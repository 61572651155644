import styled from 'styled-components'

export const BookFilter = styled.div`
  .search-bar {
    display: flex;
    background-color: ${props => props.theme.colors.blue0};
    padding: 5px 10px;
    justify-content: center;
    align-items: center;

    .filter-icon {
      width: 30px;
      height: 30px;
      padding: 5px;
    }

    .mod-molecules-navBarSearch {
      background: transparent;
      display: flex;
      flex: 1;
    }

    .filter-icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .search-filters {
    background-color: ${props => props.theme.colors.lightGrey};
    padding: 0 20px;
    max-height: ${props => (props.showFilters ? '354px' : '0px')};
    opacity: ${props => (props.showFilters ? '1' : '0')};
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    margin: 0px;
    input {
      background: transparent;
    }
  }
`
