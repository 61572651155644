//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../assets/i18n'
import { CompanyForm } from '../../../molecules'
import { ProjectsUtils } from '../../../../redux/projects'
import _ from 'lodash'

export default class EditCompany extends PureComponent {
  static propTypes = {
    projectsAccepted: PropTypes.array,
    profile: PropTypes.object,
    onSubmit: PropTypes.func,
    history: PropTypes.object,
    notify: PropTypes.func
  }

  state = {
    project: null,
    image: null,
    isProjectAdmin: false
  }

  companyName = _.get(this.props, 'match.params.company', null)
  projectName = _.get(this.props, 'match.params.project', null)

  componentDidMount() {
    this._findCompany(this.companyName, this.projectName)
  }

  _findCompany(companyName, projectName) {
    if (companyName && projectName) {
      const { projectsAccepted, profile } = this.props
      const project = _.find(projectsAccepted, { name: projectName })
      const image = _.get(project, `company_images[${companyName}]`, null)
      const isProjectAdmin = ProjectsUtils.isProyectAdmin(profile, project)
      this.setState({ project, image, isProjectAdmin })
    }
  }

  _onSubmit = (image, edited) => {
    const { project, isProjectAdmin } = this.state
    if (edited && isProjectAdmin) {
      const data = {
        image,
        project,
        companyName: this.companyName
      }
      this.props.onSubmit(data).then(() => {
        this.props.history.replace('/projects/')

        this.props.notify({
          message: i18n.success.companyEdit,
          status: 'success'
        })
      })
    }
  }

  _onClickClose = () => this.props.history.push('/')

  _renderCompanyForm() {
    const { project, isProjectAdmin, image } = this.state
    if (project && isProjectAdmin) {
      return <CompanyForm project={project} image={image} onSubmit={this._onSubmit} headTitle={i18n.companyEdit.title} onClickCloseModal={this._onClickClose} />
    }
  }

  render() {
    return (
      <div className={'motherwrapper edit-user no-modal'}>
        <div className={'container ts ts-quick'}>{this._renderCompanyForm()}</div>
      </div>
    )
  }
}
