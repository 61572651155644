import styled from 'styled-components'

export const InputTextStyled = styled.div`
  .inner {
    position: relative;
    padding: 19px 0 22px 0;
    .error-message {
      font-size: 11px;
      color: ${props => props.theme.colors.tint} !important;
      line-height: 16px;
      padding: 0;
      position: absolute;
      bottom: 4px;
    }
  }

  .inner.no-padd {
    padding: 0;
  }

  .inner.no-top-padd {
    padding-top: 0 !important;
  }

  .inner.bottom-padd-big {
    padding-bottom: 45px;
  }

  .append-note {
    color: ${props => props.theme.colors.black};
    display: block;
    padding: 0;
    font-size: 14px;
    line-height: 19px;
  }

  .phone-label {
    color: #000a12;
    font-weight: 400;
    font-style: normal;
    position: absolute;
    opacity: 1;
    pointer-events: none;
    top: 0;
    font-size: 14px;
    line-height: 19px;
    padding: 0;
    height: 19px;
    display: block;
    width: 100%;
    .mand {
      color: #0d5bdc;
      font-size: 16px;
      font-weight: bold;
      position: relative;
      top: 4px;
      left: 4px;
    }
  }

  .react-phone-number-input__input:focus {
    border-color: inherit;
  }

  input {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.black};
    border-bottom: 1px solid ${props => props.theme.colors.grey};
    display: block;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 0;
    height: 34px;
    width: 100%;

    :disabled {
      border-bottom: none;
    }

    + label,
    :focus:placeholder-shown + label {
      color: ${props => props.theme.colors.black};
      font-weight: 400;
      font-style: normal;
      position: absolute;
      opacity: 1;
      pointer-events: none;
      top: 0;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      height: 19px;
      display: block;
      width: 100%;
      .mand {
        color: ${props => props.theme.colors.tint};
        font-size: 16px;
        font-weight: bold;
        position: relative;
        top: 4px;
        left: 4px;
      }
    }

    :placeholder-shown + label {
      color: ${props => props.theme.colors.grey};
      font-size: 16px;
      line-height: 23px;
      top: 19px;
      height: 33px;
      padding: 5px 0;
      .mand {
        color: ${props => props.theme.colors.grey};
      }
    }
  }

  input[type='checkbox'] {
    display: none;
    + label {
      color: ${props => props.theme.colors.black};
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      padding-left: 30px;
      position: relative;
      cursor: pointer;
      a {
        color: ${props => props.theme.colors.tint};
        line-height: 22px;
      }
      :before {
        color: ${props => props.theme.colors.tint};
        border: 2px solid ${props => props.theme.colors.tint};
        font-family: 'Ionicons';
        display: block;
        height: 16px;
        width: 16px;
        line-height: 16px;
        position: absolute;
        top: 2px;
        left: 0;
        content: '';
        text-align: center;
        font-size: 14px;
      }
    }

    :checked {
      + label {
        :before {
          content: '\f121';
        }
      }
    }
  }

  .regular-typo a {
    color: #000a12;
    border-bottom: 2px solid #0d5bdc;
    display: inline-block;
  }
  .regular-typo a:hover {
    color: #0d5bdc;
  }
`

/*



.regular-form .input-holder input[type="checkbox"] { display: none; }
.regular-form .input-holder input[type="checkbox"] 
.regular-form .input-holder input[type="checkbox"] + label a { line-height: 22px; }
.regular-form .input-holder input[type="checkbox"] + label 
.regular-form .input-holder input[type="checkbox"]:checked + label { } 
.regular-form .input-holder input[type="checkbox"]:checked + label:before { } 

*/
