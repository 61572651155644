import styled from 'styled-components'

export const AllColsHolderStyled = styled.section`
  > .container {
    max-width: 100% !important;
    background: ${props => props.theme.colors.white};

    .col-holder {
      border-right: 2px solid ${props => props.theme.colors.tint};
      max-width: 390px !important;
      .col-cells-holder {
        position: relative;
        height: calc(100vh - 110px);
        padding: 48px 0 0 0;
      }
      .col-loop {
        position: relative;
        z-index: 999;
        height: 100%;
        overflow: auto;
        padding-top: 0;
      }

      .col-loop.mailcard-show {
        z-index: 9;
        padding-top: 74px;
      }
    }

    .col-holder.single-view-holder {
      max-width: 100% !important;
      flex: 1 1 25%;
      border-right: none;
      .row {
        max-width: 390px;
        margin: 0 auto;
      }
      .col-cells-holder {
        padding: 48px 0;
      }
    }
  }
`
