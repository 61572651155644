import styled from 'styled-components'

export const SidebarProfileStyled = styled.div`
  .row {
    background: transparent !important;

    .nav-holder {
      margin: 0 !important;
      padding: 10px 0;
      li {
        margin-bottom: 0 !important;
        padding: 10px 0;
      }
    }
  }
`
