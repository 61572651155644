import styled from 'styled-components'

export const ProfileInfoStepStyled = styled.div`
  > p.clear {
    opacity: 0.6;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  .mod-atoms-cardHolder {
    margin-bottom: 10px !important;
    .inner-cell {
      border-bottom-color: transparent;
    }
    :hover {
      .inner-cell {
        background: ${props => props.theme.colors.white} !important;
      }
    }
  }
`
