import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select from 'react-select'
import { SelectInputStyled } from './styled'

export default class SelectInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.array,
    id: PropTypes.string,
    isFetching: PropTypes.bool,
    validation: PropTypes.func,
    readOnly: PropTypes.bool,
    noResultsText: PropTypes.string,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    clearable: PropTypes.bool,
    backspaceRemoves: PropTypes.bool,
    deleteRemoves: PropTypes.bool,
    searchable: PropTypes.bool,
    className: PropTypes.string,
    multi: PropTypes.bool,
    components: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.object]),
    parentClass: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    label: '',
    value: null,
    placeholder: '',
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    options: [],
    id: null,
    isFetching: false,
    validation: null,
    readOnly: false,
    noResultsText: 'Sin resultados',
    labelKey: 'label',
    valueKey: 'value',
    clearable: false,
    backspaceRemoves: false,
    deleteRemoves: false,
    searchable: false,
    className: '',
    multi: false,
    components: false,
    parentClass: ''
  }

  constructor(props) {
    super(props)
    this.state = {
      touched: false,
      valid: true,
      error: ''
    }
  }

  validate(v) {
    if (this.props.validation) {
      const value = v != null ? v : this.props.value
      const validation = this.props.validation(value)
      this.setState({ valid: validation.isValid, error: validation.error, touched: true })
      return validation.isValid
    } else {
      return true
    }
  }

  setValid(touched, valid, error) {
    this.setState({ touched, valid, error })
  }

  _onChange(v) {
    this.validate(v)
    this.props.onChange(v)
  }

  _onFocus(e) {
    this.setState({ touched: true })
    this.props.onFocus(e)
  }

  _onBlur(e) {
    this.props.onBlur(e)
  }

  render() {
    const showError = this.state.touched && this.state.error ? true : false
    const mandatory = this.props.validation === null ? '' : <span className={'mand'}>*</span>

    return (
      <SelectInputStyled className={this.props.className + ' mod mod-atoms mod-atoms-selectInput '}>
        <div className={'input-holder'}>
          <div className={'inner ' + this.props.parentClass}>
            <Select
              options={this.props.options}
              onChange={v => this._onChange(v)}
              onFocus={e => this._onFocus(e)}
              onBlur={e => this._onBlur(e)}
              value={this.props.value}
              isLoading={this.props.isFetching}
              disabled={this.props.disabled}
              placeholder={this.props.placeholder}
              noResultsText={this.props.noResultsText}
              labelKey={this.props.labelKey}
              valueKey={this.props.valueKey}
              clearable={this.props.clearable}
              backspaceRemoves={this.props.backspaceRemoves}
              deleteRemoves={this.props.deleteRemoves}
              searchable={this.props.searchable}
              multi={this.props.multi}
              components={this.props.components}
              getOptionValue={option => `${_.get(option, 'name')} ${_.get(option, 'surname')}`}
            />
            <label htmlFor={this.props.id} className={' ts ts-instant ts-ease-out '}>
              {this.props.label}
              {mandatory}
            </label>
            {showError && <span className={'error-message '}>{this.state.error}</span>}
          </div>
        </div>
      </SelectInputStyled>
    )
  }
}
