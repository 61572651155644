//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavBarMenuStyled } from './styled'
import i18n from '../../../assets/i18n'
import ReactFlagsSelect from 'react-flags-select'
import { ContactsUtils } from '../../../redux/contacts'
import { HELP_EMAIL } from '../../../config/constants'

export default class NavBarMenu extends PureComponent {
  static propTypes = {
    showHome: PropTypes.bool,
    onClickHome: PropTypes.func,
    showMyProfile: PropTypes.bool,
    onClickMyProfile: PropTypes.func,
    onClickSignOut: PropTypes.func,
    updateLocale: PropTypes.func,
    defaultCountry: PropTypes.string
  }

  static defaultProps = {
    showHome: null,
    onClickHome: null,
    showMyProfile: null,
    onClickMyProfile: null,
    onClickSignOut: null,
    updateLocale: () => {},
    defaultCountry: 'ES'
  }

  _onSelectFlag = countryCode => {
    const code = countryCode === 'GB' ? 'en' : countryCode
    this.props.updateLocale(code.toLowerCase())
  }

  _renderMyProfile = () => {
    const { showMyProfile, onClickMyProfile } = this.props
    if (showMyProfile) {
      return <MyProfile onClick={onClickMyProfile} />
    }
  }

  _renderHomeLogo = () => {
    const { showHome, onClickHome } = this.props
    if (showHome) {
      return <Logo onClick={onClickHome} />
    }
  }

  _renderSignOut = () => {
    const { onClickSignOut } = this.props
    return <SignOut onClick={onClickSignOut} />
  }

  _renderHelp = () => {
    return <Help />
  }

  _renderFlagsSelect = () => {
    const { defaultCountry } = this.props
    return <FlagsSelect defaultCountry={defaultCountry} onSelect={this._onSelectFlag} />
  }

  render() {
    return (
      <NavBarMenuStyled className={'mod mod-molecules mod-molecules-navBarMenu'}>
        {this._renderFlagsSelect()}
        {this._renderMyProfile()}
        {this._renderHomeLogo()}
        {this._renderHelp()}
        {this._renderSignOut()}
      </NavBarMenuStyled>
    )
  }
}

const SignOut = ({ onClick }) => (
  <li>
    <button onClick={onClick}>{i18n.signOut}</button>
  </li>
)

SignOut.propTypes = {
  onClick: PropTypes.func
}

const Help = () => (
  <li>
    <a href={`mailto:${HELP_EMAIL}`}>{i18n.help}</a>
  </li>
)

const MyProfileItem = ({ onClick, showBadge }) => (
  <li>
    <button onClick={onClick} className="my-profile">
      {i18n.myProfile.title}
      {showBadge && <div className="my-profile-badge" />}
    </button>
  </li>
)

MyProfileItem.propTypes = {
  onClick: PropTypes.func,
  showBadge: PropTypes.bool
}

const mapStateToProps = state => {
  const allInvitationsTreated = ContactsUtils.allInvitationsTreated(state.contacts.contacts) && ContactsUtils.allInvitationsTreated(state.projects.projects)
  return {
    showBadge: !allInvitationsTreated
  }
}

const MyProfile = connect(
  mapStateToProps,
  null
)(MyProfileItem)

const Logo = ({ onClick }) => (
  <li>
    <button onClick={onClick}>{i18n.home}</button>
  </li>
)

Logo.propTypes = {
  onClick: PropTypes.func
}

const FlagsSelect = ({ defaultCountry, onSelect }) => (
  <ReactFlagsSelect
    countries={['ES', 'GB']}
    customLabels={{ GB: i18n.languaje.en, ES: i18n.languaje.es }}
    placeholder={i18n.languaje.title}
    defaultCountry={defaultCountry}
    onSelect={onSelect}
  />
)

FlagsSelect.propTypes = {
  defaultCountry: PropTypes.string,
  onSelect: PropTypes.func
}
