import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ColChipNameStyled } from './styled'

export default class ColChipName extends Component {
  static propTypes = {
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }

  static defaultProps = {
    name: false,
    color: false
  }

  render() {
    const { name, color } = this.props

    return (
      <ColChipNameStyled className={'mod mod-atoms mod-atoms-ColChipName regular-typo'} color={color}>
        <div className={'chip'}>
          <div className={'inner-chip'} />
        </div>
        <div className={'copy'}>
          <p>{name}</p>
        </div>
      </ColChipNameStyled>
    )
  }
}
