import styled from 'styled-components'

export const ContainerStyled = styled.div`
  .inner {
    position: relative;
    padding: 19px 0 22px 0;
    .error-message {
      font-size: 11px;
      color: ${props => props.theme.colors.tint} !important;
      line-height: 16px;
      padding: 0;
      position: absolute;
      bottom: 4px;
    }
  }
  .inner.no-padd {
    padding: 0;
  }
  .inner.no-bottom-padd {
    padding-bottom: 0 !important;
  }
  .inner.no-top-padd {
    padding-top: 0 !important;
  }
  .append-note {
    color: ${props => props.theme.colors.black};
    display: block;
    padding: 0;
    font-size: 14px;
    line-height: 19px;
  }
  .dropzone {
    background: ${props => props.theme.colors.tintOpacity};
    display: block;
    margin: 0 auto;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    label {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      text-align: center;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 50px;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
    > div {
      width: 100%;
      height: 100%;
      position: relative;
      figure {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: transparent;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      input {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        display: block !important;
      }
    }
    :hover {
      > div {
        figure {
          opacity: 0.45;
        }
      }
    }
  }
`
