//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextInput } from '../../atoms'

export default class SearchBar extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    value: '',
    onChange: () => {},
    placeholder: ''
  }

  render() {
    return (
      <div className={'regular-form'}>
        <TextInput
          id={'searchBar'}
          type={'search'}
          value={this.props.value}
          placeholder={' '}
          label={this.props.label}
          onChange={v => this.props.onChange(v)}
        />
      </div>
    )
  }
}
