/* eslint-disable no-console */

import { ContactsActions } from './'
import { ProjectsUtils } from '../projects'
import _ from 'lodash'
import { CallsOperations } from '../calls'

let firebaseContactsListener = null

export const checkProfileExist = email => (dispatch, getState, { firebase, firebaseConfig }) => {
  dispatch(ContactsActions.setIsFetching(true))

  firebase
    .database()
    .ref(`${firebaseConfig.refs.profile}/`)
    .orderByChild('email')
    .equalTo(email)
    .once('value')
    .then(snapshot => {
      const data = snapshot.val()
      if (!data) {
        dispatch(ContactsActions.setAddProfile(false))
      } else {
        const profile = _.first(Object.values(data))
        dispatch(ContactsActions.setAddProfile(profile))
      }
    })
    .catch(error => console.log(error))
    .finally(() => dispatch(ContactsActions.setIsFetching(false)))
}

export const addNewContactProfile = data => (dispatch, getState, { api }) => {
  console.log(data)
  return api
    .postContact(data)
    .then(response => {
      console.log(response)
      return true
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const editContactProfile = (data, contact) => (dispatch, getState, { api }) => {
  if (!data || !contact) {
    return Promise.resolve(false)
  }

  return api
    .patchContact(data, contact)
    .then(() => dispatch(updateContactsProfile()))
    .catch(err => {
      console.log('editContactProfile error: ', err)
      return Promise.resolve(false)
    })
}

export const addExistingContactProfile = () => (dispatch, getState, { api }) => {
  const { addProfile } = getState().contacts

  return api
    .postProfileContact(addProfile)
    .then(response => {
      console.log(response)
      return true
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export const setContactsListener = () => (dispatch, getState, { firebase, firebaseConfig }) => {
  removeContactsListener()

  const { id } = getState().auth
  const meContactsRef = `${firebaseConfig.refs.profile}/${id}/contacts`
  firebaseContactsListener = firebase.database().ref(meContactsRef)
  firebaseContactsListener.on('value', snapshot => {
    const contacts = snapshot ? snapshot.val() : null
    dispatch(ContactsActions.setContacts(contacts ? contacts : []))
    dispatch(updateContactsProfile())
  })
}

export const removeContactsListener = () => {
  firebaseContactsListener && firebaseContactsListener.off('value')
}

export const updateContactsProfile = () => (dispatch, getState, { firebase, firebaseConfig }) => {
  const { contacts } = getState().contacts
  const promises = _.map(contacts, (value, key) =>
    firebase
      .database()
      .ref(`${firebaseConfig.refs.profile}/${key}`)
      .once('value')
  )

  return Promise.all(promises).then(response => {
    const profiles = _.map(response, snapshot => snapshot.val())
    const profilesInfoFiltered = _.filter(profiles, profile => !_.isNull(profile) && !_.isUndefined(profile))
    dispatch(ContactsActions.setContactsProfile(profilesInfoFiltered))
  })
}

export const toggleContactInvitation = contact => (dispatch, getState, { i18n, api, notify }) => {
  const { projects, projectsInfo } = getState().projects
  const projects0 = _.get(contact, 'projects')
  const commonProjects = ProjectsUtils.getCommonProjects({ projects0, projects1: projects, projectsInfo })
  if (_.size(commonProjects) > 0) {
    dispatch(notify({ title: i18n.modal.info, message: i18n.invitations.contacts.noModifyInfo }))
    return Promise.resolve()
  }

  return api
    .toggleContactInvitation(contact)
    .then(() => dispatch(updateContactsProfile))
    .catch(error => console.log(error))
}

export const setContactsEmailsSend = contacts => (dispatch, getState) => {
  const { contactsEmailSend } = getState().contacts
  const newContactsEmailSend = [...contactsEmailSend]
  _.forEach(contacts, c1 => {
    const find = _.find(contactsEmailSend, e1 => c1.email === e1)
    if (find) {
      _.remove(newContactsEmailSend, e1 => c1.email === e1)
    } else {
      newContactsEmailSend.push(c1.email)
    }
  })
  dispatch(ContactsActions.setContactsEmailsSend(newContactsEmailSend))
  return Promise.resolve()
}

export const addContactsEmailsSend = contacts => (dispatch, getState) => {
  const { contactsEmailSend } = getState().contacts
  const newContactsEmailSend = [...contactsEmailSend]
  _.forEach(contacts, c1 => {
    const find = _.find(contactsEmailSend, e1 => c1.email === e1)
    if (!find) {
      newContactsEmailSend.push(c1.email)
    }
  })
  dispatch(ContactsActions.setContactsEmailsSend(newContactsEmailSend))
  return Promise.resolve()
}

export const removeContactsEmailsSend = contacts => (dispatch, getState) => {
  const { contactsEmailSend } = getState().contacts
  const newContactsEmailSend = [...contactsEmailSend]
  _.forEach(contacts, c1 => {
    const find = _.find(contactsEmailSend, e1 => c1.email === e1)
    if (find) {
      _.remove(newContactsEmailSend, e1 => c1.email === e1)
    }
  })
  dispatch(ContactsActions.setContactsEmailsSend(newContactsEmailSend))
  return Promise.resolve()
}

export const sendContactsEmailsSend = () => (dispatch, getState) => {
  const { contactsEmailSend } = getState().contacts
  dispatch(ContactsActions.setContactsEmailsSend([]))
  const contacts = _.map(contactsEmailSend, email => ({ email }))
  return dispatch(CallsOperations.sendEmailContacts(contacts))
}
