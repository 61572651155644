import * as types from './types'

export const setIsFetching = isFetching => {
  return { type: types.SET_IS_FETCHING, payload: isFetching }
}

export const setLastCalls = value => ({
  type: types.SET_LAST_CALLS,
  payload: value
})
