export const blue1 = '#2539A6'
export const blue2 = '#1475E2'
export const blue3 = '#2E51D6'
export const blue4 = '#79AEFF'

export const black = '#000A12'
export const white = '#ffffff'

export const tint = '#0D5BDC'
export const tintOpacity = '#0D5BDC77'
export const red = '#D0021B'
export const blue = '#2D8EFF'
export const yellow = '#F5A623'
export const green = '#5DA10D'
export const purple = '#BD10E0'

export const grey = '#7F7F7F'
export const grey2 = '#A8AEBA'
export const darkGrey = '#363636'
export const mediumGrey = '#CFCFCF'
export const lightGrey = '#E9E9E9'
export const background = '#F8F8F8'
