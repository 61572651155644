//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'
import { TextInput, PhoneInput, FileInput, Button, AuthHeader } from '../../../atoms'
import Utils from '../../../../utils'
import { LandingHeader } from '../../../molecules'

class CompleteRegisterForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool
  }

  state = {
    company: _.get(this.props, 'profile.company', ''),
    department: _.get(this.props, 'profile.department', ''),
    name: _.get(this.props, 'profile.name', ''),
    phone1: _.get(this.props, 'profile.phone1', ''),
    phone2: _.get(this.props, 'profile.phone2', ''),
    surname: _.get(this.props, 'profile.surname', ''),
    position: _.get(this.props, 'profile.position', ''),
    email: _.get(this.props, 'profile.email', ''),
    image: _.get(this.props, 'profile.image', null)
  }

  formInputs = {}

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      this.props.onSubmit(this.state)
    }
  }

  _renderTopSideForm = () => {
    return (
      <div className={'col col-xs-12'}>
        <FileInput
          ref={i => (this.formInputs.image = i)}
          id={'image'}
          label={i18n.imageLabel}
          placeholder={' '}
          value={this.state.image}
          onChange={image => this.setState({ image })}
        />
      </div>
    )
  }

  _renderLeftSideForm = () => {
    const { company, department, name, surname, position } = this.state
    return (
      <div className={'col col-xs-12 col-sm-6'}>
        <TextInput
          innerRef={i => (this.formInputs.name = i)}
          id={'name'}
          value={name}
          label={i18n.auth.name}
          placeholder={' '}
          onChange={name => this.setState({ name })}
          validation={v => Utils.mandatoryValidate(v)}
        />
        <TextInput
          innerRef={i => (this.formInputs.surname = i)}
          id={'surname'}
          value={surname}
          label={i18n.auth.surname}
          placeholder={' '}
          onChange={surname => this.setState({ surname })}
          validation={v => Utils.mandatoryValidate(v)}
        />
        <TextInput
          innerRef={i => (this.formInputs.company = i)}
          id={'company'}
          value={company}
          label={i18n.auth.company}
          placeholder={' '}
          onChange={company => this.setState({ company })}
          validation={v => Utils.mandatoryValidate(v)}
        />
        <TextInput
          innerRef={i => (this.formInputs.position = i)}
          id={'position'}
          value={position}
          label={i18n.auth.position}
          placeholder={' '}
          onChange={position => this.setState({ position })}
        />
        <TextInput
          innerRef={i => (this.formInputs.department = i)}
          id={'department'}
          value={department}
          label={i18n.auth.department}
          placeholder={' '}
          onChange={department => this.setState({ department })}
        />
      </div>
    )
  }

  _renderRightSideForm = () => {
    const { phone1, phone2 } = this.state
    return (
      <div className={'col col-xs-12 col-sm-6'}>
        <PhoneInput
          innerRef={i => (this.formInputs.phone1 = i)}
          id={'phone1'}
          value={phone1}
          label={i18n.auth.phone1}
          placeholder={' '}
          onChange={phone1 => this.setState({ phone1 })}
        />
        <PhoneInput
          innerRef={i => (this.formInputs.phone2 = i)}
          id={'phone2'}
          value={phone2}
          label={i18n.auth.phone2}
          placeholder={' '}
          onChange={phone2 => this.setState({ phone2 })}
        />
      </div>
    )
  }

  _renderBottomSideForm = () => {
    const { isFetching } = this.props
    const { company } = this.state
    const submitEnabled = _.size(company) > 0
    return (
      <div className={'col col-xs-12 center'}>
        <Button className={'clear-blue jumbo inline'} label={i18n.continue} disabled={!submitEnabled} isFetching={isFetching} />
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this._onSubmit} className={'row'}>
        {this._renderTopSideForm()}
        {this._renderLeftSideForm()}
        {this._renderRightSideForm()}
        {this._renderBottomSideForm()}
      </form>
    )
  }
}

export default class CompleteRegister extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool
  }

  render() {
    const { isFetching, onSubmit } = this.props
    const profile = _.get(this.props, 'location.state.profile')

    return (
      <div>
        <LandingHeader />
        <section className={'mod mod-pages mod-pages-auth-register mid-form-holder'}>
          <div className={'container'}>
            <div className={'row '}>
              <div className={'col col-xs-12'}>
                <AuthHeader subtitle={i18n.auth.completeInfo} />
              </div>
              <div className={'col-xs-12'}>
                <CompleteRegisterForm onSubmit={onSubmit} isFetching={isFetching} profile={profile} />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
