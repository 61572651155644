import styled from 'styled-components'

export const ModalFormHeaderStyled = styled.div`
  background: ${props => props.theme.colors.tint};
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 20px;

  .title-holder {
    h4 {
      color: ${props => props.theme.colors.white} !important;
      text-align: center;
      margin: 20px 0 !important;
    }

    .button-holder {
      position: absolute;
      top: 50%;
      left: 20px;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
  }
`
