import * as types from './types'

export const setIsFetching = isFetching => {
  return { type: types.SET_IS_FETCHING, payload: isFetching }
}

export const setAddProfile = value => ({
  type: types.SET_ADD_CONTACT_PROFILE,
  payload: value
})

export const resetAddProfile = () => ({
  type: types.SET_ADD_CONTACT_PROFILE,
  payload: null
})

export const setContacts = value => ({
  type: types.SET_CONTACTS,
  payload: value
})

export const setContactsProfile = value => ({
  type: types.SET_CONTACTS_PROFILE,
  payload: value
})

export const setContactsEmailsSend = value => ({
  type: types.SET_CONTACTS_EMAIL_SEND,
  payload: value
})
