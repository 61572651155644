//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'
import { TextInput, Button, AuthHeader } from '../../../atoms'
import Utils from '../../../../utils'
import { Link } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { TermsStyled } from './styled'
import { LandingHeader, LandingFooter, LandingInfo, DownloadApp } from '../../../molecules'
import Paper from '@material-ui/core/Paper'

class RegisterForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    onClickFG: PropTypes.func,
    isFetching: PropTypes.bool,
    history: PropTypes.object
  }

  _onClickForgot = () => this.props.history.push('/restore-password')

  state = {
    _username: '',
    _password: '',
    _checked: false
  }

  formInputs = {}

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      const data = {
        email: this.state._username,
        password: this.state._password,
        checked: this.state._checked
      }
      this.props.onSubmit(data)
    }
  }

  render() {
    const { isFetching, onClickFG } = this.props
    const { _checked, _username, _password } = this.state
    const submitEnabled = _checked && _.size(_username) > 2 && _.size(_password) > 7
    const labelAccept = (
      <TermsStyled>
        {i18n.auth.aceptTerms} <Link to="/terms-and-conditions">{i18n.auth.terms}</Link>{' '}
      </TermsStyled>
    )

    return (
      <form onSubmit={this._onSubmit}>
        <TextInput
          innerRef={i => (this.formInputs._username = i)}
          id={'_username'}
          value={_username}
          label={i18n.auth.email}
          placeholder={' '}
          onChange={_username => this.setState({ _username: _username.toLowerCase().trim() })}
          validation={v => Utils.mandatoryValidate(v)}
        />
        <TextInput
          innerRef={i => (this.formInputs._password = i)}
          id={'_password'}
          value={_password}
          label={i18n.auth.pass}
          placeholder={' '}
          onChange={_password => this.setState({ _password })}
          type={'password'}
          validation={v => Utils.mandatoryValidate(v)}
        />

        <FormControlLabel label={labelAccept} control={<Checkbox checked={_checked} onChange={() => this.setState({ _checked: !this.state._checked })} />} />

        <div className={'center'}>
          <Button className={'simple-link-alike inline compact'} label={i18n.auth.forgotPassword} onClick={onClickFG} parentClass={'no-padd'} />
          <Button className={'clear-blue block'} label={i18n.auth.register} disabled={!submitEnabled} isFetching={isFetching} labeledSpinner />
        </div>
      </form>
    )
  }
}

export default class Register extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    history: PropTypes.object
  }

  _onClickLogin = () => this.props.history.push('/login')

  _onClickForgot = () => this.props.history.push('/restore-password')

  render() {
    const { onSubmit, isFetching } = this.props
    return (
      <div>
        <LandingHeader />
        <section style={{ display: 'flex', flex: 1, padding: '2em', justifyContent: 'center', alignItems: 'center' }}>
          <LandingInfo />
          <div>
            <Paper style={{ display: 'flex', flexDirection: 'column', width: 320, background: '#f8f8f8', padding: 20, marginRight: 20 }} elevation={2}>
              <AuthHeader title={'REGISTER'} />
              <RegisterForm onSubmit={onSubmit} isFetching={isFetching} onClickFG={this._onClickForgot} />
              <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ color: 'grey', marginRight: 10 }}>Have an account? </span>
                <Button className={'link-alike compact inline'} label={i18n.auth.login} onClick={this._onClickLogin} parentClass={'no-padd'} />
              </div>
            </Paper>
            <DownloadApp />
          </div>
        </section>
        <LandingFooter />
      </div>
    )
  }
}
