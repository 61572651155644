import * as types from './types'

export const setIsFetching = isFetching => {
  return { type: types.SET_IS_FETCHING, payload: isFetching }
}

export const setProjects = value => ({
  type: types.SET_PROJECTS,
  payload: value
})

export const setProjectsAccepted = value => ({
  type: types.SET_PROJECTS_ACCEPTED,
  payload: value
})

export const setProjectsInfo = value => ({
  type: types.SET_PROJECTS_INFO,
  payload: value
})

export const setProjectSelected = value => ({
  type: types.SET_PROJECT_SELECTED,
  payload: value
})

export const setSearchText = value => ({
  type: types.SET_SEARCH_TEXT,
  payload: value
})