import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContainerStyled } from './styled'

export default class AuthHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
  }

  static defaultProps = {
    title: '',
    subtitle: 'to access the app'
  }

  render() {
    return (
      <ContainerStyled className={'mod mod-atoms mod-atoms-authHeader regular-typo'}>
        <h4 className={'title'}>{this.props.title}</h4>
        <h4 className={'subtitle'}>{this.props.subtitle}</h4>
      </ContainerStyled>
    )
  }
}
