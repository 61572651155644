//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'
import { TextInput, Button, AuthHeader } from '../../../atoms'
import { LandingHeader, LandingFooter, LandingInfo, DownloadApp } from '../../../molecules'
import Utils from '../../../../utils'
import Paper from '@material-ui/core/Paper'

class LoginForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    onClickFG: PropTypes.func,
    isFetching: PropTypes.bool
  }

  state = {
    _username: '',
    _password: ''
  }

  formInputs = {}

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      const data = {
        email: this.state._username,
        password: this.state._password
      }
      this.props.onSubmit(data)
    }
  }

  render() {
    const { isFetching, onClickFG } = this.props
    const { _username, _password } = this.state
    const submitEnabled = _.size(_username) > 2 && _.size(_password) > 7

    return (
      <form onSubmit={this._onSubmit}>
        <TextInput
          innerRef={i => (this.formInputs._username = i)}
          id={'_username'}
          value={this.state._username}
          label={i18n.auth.email}
          placeholder={' '}
          onChange={_username => this.setState({ _username: _username.toLowerCase().trim() })}
          validation={v => Utils.mandatoryValidate(v)}
        />
        <TextInput
          innerRef={i => (this.formInputs._password = i)}
          id={'_password'}
          value={this.state._password}
          label={i18n.auth.pass}
          placeholder={' '}
          onChange={_password => this.setState({ _password })}
          type={'password'}
          validation={v => Utils.mandatoryValidate(v)}
        />
        <div className={'center'}>
          <Button className={'simple-link-alike inline compact'} label={'Forgot password?'} onClick={onClickFG} parentClass={'no-padd'} />
          <Button className={'clear-blue block'} label={'SIGN IN'} disabled={!submitEnabled} isFetching={isFetching} labeledSpinner />
        </div>
      </form>
    )
  }
}

export default class Login extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    history: PropTypes.object,
    isFetching: PropTypes.bool
  }

  _onClickRegister = () => this.props.history.push('/register')

  _onClickForgot = () => this.props.history.push('/restore-password')

  render() {
    const { onSubmit, isFetching } = this.props
    return (
      <div>
        <LandingHeader />
        <section style={{ display: 'flex', flex: 1, padding: '2em', justifyContent: 'center', alignItems: 'center' }}>
          <LandingInfo />
          <div>
            <Paper style={{ display: 'flex', flexDirection: 'column', width: 320, background: '#f8f8f8', padding: 20, marginRight: 20 }} elevation={2}>
              <AuthHeader title={'LOGIN'} />
              <LoginForm onSubmit={onSubmit} isFetching={isFetching} onClickFG={this._onClickForgot} />
              <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ color: 'grey', marginRight: 10 }}>Don’t have an account? </span>
                <Button className={'link-alike compact inline'} label={'SIGN UP'} onClick={this._onClickRegister} parentClass={'no-padd'} />
              </div>
            </Paper>
            <DownloadApp />
          </div>
        </section>
        <LandingFooter />
      </div>
    )
  }
}
