import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import _ from 'lodash'
import { createLogger } from 'redux-logger'
import firebase from 'firebase'
import firebaseConfig from './firebase'
import * as reducers from '../redux/'
import { AuthOperations } from '../redux/auth'
import i18n from '../assets/i18n/'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { reducer as notificationsReducer } from 'reapop'
import { notify } from 'reapop'
import * as api from '../api/'

//Add all reducers to black list
const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: _.map(reducers, (_, key) => key)
}

//Add reducer-properties to white list
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'id', 'uid', 'email', 'name', 'surname', 'company', 'position', 'department', 'phone1', 'phone2', 'languaje']
}

const contactsPersistConfig = {
  key: 'contacts',
  storage,
  whitelist: ['contacts', 'contactsProfile']
}

const projectsPersistConfig = {
  key: 'projects',
  storage,
  whitelist: ['projects', 'projectsAccepted', 'projectsInfo']
}

const rootReducer = combineReducers({
  ...reducers,
  auth: persistReducer(authPersistConfig, reducers.auth),
  contacts: persistReducer(contactsPersistConfig, reducers.contacts),
  projects: persistReducer(projectsPersistConfig, reducers.projects),
  notifications: notificationsReducer()
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

//Middlewares
const thunkMiddleware = thunk.withExtraArgument({ i18n, firebase, firebaseConfig, notify, api })
const loggerMiddleware = createLogger({ collapsed: true })
const middlewares =
  process.env.NODE_ENV === 'production' || typeof window !== 'undefined' ? applyMiddleware(thunkMiddleware) : applyMiddleware(thunkMiddleware, loggerMiddleware)

//process.env.NODE_ENV
const store = createStore(persistedReducer, middlewares)
const persistor = persistStore(store, {}, () => {
  store.dispatch(AuthOperations.checkLocale())
  store.dispatch(AuthOperations.checkSesion())
})

export { store, persistor }
