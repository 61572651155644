import styled from 'styled-components'

export const DataItemStyled = styled.li`
  display: block;
  margin-bottom: 10px;

  span {
    display: block;
  }

  span.label {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  span.value {
    margin-bottom: 5px;
    word-break: break-all;
  }
`
