import styled from 'styled-components'

export const ProfileColsHolderStyled = styled.section`
  > .container {
    max-width: 100% !important;
    min-height: 100%;
    background: blue;

    .row {
      background: ${props => props.theme.colors.white};
    }

    .sidebar-holder {
      background: ${props => props.theme.colors.lightGrey};
      height: calc(100vh - 110px);
    }

    .main-holder {
      background: ${props => props.theme.colors.white};
      height: 100%;
    }
  }
`
