import { configureAxiosDefaults, configure, post, put, patch } from './utils'

export const configureInterceptors = () => {
  configure()
}

export function configureToken(token) {
  configureAxiosDefaults('Authorization', 'Bearer ' + token)
}

export function patchMeProfile(data) {
  const url = `/me/profile`
  return patch(url, data)
}

export function postMeProfile(data) {
  const url = `/me/profile`
  return post(url, data)
}

export function postCall({ id }) {
  const url = `/call/${id}`
  return post(url)
}

export function postContact(data) {
  const url = `/contact/new`
  return post(url, data)
}

export function postProfileContact({ id }) {
  const url = `/contact/${id}`
  return post(url)
}

//eslint-disable-next-line
export function patchContact({ id, ...data }, contact) {
  const url = `/contact/${contact.id}`
  return patch(url, data)
}

export function toggleContactInvitation({ id, accepted }) {
  const url = `/contact/${id}/invitation`
  const data = { accepted: !accepted }
  return put(url, data)
}

export function postProject(data) {
  const url = `/project`
  return post(url, data)
}

export function patchProject({ id, ...data }) {
  const url = `/project/${id}`
  return patch(url, data)
}

export function putProjectCompany({ project, company, image }) {
  const url = `/project/${project}/company`
  const data = { company, image }
  return put(url, data)
}

export function putProjectInvitation({ id, accepted }) {
  const url = `/project/${id}/invitation`
  const data = { accepted }
  return put(url, data)
}

export function putProjectAdmin({ id, isAdmin }) {
  const url = `/project/${id}/admin`
  const data = { isAdmin }
  return put(url, data)
}

export function deleteProject({ id, name, members }) {
  const url = `/project/${id}/delete`
  const data = { name, members }
  return post(url, data)
}
