import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SectionDepHeaderStyled } from './styled'

export default class SectionDepHeader extends Component {
  static propTypes = {
    department: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
  }

  static defaultProps = {
    department: ''
  }

  render() {
    const dep = this.props.department
    const depname = dep && dep !== '' ? this.props.department : <em>{''}</em>
    return (
      <SectionDepHeaderStyled className={'mod mod-atoms mod-molecules-sectionDepHeader regular-typo'}>
        <div className={'container'}>
          <div className={'inner-cell-dep-div'}>
            <div className={'row middle-xs'}>
              <div className={'col-xs-8 dep-name-holder'}>
                <h5>{depname}</h5>
              </div>
              <div className={'col-xs-4 actions-holder'}>{this.props.children}</div>
            </div>
          </div>
        </div>
      </SectionDepHeaderStyled>
    )
  }
}
