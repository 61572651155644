import styled from 'styled-components'
import { WhiteMagGlass } from '../../../assets/images/'

export const NavBarSearchStyled = styled.div`
  background: ${props => props.theme.colors.tint};
  position: relative;

  form {
    display: flex;
    padding: 5px 0;
    position: relative;
    max-width: 600px;
    float: right;
    width: 100%;

    .input-holder {
      display: flex;
      flex: 1;
    }

    button {
      position: absolute;
      right: 13px;
      top: 14px;

      img {
        width: 12px;
        height: 12px;
      }
    }

    input[type='text'] {
      width: 100%;
      border-radius: 20px;
      font-size: 14px;
      line-height: 28px;
      padding: 0 15px 0 30px;
      border-radius: 20px;
      border: 1px solid ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.white};
      ::placeholder {
        color: ${props => props.theme.colors.white};
        opacity: 0.6;
      }
    }

    input[type='submit'] {
      height: 26px;
      width: 26px;
      display: block;
      position: absolute;
      left: 1px;
      top: 7px;
      border-radius: 20px;
      overflow: hidden;
      text-indent: -9999px;
      background: url(${WhiteMagGlass}) center center no-repeat;
    }
  }
`
