import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ProjectsOperations } from '../../../../redux/projects'

const mapStateToProps = state => {
  return {
    projects: state.projects.projects,
    projectsInfo: state.projects.projectsInfo,
    isFetching: state.projects.isFetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refresh: () => dispatch(ProjectsOperations.updateProjects()),
    toggleProjectIvitation: data => dispatch(ProjectsOperations.toggleProjectIvitation(data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(View))
