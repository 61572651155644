//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonIcon, ModalHolder, CardSpiner } from '../../../atoms'
import { SearchBar, CompanyCard } from '../../../molecules'
import { ProjectsUtils } from '../../../../redux/projects'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'

class ProjectInvitationCard extends PureComponent {
  static propTypes = {
    onPressAction: PropTypes.func,
    accepted: PropTypes.bool,
    project: PropTypes.object,
    isFetching: PropTypes.bool
  }

  static defaultProps = {
    onPressAction: () => {},
    accepted: false,
    project: null,
    isFetching: false
  }

  _onPressAction = () => this.props.onPressAction(this.props)

  render() {
    const { project, accepted, isFetching } = this.props
    const accButton = <ButtonIcon label={i18n.accept} icon={'toright'} color={'blue'} onClick={this._onPressAction} display={true} />
    const rejButton = <ButtonIcon label={i18n.reject} icon={'close'} color={'blue'} onClick={this._onPressAction} display={true} />
    const spinner = <CardSpiner />
    const actionButton = isFetching ? spinner : accepted ? rejButton : accButton
    return (
      <div className={'added-members-loop'}>
        <CompanyCard company={project} forceActions={actionButton} />
      </div>
    )
  }
}

export default class MyInvitationsProjects extends PureComponent {
  static propTypes = {
    toggleProjectIvitation: PropTypes.func,
    projects: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    projectsInfo: PropTypes.array,
    history: PropTypes.object,
    profile: PropTypes.object
  }

  state = {
    searchText: '',
    isFetchingInvitationIds: {}
  }

  _onClickClose = () => this.props.history.goBack()

  _setIsFetchingInvitationId = (id, isFetching) => this.setState({ isFetchingInvitationIds: { ...this.state.isFetchingInvitationIds, [id]: isFetching } })

  _toggleProjectIvitation = data => {
    const { id } = data
    this._setIsFetchingInvitationId(id, true)
    this.props.toggleProjectIvitation(data).finally(() => this._setIsFetchingInvitationId(id, false))
  }

  _renderProjects = list => {
    const { projects } = this.props
    const { isFetchingInvitationIds } = this.state
    return _.map(list, project => (
      <ProjectInvitationCard
        key={'project' + project.id}
        project={project}
        {...project}
        accepted={projects[project.id]}
        onPressAction={this._toggleProjectIvitation}
        isFetching={isFetchingInvitationIds[project.id]}
      />
    ))
  }

  _renderNoData = (showNoData, noDataLabel) => {
    if (showNoData) {
      return (
        <span className={'regular-typo'}>
          <p className={'isclear'}>
            <em>{noDataLabel}</em>
          </p>
        </span>
      )
    }
  }

  render() {
    const { projectsInfo, projects } = this.props
    const filtered = ProjectsUtils.filterByText(this.state.searchText, projectsInfo)
    const items = _.orderBy(filtered, ({ id }) => `${projects[id]}`)
    const noDataLabel = this.state.searchText ? i18n.invitations.noDataFilter : i18n.invitations.noData
    const showNoData = _.isEmpty(items)
    return (
      <ModalHolder className={'has-navbar has-side'} addClose={true} CloseOnClick={this._onClickClose}>
        <div className={'row regular-form slim-form-holder-nofloat'}>
          <div className={'col col-xs-12'}>
            <h2>{i18n.projects.title}</h2>
          </div>
          <div className={'col col-xs-12'}>
            <SearchBar label={i18n.search} value={this.state.searchText} onChange={v => this.setState({ searchText: v })} />
          </div>
          <div className={'col col-xs-12'}>
            {this._renderProjects(items)}
            {this._renderNoData(showNoData, noDataLabel)}
          </div>
        </div>
      </ModalHolder>
    )
  }
}
