import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NotificationsSystem from 'reapop'
import theme from 'reapop-theme-wybo'
import { withRouter } from 'react-router-dom'

// Routes
import {
  Splash,
  Login,
  Home,
  ModalAddProfile,
  AddProfile,
  EditProfile,
  Politic,
  Conditions,
  Register,
  CompleteRegister,
  Restore,
  MyProfile,
  MyData,
  UpdatePass,
  UpdateEmail,
  MyInvitations,
  MyInvitationsProjects,
  MyInvitationsContacts,
  AddProject,
  EditProject,
  EditCompany,
  NotFound,
  MyQr
} from '../../pages'

function PrivateRoute({ authData, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!authData.sesionLoaded) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
        } else if (!authData.uid) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}

PrivateRoute.propTypes = {
  authData: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

class Routes extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object
  }

  previousLocation = this.props.location

  componentWillUpdate(nextProps) {
    let { location } = this.props

    // React Router Modal Fix
    if (nextProps.history.action !== 'POP' && (!location.state || !location.state.modal)) {
      this.previousLocation = location
    }
  }

  render() {
    const { auth, location } = this.props
    if (!auth.sesionLoaded) return <div />

    const isModal = !!(location.state && location.state.modal) && this.previousLocation !== location
    return (
      <div>
        <Switch location={isModal ? this.previousLocation : location}>
          <Route exact path="/" component={Splash} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/restore-password" component={Restore} />
          <Route exact path="/privacy-policy" component={Politic} />
          <Route exact path="/terms-and-conditions" component={Conditions} />
          <Route exact path="/complete-register" component={CompleteRegister} />

          <PrivateRoute authData={auth} path="/my-profile" component={MyProfile} />
          <PrivateRoute authData={auth} exact path="/edit/:email" component={EditProfile} />
          <PrivateRoute authData={auth} exact path="/add" component={AddProfile} />

          <PrivateRoute authData={auth} exact path="/projects" component={Home} />
          <PrivateRoute authData={auth} exact path="/projects/add" component={AddProject} />
          <PrivateRoute authData={auth} exact path="/projects/:project" component={Home} />
          <PrivateRoute authData={auth} exact path="/projects/:project/edit" component={EditProject} />
          <PrivateRoute authData={auth} exact path="/projects/:project/:company" component={Home} />
          <PrivateRoute authData={auth} exact path="/projects/:project/:company/edit" component={EditCompany} />
          <PrivateRoute authData={auth} exact path="/projects/:project/:company/:contact" component={Home} />
          <Route component={NotFound} />
        </Switch>
        {isModal && <PrivateRoute authData={auth} exact path="/my-profile/my-qr" component={MyQr} />}
        {isModal && <PrivateRoute authData={auth} exact path="/my-profile/my-data" component={MyData} />}
        {isModal && <PrivateRoute authData={auth} exact path="/my-profile/update-pass" component={UpdatePass} />}
        {isModal && <PrivateRoute authData={auth} exact path="/my-profile/update-email" component={UpdateEmail} />}
        {isModal && <PrivateRoute authData={auth} exact path="/my-profile/invitations" component={MyInvitations} />}
        {isModal && <PrivateRoute authData={auth} exact path="/my-profile/invitations/projects" component={MyInvitationsProjects} />}
        {isModal && <PrivateRoute authData={auth} exact path="/my-profile/invitations/contacts" component={MyInvitationsContacts} />}
        {isModal && <PrivateRoute authData={auth} exact path="/projects/add/contact" component={ModalAddProfile} />}

        <NotificationsSystem theme={theme} />
      </div>
    )
  }
}

const mapStateToProps = state => ({ auth: state.auth })

export default withRouter(connect(mapStateToProps)(Routes))
