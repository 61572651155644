import styled from 'styled-components'

export const SectionColHeaderStyled = styled.header`
  .container {
    background-color: ${props => props.theme.colors.blue0};

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    z-index: 9999;

    .row {
      max-width: 100% !important;
      padding: 0 15px;
    }

    .actions-holder {
      text-align: right;
    }

    .title-holder {
      h5 {
        margin-left: 5px;
        margin-bottom: 0;
        color: ${props => props.theme.colors.white};
        line-height: 38px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: ${props => (props.clickTitleEnabled ? 'pointer' : 'cursor')};
      }
    }
  }

  .container.section-footer {
    top: initial;
    bottom: 0;
  }
`
