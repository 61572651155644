import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SingleContactHeaderStyled } from './styled'

export default class SingleContactHeader extends Component {
  static propTypes = {
    name: PropTypes.string,
    img: PropTypes.string,
    position: PropTypes.string,
    initials: PropTypes.string
  }

  static defaultProps = {
    name: '',
    img: '',
    position: '',
    initials: ''
  }

  _renderPicture = (image, name) => {
    return (
      <figure>
        <img src={image} alt={name} />
      </figure>
    )
  }

  _renderInitials = initials => {
    return (
      <div className={'initials'}>
        <div className={'initials-inner grad-gray'}>
          <span className={'h1'}>{initials}</span>
        </div>
      </div>
    )
  }

  render() {
    const { name, img, position, initials } = this.props
    const display = img ? this._renderPicture(img, name) : this._renderInitials(initials)

    return (
      <SingleContactHeaderStyled className={'mod mod-molecules mod-molecules-singleContactHeader regular-typo'}>
        <div className={'row'}>
          <div className={'col col-xs-12 image-holder'}>{display}</div>
          <div className={'col col-xs-12 name-holder'}>
            <h3>{name}</h3>
            <p>{position}</p>
          </div>
        </div>
      </SingleContactHeaderStyled>
    )
  }
}
