import * as types from './types'

const initialState = {
  isFetching: false,
  token: null,
  email: null,
  id: null,
  uid: null,
  image: null,
  name: null,
  surname: null,
  company: null,
  position: null,
  department: null,
  phone1: null,
  phone2: null,
  languaje: 'en',
  sesionLoaded: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_AUTH_TOKEN:
      return { ...state, token: action.payload }
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_UID:
      return { ...state, uid: action.payload }
    case types.SET_EMAIL:
      return { ...state, email: action.payload }
    case types.SET_ID:
      return { ...state, id: action.payload }
    case types.SET_PROFILE:
      return { ...state, ...action.payload }
    case types.SET_SESION_LOADED:
      return { ...state, sesionLoaded: action.payload }

    case types.SET_LANGUAJE:
      return { ...state, languaje: action.payload }
    case types.LOG_OUT:
      return { ...initialState, sesionLoaded: true, languaje: state.languaje }
    default:
      return state
  }
}
