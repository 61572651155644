import styled from 'styled-components'

export const NotFoundStyled = styled.div`
  padding: 50px;
  text-align: center;

  img {
    opacity: 0.5;
  }

  .title {
    margin-top: 50px;
    font-size: 200px;
    color: #0d5bdc;
  }

  .subtitle {
    font-size: 30px;
  }
`
