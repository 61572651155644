import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
  return {
    profile: state.auth
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(View))
