import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ContactsOperations, ContactsActions } from '../../../../redux/contacts'
import { notify } from 'reapop'

const mapStateToProps = state => {
  return {
    email: state.auth.email,
    isFetching: state.contacts.isFetching,
    addProfile: state.contacts.addProfile,
    contacts: state.contacts.contacts
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetAddProfile: () => dispatch(ContactsActions.resetAddProfile()),
    checkProfileExist: email => dispatch(ContactsOperations.checkProfileExist(email)),
    addNewContactProfile: data => dispatch(ContactsOperations.addNewContactProfile(data)),
    addExistingContactProfile: () => dispatch(ContactsOperations.addExistingContactProfile()),
    notify: notif => dispatch(notify(notif))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
)
