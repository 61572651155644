//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, ModalHolder } from '../../../atoms'
import i18n from '../../../../assets/i18n'

export default class MyInvitations extends PureComponent {
  static propTypes = {
    showContactsBadge: PropTypes.bool,
    showProjectsBadge: PropTypes.bool,
    history: PropTypes.object
  }

  _onClickProjects = () => this.props.history.push('/my-profile/invitations/projects', { modal: true })
  _onClickContacts = () => this.props.history.push('/my-profile/invitations/contacts', { modal: true })
  _onClickClose = () => this.props.history.goBack()

  render() {
    const { showContactsBadge, showProjectsBadge } = this.props
    return (
      <ModalHolder className={'has-navbar has-side'} addClose={true} CloseOnClick={this._onClickClose}>
        <div className={'row regular-form slim-form-holder-nofloat'}>
          <div className={'col col-xs-12'}>
            <h2>{i18n.invitations.title}</h2>
          </div>
          <div className={'col col-xs-12 inliner'}>
            <Button
              label={i18n.projects.title}
              className={'clear-blue compact inline'}
              parentClass={'no-padd'}
              onClick={this._onClickProjects}
              showBadge={showProjectsBadge}
            />
            <Button
              label={i18n.contacts.title}
              className={'clear-blue compact inline'}
              parentClass={'no-padd'}
              onClick={this._onClickContacts}
              showBadge={showContactsBadge}
            />
          </div>
        </div>
      </ModalHolder>
    )
  }
}
