import styled from 'styled-components'

export const SplashScreenStyled = styled.div`
  @keyframes fadeinout {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.8;
    }
  }

  .inner {
    position: fixed;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;
    z-index: 9999;
    .splash {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;

      img {
        width: 180px;
        margin-bottom: 30px;
      }

      h5 {
        -webkit-animation: fadeinout 2s infinite linear;
        animation: fadeinout 2s linear infinite;
      }
    }
  }
`
