import styled from 'styled-components'

export const ModalStyled = styled.div`
  .holder-overlay {
    display: flex;
    padding: 20px;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .overlay {
      position: relative;
      background: ${props => props.theme.colors.white};
      width: 100%;
      overflow: auto;
      padding-left: 30px;
      pointer-events: all;
      > .mod-atoms-buttonIcon {
        position: absolute;
        top: 15px;
        right: 15px;
        pointer-events: all;
      }
      > .container {
        margin: 0 !important;
        max-width: 600px !important;
        background: ${props => props.theme.colors.white};
        padding: 15px;
      }
    }
  }

  .holder-overlay.on-center {
    .overlay {
      background: ${props => props.theme.colors.lightGreyOpacity};
      > .container {
        max-width: 100%;
        background: ${props => props.theme.colors.white};
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 0;
        .mod-atoms-modalFormHeader {
          margin-bottom: 0 !important;
        }
        .scrollbox {
          padding: 20px 0 0 0;
          width: 100%;
          max-height: 65vh;
          overflow: auto;
          background: ${props => props.theme.colors.white};
        }
      }
    }
  }

  .holder-overlay.has-side {
    padding-left: 0;
    .overlay {
      > .container {
        margin: 0 !important;
      }
    }
  }

  .holder-overlay.has-navbar {
    padding-top: 0;
  }

  @media only screen and (min-width: 48em) {
    .holder-overlay.has-side {
      padding-left: 33.33%;
    }
    .holder-overlay.has-navbar {
      padding-top: 110px;
    }
  }

  @media only screen and (min-width: 64em) {
    .holder-overlay.has-side {
      padding-left: 25%;
    }
  }

  @media only screen and (min-width: 75em) {
    .holder-overlay.has-side {
      padding-left: 16.66%;
    }
  }
`
