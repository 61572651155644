import React from 'react'
import Spinner from 'react-spinkit'

const CardSpinner = () => (
  <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 10 }}>
    <Spinner color={'#0D5BDC'} name={'circle'} fadeIn={'none'} />
  </span>
)

export default CardSpinner
