import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 80px 0;

  .input-holder {
    text-align: center;
  }
`
