//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonIcon, ModalHolder, CardSpiner } from '../../../atoms'
import { SearchBar, ContactCard } from '../../../molecules'
import { ContactsUtils } from '../../../../redux/contacts'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'

class ContactInvitationCard extends PureComponent {
  static propTypes = {
    onPressAction: PropTypes.func,
    contact: PropTypes.object,
    accepted: PropTypes.bool,
    userProfile: PropTypes.object,
    history: PropTypes.object,
    isFetching: PropTypes.bool
  }

  static defaultProps = {
    onPressAction: () => {},
    accepted: false,
    contact: null,
    isFetching: false
  }

  _onPressAction = () => this.props.onPressAction(this.props)

  render() {
    const { contact, accepted, userProfile, isFetching } = this.props
    const accButton = <ButtonIcon label={i18n.accept} icon={'toright'} color={'blue'} onClick={this._onPressAction} display={true} />
    const rejButton = <ButtonIcon label={i18n.reject} icon={'close'} color={'blue'} onClick={this._onPressAction} display={true} />
    const spinner = <CardSpiner />
    const actionButton = isFetching ? spinner : accepted ? rejButton : accButton
    return (
      <div className={'added-members-loop'}>
        <ContactCard contact={contact} forceActions={actionButton} profile={userProfile} />
      </div>
    )
  }
}

export default class MyInvitationsContacts extends PureComponent {
  static propTypes = {
    toggleIvitation: PropTypes.func,
    contacts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    contactsProfile: PropTypes.array,
    history: PropTypes.object
  }

  state = {
    searchText: '',
    isFetchingInvitationIds: {}
  }

  _setIsFetchingInvitationId = (id, isFetching) => this.setState({ isFetchingInvitationIds: { ...this.state.isFetchingInvitationIds, [id]: isFetching } })

  _onClickClose = () => this.props.history.goBack()

  _toggleContactIvitation = data => {
    const { id } = data
    this._setIsFetchingInvitationId(id, true)
    this.props.toggleIvitation(data).finally(() => this._setIsFetchingInvitationId(id, false))
  }

  _renderContacts = list => {
    const { contacts } = this.props
    const { isFetchingInvitationIds } = this.state
    return _.map(list, contact => (
      <ContactInvitationCard
        contact={contact}
        key={'contact' + contact.id}
        {...contact}
        accepted={contacts[contact.id]}
        onPressAction={this._toggleContactIvitation}
        isFetching={isFetchingInvitationIds[contact.id]}
      />
    ))
  }

  _renderNoData = (showNoData, noDataLabel) => {
    if (showNoData) {
      return (
        <span className={'regular-typo'}>
          <p className={'isclear'}>
            <em>{noDataLabel}</em>
          </p>
        </span>
      )
    }
  }

  render() {
    const { contactsProfile, contacts } = this.props
    const filtered = ContactsUtils.filterContactsByText(this.state.searchText, contactsProfile)
    const list = _.orderBy(filtered, ({ id }) => `${contacts[id]}`)
    const noDataLabel = this.state.searchText ? i18n.invitations.noDataFilter : i18n.invitations.noData
    const showNoData = _.isEmpty(list)
    return (
      <ModalHolder className={'has-navbar has-side'} addClose={true} CloseOnClick={this._onClickClose}>
        <div className={'row regular-form slim-form-holder-nofloat'}>
          <div className={'col col-xs-12'}>
            <h2>{i18n.contacts.title}</h2>
          </div>
          <div className={'col col-xs-12'}>
            <SearchBar label={i18n.search} value={this.state.searchText} onChange={v => this.setState({ searchText: v })} />
          </div>
          <div className={'col col-xs-12'}>
            {this._renderContacts(list)}
            {this._renderNoData(showNoData, noDataLabel)}
          </div>
        </div>
      </ModalHolder>
    )
  }
}
