import _ from 'lodash'
import { ContactsUtils } from '../contacts'

export const getInitials = ({ name }) => {
  const items = _.split(name, ' ')
  const first = _.upperCase(_.get(items, '[0][0]', ''))
  const second = _.upperCase(_.get(items, '[1][0]', ''))
  const initials = first + second
  return initials
}

export const filterByText = (searchText, sourceProjects) => {
  const sText = _.upperCase(searchText)
  const projects = !searchText ? sourceProjects : _.filter(sourceProjects, ({ name }) => _.upperCase(name).includes(sText))
  return projects
}

export const filterByName = (searchName, sourceProjects) => {
  return _.filter(sourceProjects, ({ name }) => name !== searchName)
}

export const includesName = (searchName, sourceProjects) => {
  return _.findIndex(sourceProjects, ({ name }) => name === searchName) >= 0
}

export const getProjectMembers = ({ project, contacts, profile }) => {
  const members = _.get(project, 'members')
  const projectContacts = _.map(members, (_, id) => {
    if (id === profile.id) return profile
    else return ContactsUtils.findContactByContact({ id }, contacts)
  })
  const filterUndefined = _.filter(projectContacts, value => !_.isUndefined(value))
  return filterUndefined
}

export const getProjectCompanys = ({ project, contacts, profile }) => {
  const projectContacts = getProjectMembers({ project, contacts, profile })
  const projectCompanys = _.map(projectContacts, ({ company }) => company)
  const projectCompanysUniq = _.uniqBy(projectCompanys, value => value.toUpperCase());
  const projectCompanysName = _.map(_.uniq(projectCompanysUniq), name => ({ name }))
  const filterUndefined = _.filter(projectCompanysName, value => !_.isUndefined(value))
  return filterUndefined
}

export const isProyectAdmin = (profile, project) => {
  const admins = _.get(project, 'admins')
  return _.find(admins, (_, id) => profile.id === id)
}

export const getCommonProjects = ({ projects0, projects1, projectsInfo }) => {
  const commonProjects = []
  _.forEach(projects0, (value, key) => {
    if (projects1[key]) {
      const sProject = _.find(projectsInfo, project => project.id === key)
      sProject && commonProjects.push(sProject)
    }
  })
  return commonProjects
}

export const getProjectNewMembersKeys = ({ members, project }) => {
  let newMembers = {}
  _.forEach(Object.keys(members), mId => {
    if (!project.members[mId]) {
      newMembers[mId] = true
    }
  })
  return newMembers
}

export const getProjectDeledtedMembersKeys = ({ members, project }) => {
  let deletedMembers = {}
  _.forEach(Object.keys(project.members), mId => {
    if (!members[mId]) {
      deletedMembers[mId] = true
    }
  })
  return deletedMembers
}
