import View from './view'
import { connect } from 'react-redux'
import { ContactsUtils } from '../../../../redux/contacts'

const mapStateToProps = state => {
  const allInvitationsTreated = ContactsUtils.allInvitationsTreated(state.contacts.contacts) && ContactsUtils.allInvitationsTreated(state.projects.projects)
  return {
    showInvitationsBadge: !allInvitationsTreated
  }
}

export default connect(
  mapStateToProps,
  null
)(View)
