import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ModalFormHeaderStyled } from './styled'
import { ButtonIcon } from '../../atoms'

export default class ModalFormHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    onClickClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onClickBack: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
  }

  static defaultProps = {
    title: '',
    onClickClose: false,
    onClickBack: false
  }

  render() {
    const { title, onClickClose, onClickBack } = this.props
    return (
      <ModalFormHeaderStyled className={'mod mod-atoms mod-atoms-modalFormHeader'}>
        <div className={'row regular-typo'}>
          <div className={'col col-xs-12 title-holder'}>
            <h4>{title}</h4>
            {onClickClose && (
              <div className={'button-holder'}>
                <ButtonIcon onClick={onClickClose} icon={'close'} back={'trans'} color={'white'} />
              </div>
            )}
            {onClickBack && (
              <div className={'button-holder'}>
                <ButtonIcon onClick={onClickBack} icon={'toright'} back={'trans'} color={'white'} />
              </div>
            )}
          </div>
        </div>
      </ModalFormHeaderStyled>
    )
  }
}
