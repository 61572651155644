import React from 'react'
import PropTypes from 'prop-types'
import { InputTextStyled } from './styled'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import es from 'react-phone-number-input/locale/es'

export default class TextInput extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    validation: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    inIcon: PropTypes.element,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    parentClass: PropTypes.string,
    checked: PropTypes.bool,
    appendNote: PropTypes.string
  }

  static defaultProps = {
    id: null,
    label: '',
    value: '',
    type: 'text',
    placeholder: '',
    validation: null,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    autoFocus: false,
    className: '',
    inIcon: null,
    disabled: false,
    maxLength: null,
    parentClass: '',
    checked: null,
    appendNote: null
  }

  constructor(props) {
    super(props)
    this.state = {
      touched: false,
      valid: true,
      error: ''
    }
  }

  validate(v) {
    if (this.props.validation) {
      const value = v != null ? v : this.props.value
      const validation = this.props.validation(value)
      this.setState({ valid: validation.isValid, error: validation.error, touched: true })
      return validation.isValid
    } else {
      return true
    }
  }

  setValid(touched, valid, error) {
    this.setState({ touched, valid, error })
  }

  _onBlur(e) {
    this.validate()
    this.props.onBlur(e)
  }

  _onFocus(e) {
    this.setState({ touched: true })
    this.props.onFocus(e)
  }

  _onChange(value) {
    this.props.onChange(value)
  }

  render() {
    const showError = this.state.touched && this.state.error ? true : false
    const mandatory = this.props.validation === null || this.props.type === 'checkbox' ? '' : <span className={'mand'}>*</span>
    const showAppendote = this.props.appendNote ? <span className={'append-note'}>{this.props.appendNote}</span> : ''

    return (
      <InputTextStyled className={this.props.className + ' mod mod-atoms mod-atoms-textInput '}>
        <div className={'input-holder'}>
          <div className={'inner ' + this.props.parentClass}>
            <label htmlFor={this.props.id} className={'phone-label'}>
              {this.props.label}
              {mandatory}
            </label>
            <PhoneInput
              id={this.props.id}
              name={this.props.id}
              type={this.props.type}
              onChange={e => this._onChange(e)}
              onFocus={e => this._onFocus(e)}
              onBlur={e => this._onBlur(e)}
              value={this.props.value}
              placeholder={this.props.placeholder}
              autoComplete={'off'}
              autoFocus={this.props.autoFocus}
              style={this.props.inIcon ? { backgroundImage: `url( ${this.props.inIcon} )` } : {}}
              disabled={this.props.disabled}
              maxLength={this.props.maxLength}
              checked={this.props.checked}
              labels={es}
            />

            {showError && <span className={'error-message '}>{this.state.error}</span>}
          </div>
          {showAppendote}
        </div>
      </InputTextStyled>
    )
  }
}
