/* eslint-disable no-console */

import _ from 'lodash'
import { CallsActions } from './'
import { ContactsUtils } from '../contacts'
import { ProjectsUtils } from '../projects'

let firebaseCallsListener = null

export const setCallsListener = () => (dispatch, getState, { firebase, firebaseConfig }) => {
  removeCallsListener()

  const { id } = getState().auth
  const meCallsRef = `${firebaseConfig.refs.profile}/${id}/calls`
  firebaseCallsListener = firebase.database().ref(meCallsRef)
  firebaseCallsListener.on('value', snapshot => {
    const calls = snapshot ? snapshot.val() : null
    dispatch(CallsActions.setLastCalls(calls))
  })
}

export const removeCallsListener = () => {
  firebaseCallsListener && firebaseCallsListener.off('value')
}

export const call = (contact, phone = null) => dispatch => {
  dispatch(callContact(contact, phone))
  dispatch(postCallContact(contact))
}

const callContact = (contact, phone = null) => () => {
  if (!contact) return
  let contactPhone = null
  if (phone) contactPhone = phone
  else if (contact.phone1) contactPhone = contact.phone1
  else if (contact.phone2) contactPhone = contact.phone2
  const url = `tel:${contactPhone}`
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
}

const sendEmailContactsWithSeparator = (contacts, separator = ',') => () => {
  const mailto = _.map(contacts, v => (v ? v.email : '')).join(`${separator} `)
  const url = `mailto:${mailto}`
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
}

export const sendEmailContacts = contacts => (dispatch, getState, { i18n, notify }) => {
  const onClickOutlook = () => dispatch(sendEmailContactsWithSeparator(contacts, ';'))
  const onClickOthers = () => dispatch(sendEmailContactsWithSeparator(contacts, ','))

  dispatch(
    notify({
      title: i18n.mailto.title,
      message: i18n.mailto.message,
      status: 'info',
      dismissAfter: 0,
      dismissible: true,
      buttons: [
        {
          name: i18n.outlook,
          primary: false,
          onClick: onClickOutlook
        },
        {
          name: i18n.others,
          primary: true,
          onClick: onClickOthers
        }
      ]
    })
  )

  return Promise.resolve()
}

export const sendEmailProjects = projects => (dispatch, getState) => {
  const currentUserId = getState().auth.id
  const { contactsProfile } = getState().contacts

  const membersId = []
  _.forEach(projects, ({ members }) => {
    _.forEach(members, (_, id) => {
      if (id !== currentUserId) membersId.push(id)
    })
  })
  const uniqMembersId = _.uniq(membersId)
  const contacts = _.map(uniqMembersId, id => ContactsUtils.findContactByContact({ id }, contactsProfile))
  dispatch(sendEmailContacts(contacts))
}

export const sendEmailCompanys = (companys, project) => (dispatch, getState) => {
  const profile = getState().auth
  const { contactsProfile } = getState().contacts
  const contacts = ProjectsUtils.getProjectMembers({ profile, project, contacts: contactsProfile })
  const filterContacts = _.filter(contacts, ({ company }) => {
    const belongsCompany = _.findIndex(companys, ({ name }) => name.toUpperCase() === company.toUpperCase()) >= 0
    return belongsCompany
  })
  dispatch(sendEmailContacts(filterContacts))
}

const postCallContact = contact => (dispatch, getState, { api }) => {
  const { contacts } = getState().contacts
  const isContact = _.find(contacts, (_, key) => key === contact.id)
  if (!isContact || !_.get(contact, 'id')) return
  api.postCall(contact).catch(error => console.log(error))
}
