import React, { Component } from 'react'
import { SplashScreenStyled } from './styled'
import { logoColor } from '../../../assets/images/'
import i18n from '../../../assets/i18n'

export default class SplashScreen extends Component {
  static defaultProps = {
    title: '',
    subtitle: ''
  }

  render() {
    return (
      <SplashScreenStyled className={'mod mod-atoms mod-atoms-authHeader regular-typo'}>
        <div className={'inner'}>
          <div className={'splash'}>
            <img src={logoColor} alt={'WGL'} />
            <h5>{i18n.loading}...</h5>
          </div>
        </div>
      </SplashScreenStyled>
    )
  }
}
