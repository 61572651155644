import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import { ButtonStyled } from './styled'

export default class Button extends Component {
  static propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    isFetching: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    parentClass: PropTypes.string,
    labeledSpinner: PropTypes.bool,
    showBadge: PropTypes.bool
  }

  static defaultProps = {
    label: '',
    onClick: null,
    isFetching: false,
    disabled: false,
    className: '',
    parentClass: '',
    labeledSpinner: false,
    showBadge: false
  }

  _handleClick(e) {
    if (!this.props.isFetching) {
      this.props.onClick && this.props.onClick(e)
    }
  }

  _renderContent() {
    const { isFetching, labeledSpinner, showBadge } = this.props
    return (
      <span>
        {isFetching && <Spinner color={labeledSpinner ? 'white' : '#11A8AD'} name={'circle'} fadeIn={'none'} />}
        {showBadge && (
          <div className="button-badge">
            <span>!</span>
          </div>
        )}
        {(labeledSpinner || !isFetching) && <span>{this.props.label}</span>}
      </span>
    )
  }

  render() {
    const { disabled } = this.props
    const type = this.props.onClick ? 'button' : 'submit'
    return (
      <ButtonStyled className={'mod mod-atoms mod-atoms-button'} disabled={disabled}>
        <div className={'input-holder'}>
          <div className={'inner ' + this.props.parentClass}>
            <button className={this.props.className} disabled={disabled} type={type} onClick={this._handleClick.bind(this)}>
              {this._renderContent()}
            </button>
          </div>
        </div>
      </ButtonStyled>
    )
  }
}
