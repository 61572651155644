import styled from 'styled-components'

import { WhiteMail, WhiteMailAlt } from '../../../assets/images/'

export const ButtonStyled = styled.span`
  display: inline-block;

  button {
    background: ${props => (props.selected ? props.theme.colors.tint : props.theme.colors.mediumGrey)} !important;
    padding: 0;
    height: 36px;
    width: 36px;
    line-height: 36px;
    border-radius: 50%;
    position: relative;
    position: relative;
    :hover {
      transform: scale(1.1, 1.1);
    }
    span {
      display: none;
    }
    :before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 26px;
      height: 26px;
      content: '';
      display: block;
      background-size: 21px;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .mail.white {
    button {
      :before {
        background-image: url(${WhiteMail});
        background-size: 22px;
      }
    }
  }
  .mail-alt.white {
    button {
      :before {
        background-image: url(${WhiteMailAlt});
      }
    }
  }

  .back-blue0 {
    button {
      background: ${props => props.theme.colors.blue0};
    }
  }
  .back-blue1 {
    button {
      background: ${props => props.theme.colors.blue1};
    }
  }
  .back-blue2 {
    button {
      background: ${props => props.theme.colors.blue2};
    }
  }
  .back-blue3 {
    button {
      background: ${props => props.theme.colors.blue3};
    }
  }
  .back-blue4 {
    button {
      background: ${props => props.theme.colors.blue4};
    }
  }
  .back-black {
    button {
      background: ${props => props.theme.colors.black};
    }
  }
  .back-white {
    button {
      background: ${props => props.theme.colors.white};
    }
  }
  .back-tint {
    button {
      background: ${props => props.theme.colors.tint};
    }
  }
  .back-red {
    button {
      background: ${props => props.theme.colors.red};
    }
  }
  .back-blue {
    button {
      background: ${props => props.theme.colors.blue};
    }
  }
  .back-yellow {
    button {
      background: ${props => props.theme.colors.yellow};
    }
  }
  .back-green {
    button {
      background: ${props => props.theme.colors.green};
    }
  }
  .back-purple {
    button {
      background: ${props => props.theme.colors.purple};
    }
  }
  .back-grey {
    button {
      background: ${props => props.theme.colors.grey};
    }
  }

  .back-grey2 {
    button {
      background: ${props => props.theme.colors.grey2};
    }
  }

  .back-darkGrey {
    button {
      background: ${props => props.theme.colors.darkGrey};
    }
  }
  .back-mediumGrey {
    button {
      background: ${props => props.theme.colors.mediumGrey};
    }
  }
  .back-lightGrey {
    button {
      background: ${props => props.theme.colors.lightGrey};
    }
  }
  .back-background {
    button {
      background: ${props => props.theme.colors.background};
    }
  }
  .back-trans {
    button {
      background: transparent;
    }
  }
`
