import styled from 'styled-components'

export const CardHolderStyled = styled.div`
  .container {
    background: ${props => props.theme.colors.white};
  }

  .is-selected-card {
    background: ${props => props.theme.colors.lightGrey};
  }

  .row {
    padding: 0 10px;
  }

  .inner-cell {
    border-bottom: 1px solid ${props => props.theme.colors.mediumGrey};
    padding: 14px 10px;

    .actions-holder {
      text-align: right;
      span {
        > span {
          margin: 0 5px 0 0;
          :last-child {
            margin: 0;
          }
        }
      }
    }
  }

  :hover {
    .inner-cell {
      background: #f7f7f7;
    }
  }
`
