export default {
  outlook: 'Outlook',
  others: 'Otros',
  loading: 'Cargando...',
  next: 'Siguiente',
  back: 'Atrás',
  finish: 'Finalizar',
  send: 'Enviar',
  save: 'Guardar',
  continue: 'Continuar',
  edit: 'Editar',
  email: 'Correo electrónico',
  password: 'Contraseña',
  firstName: 'Nombre',
  lastName: 'Apellidos',
  phone1: 'Teléfono 1',
  phone2: 'Teléfono 2',
  description: 'Descripción',
  cancel: 'Cancelar',
  accept: 'Aceptar',
  reject: 'Rechazar',
  company: 'Empresa',
  position: 'Cargo',
  department: 'Departamento',
  commonProjects: 'Proyectos en común',
  imageLabel: 'Añadir foto',
  imagePlaceholder: 'Pinche aquí o arrastre su imagen',
  download: 'DESCARGAR',
  companies: 'Empresas',
  contact: 'Contacto',
  sendEmail: 'Enviar email',
  copyEmail: 'Copiar',
  copyClipboard: 'Copiado al portapapeles',

  validations: {
    email: 'Debes introducir un email válido',
    mandatory: 'Campo obligatorio',
    match: 'No coinciden',
    length: 'Mínimo 8 caracteres',
    minLength: 'Selecciona al menos {0} elemento',
    maxLength: 'Selecciona máximo {0} elementos',
    minMaxValidation: 'Debe escoger mínimo {0} elemento y máximo {1}',
    url: 'Introduzca una url válida'
  },
  auth: {
    welcome: 'Bienvenido a',
    login: 'Iniciar sesión',
    register: 'Registrarme',
    forgotPassword: 'Recuperar contraseña',
    aceptTerms: 'Acepto los',
    terms: 'Términos y Condiciones',
    restorePassInfo: 'Introduce el email con el que te registraste y te enviaremos una nueva contraseña.',
    restorePassSuccess: 'Te hemos enviado un email para que puedas recuperar tu contraseña. Revisa tu bandeja de entrada.',
    changePasswordSucces: 'Contraseña actualizada',
    changeProfileSucces: 'Datos actualizados',
    changeEmailSucces: 'Email actualizado',
    changeEmail: 'Actualizar email',
    changePassword: 'Actualizar contraseña',
    completeInfo: 'Por favor, para continuar confirme o rellene sus datos.',
    name: 'Nombre',
    email: 'Email',
    emailPlaceholder: 'usuario@email.com',
    newEmail: 'Nuevo email',
    currentPass: 'Contraseña actual',
    newPass: 'Contraseña nueva (Mínimo 8 caracteres)',
    pass: 'Contraseña (Mínimo 8 caracteres)',
    passPlaceholder: '12345678',
    namePlaceholder: 'Nombre',
    surname: 'Apellidos',
    surnamePlaceholder: 'Apellidos',
    company: 'Empresa',
    companyPlaceholder: 'Empresa',
    position: 'Cargo',
    positionPlaceholder: 'Cargo',
    department: 'Departamento',
    departmentPlaceholder: 'Departamento',
    phone1: 'Teléfono 1',
    phone1Placeholder: '+34666666666',
    phone2: 'Teléfono 2',
    phone2Placeholder: '+34666666666',
    errors: {
      'auth/wrong-password': {
        title: 'Contraseña inválida',
        message: "La contraseña introducida no es válida. Vuelve a introducirla o si quieres puedes recuperarla pulsando en 'Recuperar contraseña'"
      },
      'auth/invalid-email': {
        title: 'Email inválido',
        message: 'El email no tiene un formato válido.'
      },
      'auth/user-disabled': {
        title: 'Usuario desactivado',
        message: 'El usuario está desactivado. Por favor, ponte en contacto con un administrador.'
      },
      'auth/user-not-found': {
        title: 'Usuario no encontrado',
        message: "El usuario no está registrado. Si quieres, puedes registrarte pulsando en 'Registrarme'."
      },
      'auth/email-already-in-use': {
        title: 'Usuario ya registrado',
        message: "El email ya está en uso. Puedes recuperar tu contraseña pulsando en 'Iniciar sesión'"
      },
      'auth/operation-not-allowed': {
        title: 'Operación no permitida',
        message: 'El registro de usuarios está temporalmente desactivado.'
      },
      'auth/weak-password': {
        title: 'Contraseña insegura',
        message: 'Debes introducir una contraseña segura de al menos 8 caracteres.'
      },
      unknown: {
        title: 'Error',
        message: 'La operación no ha podido completarse. Revise su conexión a internet.'
      },
      emailNoVerified: {
        title: 'Email no verificado',
        message: 'Debes validar tu email para poder usar la app. Revisa tu bandeja de entrada y sigue las instrucciones para activar tu cuenta.',
        resend: 'Reenviar'
      },
      emailSended: {
        title: 'Email enviado',
        message: 'Revisa tu bandeja de entrada'
      },
      tooManyRequests: {
        title: 'Demasiadas peticiones',
        message: 'Has llegado al límite de peticiones. Vuelve a intentarlo en unos minutos'
      }
    }
  },
  modal: {
    acept: 'ACEPTAR',
    info: 'Información'
  },
  photosModal: {
    cancel: 'Cancelar',
    takePhoto: 'Hacer una foto',
    chooseFromLibrary: 'Elegir de la galería',
    permissionDenied: {
      title: 'Permiso denegado',
      text: 'Es necesario para poder seleccionar la foto',
      reTryTitle: 'Volver a pedir',
      okTitle: 'Aceptar'
    }
  },
  projects: {
    title: 'Proyectos',
    noData: 'No tienes proyectos, por favor, añade uno para continuar.',
    noDataFilter: 'No hay proyectos asociados a la búsqueda.'
  },
  projectAdd: {
    title: 'Nuevo proyecto',
    name: 'Nombre proyecto',
    namePlaceholder: 'Escribe aquí el nombre',
    members: 'Miembros',
    addMember: 'Añadir miembro',
    addMemberPlaceholder: 'Busca por nombre ...',
    colorTitle: 'Color',
    colorLabel: 'Asigna un color',
    newMember: 'Añadir nuevo contacto',
    noName: 'Para continear, es necesario que añadas un nombre al proyecto.'
  },
  projectEdit: {
    title: 'Editar proyecto',
    admin: 'Administrador',
    succesMessage: 'Proyecto editado con éxito'
  },
  companyEdit: {
    title: 'Editar Empresa'
  },
  contacts: {
    title: 'Contactos',
    book: 'Agenda',
    noCompany: 'Sin empresa',
    noDepartment: '',
    sendEmail: 'Enviar email',
    copyEmail: 'Copiar',
    noData: 'No tienes contactos, por favor, añade uno para continuar.',
    noDataFilter: 'No hay contactos asociados a la búsqueda.',
    commonProjects: 'Projectos en común',
    check: 'Comprobar'
  },
  contactAdd: {
    title: 'Nuevo contacto',
    noMember: 'Este usuario todavia no es miembro de WGL, rellene su perfil, y si lo desea, el resto de sus datos y será notificado.',
    alreadyMember: 'Este usuario ya es miembro de WGL.',
    isCurrentUser: 'El email introducido coincide con el de tu perfil. Puedes pulsar en Nuevo para añadir un nuevo contacto.',
    alreadyContact: 'El usuario ya pertenece a tu lista de contactos. Puedes pulsar en Nuevo para añadir un nuevo contacto.',
    createContact: 'Crear contacto'
  },
  contactEdit: {
    title: 'Editar contacto'
  },
  calls: {
    title: 'Llamadas',
    titleLarge: 'Últimas llamadas',
    noData: 'No tienes llamadas.'
  },
  profile: {
    title: 'Mi perfil'
  },
  myProfile: {
    title: 'Mi perfil',
    qrCOde: 'QR Code',
    editImage: 'Editar imagen'
  },
  invitations: {
    title: 'Invitaciones',
    noData: 'No tienes invitaciones.',
    noDataFilter: 'No hay invitaciones asociadas a la búsqueda.',
    deleteProyectConfirm: {
      title: '¿Estás segur@?',
      message: 'Eres el único administrador. Si continúas, se borrará el proyecto para todos los usuarios. Esta acción no puede deshacerse.'
    },
    contacts: {
      acept: 'Aceptar',
      reject: 'Bloquear',
      noModifyInfo: 'No es posible bloquear al contacto por que teneís algún proyecto en común.'
    },
    projects: {
      acept: 'Ingresar en grupo',
      reject: 'Salir del grupo'
    }
  },
  terms: {
    title: 'Términos y política',
    terms: 'Términos y condiciones',
    privacy: 'Política de privacidad'
  },
  myProfileEdit: {
    title: 'Editar Perfil'
  },
  languaje: {
    title: 'Idioma',
    es: 'Español',
    en: 'English'
  },
  sendgrid: {
    inviteApp: {
      subject: '{name} {surname} ({email}) le ha invitado a WGL',
      content: 'WGL es una app para tener siempre a mano y bien organizados tus contactos.'
    },
    inviteProject: {
      subject: '{name} {surname} ({email}) le ha invitado al projecto {project}',
      content: 'Has sido añadido a un proyecto, entra y verifica perfil.'
    },
    projectDeleted: {
      subject: '{name} {surname} ({email}) ha eliminado el projecto {project}',
      content: 'El proyecto ha sido eliminado pero tus contactos siguen guardados.'
    }
  },

  success: {
    companyEdit: 'Compañía editada correctamente',
    companyAdd: 'Compañía creada correctamente',
    contactEdit: 'Contacto editado correctamente',
    contactAdd: 'Contacto creado correctamente',
    contactAddError: 'Ha ocurrido un error y no se ha podido añadir al contacto',
    contactInvite: 'Invitación enviada',
    projectEdit: 'Proyecto editado correctamente',
    projectAdd: 'Proyecto creado correctamente'
  },
  error: {
    message: 'Ha ocurrido un error'
  },

  search: 'Buscar',
  delete: 'Eliminar',
  invite: 'Invitar',
  new: 'Nuevo',
  emailNoVerified: {
    title: 'Email no verificado',
    message: 'Necesitas validar tu cuenta para poder usar la app. Por favor, revisa tu bandeja de entrada'
  },
  home: 'Home',
  help: 'Ayuda',
  signOut: 'Salir',
  landing: {
    available: 'Disponible en'
  },
  mailto: {
    title: '¿Cúal es tu gestor de correo?',
    message: 'Elige otros en caso de que no sea Outlook'
  },
  project: 'Proyecto'
}
