import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ContactsUtils } from '../../../../redux/contacts'

const mapStateToProps = state => ({
  showContactsBadge: !ContactsUtils.allInvitationsTreated(state.contacts.contacts),
  showProjectsBadge: !ContactsUtils.allInvitationsTreated(state.projects.projects)
})

export default connect(
  mapStateToProps,
  null
)(withRouter(View))
