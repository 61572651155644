import styled from 'styled-components'

export const ButtonStyled = styled.div`
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  .sk-spinner {
    /* width: 100px; */
    display: inline-block;
    position: absolute;
    right: 20px;
    color: white;
  }

  .button-badge {
    width: 16px;
    height: 16px;
    border-radius: 14px;
    background-color: red;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    span {
      color: white;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .inner {
    position: relative;
    padding: 19px 0 22px 0;
  }

  .inner.no-padd {
    position: relative;
    padding: 0 !important;
  }

  .inner.no-bottom-padd {
    padding-bottom: 0 !important;
  }

  .inner.no-top-padd {
    padding-top: 0 !important;
  }

  .inner.bottom-padd-big {
    padding-bottom: 45px;
  }

  button {
    color: ${props => props.theme.colors.tint};
    display: block;
    padding: 10px 20px;
    line-height: 26px;
    text-align: center;
    border-radius: 10px;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: uppercase;
    background: transparent;
    border: 2px solid transparent;
    :active {
      position: relative;
      top: 1px;
    }
  }

  /* LAYOUTS */

  .block {
    display: block;
    width: 100% !important;
  }
  .inline {
    display: inline-block;
    max-width: 100%;
  }

  /* SIZES */

  .mini {
    padding: 3px 8px;
    font-size: 12px;
  }

  .compact {
    padding: 5px 10px;
    font-size: 14px;
  }

  .jumbo {
    padding: 20px 30px;
    font-size: 20px;
  }

  /* COLORS */

  .clear-blue {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.tint};
    border-color: ${props => props.theme.colors.tint};
    :hover {
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.tint};
      border-color: ${props => props.theme.colors.tint};
    }
  }

  .dark-blue {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.black};
    border-color: ${props => props.theme.colors.black};
    :hover {
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.black};
      border-color: ${props => props.theme.colors.black};
    }
  }

  .clear-gray {
    background: ${props => props.theme.colors.mediumGrey};
    :hover {
      color: ${props => props.theme.colors.black};
      background: ${props => props.theme.colors.mediumGrey};
      border-color: ${props => props.theme.colors.mediumGrey};
    }
  }

  .dark-gray {
    color: ${props => props.theme.colors.black};
    background: ${props => props.theme.colors.grey};
    border-color: ${props => props.theme.colors.grey};
    :hover {
      color: ${props => props.theme.colors.black};
      background: ${props => props.theme.colors.grey};
      border-color: ${props => props.theme.colors.grey};
    }
  }

  .link-alike {
    text-transform: none;
    color: ${props => props.theme.colors.black};
    border-radius: 0;
    border-bottom-color: ${props => props.theme.colors.tint};
    padding: 0;
    border: 0 0 2px 0;
  }

  .simple-link-alike {
    text-transform: none;
    color: ${props => props.theme.colors.tint};
    border-radius: 0;
    padding: 0;
    border: 0;
  }

  .black-link-alike {
    text-transform: none;
    color: ${props => props.theme.colors.black};
    border-radius: 0;
    padding: 0;
    border: 0;
  }

  /* APPEND ICON */

  .has-apicon {
    padding-right: 30px;
    position: relative;
    :before {
      right: 6px;
      top: 50%;
      margin-top: -10px;
      position: absolute;
      display: block;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      content: '';
      font-size: 16px;
      font-family: 'Ionicons';
      -webkit-transition: all 200ms ease;
      -moz-transition: all 200ms ease;
      -ms-transition: all 200ms ease;
      -o-transition: all 200ms ease;
      transition: all 200ms ease;
    }
    :hover {
      :before {
        right: 0;
      }
    }
  }

  .apicon-rgarrow {
    :before {
      content: '\f3d1';
    }
  }
`
