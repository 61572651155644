//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import Utils from '../../../utils'
import { Button, TextInput, PhoneInput, FileInput, ModalFormHeader } from '../../atoms'
import { ProfileInfoStep } from '../../molecules'
import _ from 'lodash'
import Spinner from 'react-spinkit'

export default class ProfileForm extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClickCloseModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    headTitle: PropTypes.string,
    addNote: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }

  static defaultProps = {
    isFetching: false,
    onSubmit: () => {},
    onClickCloseModal: false,
    headTitle: '',
    addNote: false
  }

  constructor(props) {
    super(props)
    this.formInputs = {}
    this.state = {
      image: _.get(props, 'profile.image', null),
      name: _.get(props, 'profile.name', ''),
      surname: _.get(props, 'profile.surname', ''),
      company: _.get(props, 'profile.company', ''),
      position: _.get(props, 'profile.position', ''),
      department: _.get(props, 'profile.department', ''),
      phone1: _.get(props, 'profile.phone1', ''),
      phone2: _.get(props, 'profile.phone2', '')
    }
  }

  _onSubmit = e => {
    e.preventDefault()
    const mandatoryFields = !_.isEmpty(_.get(this.state, 'name')) && !_.isEmpty(_.get(this.state, 'surname')) && !_.isEmpty(_.get(this.state, 'company'))
    if (mandatoryFields && Utils.validateForm(this.formInputs)) {
      const data = {
        image: _.get(this.state, 'image', null),
        name: _.get(this.state, 'name', ''),
        surname: _.get(this.state, 'surname', ''),
        company: _.get(this.state, 'company', ''),
        position: _.get(this.state, 'position', ''),
        department: _.get(this.state, 'department', ''),
        phone1: _.get(this.state, 'phone1', ''),
        phone2: _.get(this.state, 'phone2', '')
      }
      this.props.onSubmit(data)
    }
  }

  render() {
    const { addNote, onClickCloseModal, headTitle, isFetching } = this.props

    return (
      <form className={'regular-form mid-form-holder-nofloat'} onSubmit={this._onSubmit}>
        <ModalFormHeader title={headTitle} onClickClose={onClickCloseModal} />
        <div className={'scrollbox row'}>
          <div className={'col col-xs-12 center'}>{addNote && <ProfileInfoStep label={addNote} />}</div>
          <div className={'col col-xs-12 center'}>
            <FileInput
              ref={i => (this.formInputs.image = i)}
              id={'image'}
              label={i18n.imageLabel}
              value={this.state.image}
              onChange={image => this.setState({ image })}
            />
          </div>
          <div className={'col col-xs-12 col-sm-6'}>
            <TextInput
              innerRef={i => (this.formInputs.name = i)}
              id={'name'}
              label={i18n.firstName}
              placeholder={' '}
              value={this.state.name}
              onChange={name => this.setState({ name })}
              validation={v => Utils.mandatoryValidate(v)}
            />
            <TextInput
              innerRef={i => (this.formInputs.surname = i)}
              id={'surname'}
              label={i18n.lastName}
              placeholder={' '}
              value={this.state.surname}
              onChange={surname => this.setState({ surname })}
              validation={v => Utils.mandatoryValidate(v)}
            />
            <TextInput
              innerRef={i => (this.formInputs.company = i)}
              id={'company'}
              label={i18n.company}
              placeholder={' '}
              value={this.state.company}
              onChange={company => this.setState({ company })}
              validation={v => Utils.mandatoryValidate(v)}
            />
            <TextInput
              innerRef={i => (this.formInputs.position = i)}
              id={'position'}
              label={i18n.position}
              placeholder={' '}
              value={this.state.position}
              onChange={position => this.setState({ position })}
            />
          </div>
          <div className={'col col-xs-12 col-sm-6'}>
            <TextInput
              innerRef={i => (this.formInputs.department = i)}
              id={'department'}
              label={i18n.department}
              placeholder={' '}
              value={this.state.department}
              onChange={department => this.setState({ department })}
            />
            <PhoneInput
              innerRef={i => (this.formInputs.phone1 = i)}
              id={'phone1'}
              label={i18n.phone1}
              placeholder={' '}
              value={this.state.phone1}
              onChange={phone1 => this.setState({ phone1 })}
            />
            <PhoneInput
              innerRef={i => (this.formInputs.phone2 = i)}
              id={'phone2'}
              label={i18n.phone2}
              placeholder={' '}
              value={this.state.phone2}
              onChange={phone2 => this.setState({ phone2 })}
            />
          </div>
          <div className={'col col-xs-12 center'}>
            {isFetching ? (
              <Spinner color={'#0D5BDC'} name={'circle'} fadeIn={'none'} style={{ margin: '20px auto' }} />
            ) : (
              <Button label={i18n.save} className={'clear-blue inline'} />
            )}
          </div>
        </div>
      </form>
    )
  }
}
