export default {
  outlook: 'Outlook',
  others: 'Others',
  loading: 'Loading...',
  next: 'Next',
  back: 'Back',
  finish: 'Finish',
  send: 'Send',
  save: 'Save',
  continue: 'Continue',
  edit: 'Edit',
  email: 'Email',
  password: 'Password',
  firstName: 'Name',
  lastName: 'Surname',
  phone1: 'Telephone 1',
  phone2: 'Telephone 2',
  description: 'Description',
  cancel: 'Cancel',
  accept: 'Accept',
  reject: 'Reject',
  company: 'Company',
  position: 'Title',
  department: 'Department',
  commonProjects: 'Projects in common',
  imageLabel: 'Add pic',
  imagePlaceholder: 'Click here or drag image',
  download: 'DOWNLOAD',
  companies: 'Companies',
  contact: 'Contact',
  sendEmail: 'Send email',
  copyEmail: 'Copy',
  copyClipboard: 'Copied to clipboard',

  validations: {
    email: 'You must insert a valid email',
    mandatory: 'Mandatory',
    match: "Don't match",
    length: 'Minimum 8 characters',
    minLength: 'Select at least {0} element',
    maxLength: 'Select a maximum of {0} elements',
    minMaxValidation: 'You must choose a minimu of {0} elements and a maximum {1}',
    url: 'Please introcude a valid url'
  },
  auth: {
    welcome: 'Welcome to',
    login: 'Log in',
    register: 'Register',
    forgotPassword: 'Forgot password',
    aceptTerms: 'Accept the',
    terms: 'Terms & Conditions',
    restorePassInfo: 'Enter the email with which you registered and we will send you a new password.',
    restorePassSuccess: 'We have sent you an email so you can recover your password. Check your inbox.',
    changePasswordSucces: 'Password updated',
    changeProfileSucces: 'Data updated',
    changeEmailSucces: 'Email updated',
    changeEmail: 'Email updated',
    changePassword: 'Change password',
    completeInfo: 'Please, to continue confirm or fill in your information.',
    name: 'Name',
    email: 'Email',
    emailPlaceholder: 'user@email.com',
    newEmail: 'New email',
    currentPass: 'Current password',
    newPass: 'New password (minimum 8 characters)',
    pass: 'Password (Minimum 8 characters)',
    passPlaceholder: '12345678',
    namePlaceholder: 'Name',
    surname: 'Surname',
    surnamePlaceholder: 'Surname',
    company: 'Company',
    companyPlaceholder: 'Company',
    position: 'Position',
    positionPlaceholder: 'Position',
    department: 'Department',
    departmentPlaceholder: 'Department',
    phone1: 'Telephone 1',
    phone1Placeholder: '+44 55555555',
    phone2: 'Telephone 2',
    phone2Placeholder: '+44 55555555',
    errors: {
      'auth/wrong-password': {
        title: 'Invalid password',
        message: "The password is incorrect. Re-enter it or if you want you can recover it by clicking on 'Recover password'"
      },
      'auth/invalid-email': {
        title: 'Invalid email',
        message: 'The email does not have a valid format.'
      },
      'auth/user-disabled': {
        title: 'Deactivated user',
        message: 'The user is deactivated. Please, get in touch with an administrator.'
      },
      'auth/user-not-found': {
        title: 'User not found',
        message: "The user is not registered. If you want, you can register by clicking on 'Register'."
      },
      'auth/email-already-in-use': {
        title: 'User already registered',
        message: "The email is already in use. You can recover your password by clicking on 'Log in'"
      },
      'auth/operation-not-allowed': {
        title: 'Operation not allowed',
        message: 'The user registry is temporarily disabled.'
      },
      'auth/weak-password': {
        title: 'Unsafe password',
        message: 'You must enter a secure password of at least 8 characters.'
      },
      unknown: {
        title: 'Error',
        message: 'The operation could not be completed. Check your internet connection.'
      },
      emailNoVerified: {
        title: 'Email not verified',
        message: 'You must validate your email in order to use the app. Check your inbox and follow the instructions to activate your account.',
        resend: 'Reenviar'
      },
      emailSended: {
        title: 'Email sent',
        message: 'Check your inbox'
      },
      tooManyRequests: {
        title: 'Too many requests',
        message: 'You have reached the limit of requests. Try again in a few minutes'
      }
    }
  },
  modal: {
    acept: 'ACCEPT',
    info: 'Information'
  },
  photosModal: {
    cancel: 'Cancel',
    takePhoto: 'Take a pic',
    chooseFromLibrary: 'Choose from library',
    permissionDenied: {
      title: 'Permission denied',
      text: 'You need to select a pic',
      reTryTitle: 'Retry',
      okTitle: 'Accept'
    }
  },
  projects: {
    title: 'Projects',
    noData: "You don't have any projects, please add one to continue.",
    noDataFilter: 'There are no projects associated to the filters.'
  },
  projectAdd: {
    title: 'New project',
    name: 'Project name',
    namePlaceholder: 'Project name',
    members: 'Members',
    addMember: 'Add members',
    addMemberPlaceholder: 'Search by name...',
    colorTitle: 'Colour',
    colorLabel: 'Assign a colour',
    newMember: 'Add new contact',
    noName: 'You need to add a name to the project'
  },
  projectEdit: {
    title: 'Edit project',
    admin: 'Admin',
    succesMessage: 'You have edited the project successfully'
  },
  companyEdit: {
    title: 'Edit company'
  },
  contacts: {
    title: 'Contacts',
    book: 'Book',
    noCompany: 'No company',
    noDepartment: '',
    sendEmail: 'Send email',
    copyEmail: 'Copy',
    noData: 'You have no contacts, please add one to continue.',
    noDataFilter: 'There are no contacts associated to the search.',
    commonProjects: 'Projects in common.',
    check: 'Check'
  },
  contactAdd: {
    title: 'New contact',
    noMember: 'This user is not a member of WGL, please fill his profile details and we will send him an invitation.',
    alreadyMember: 'This user is already a member of WGL.',
    isCurrentUser: 'The email you have introduced corresponds to your profile email. You can press on "New" to add a new contact.',
    alreadyContact: 'This user is already a contact. You can press on "New" to add a new contact.',
    createContact: 'Create a new contact'
  },
  contactEdit: {
    title: 'Edit contact'
  },
  calls: {
    title: 'Calls',
    titleLarge: 'Latest calls',
    noData: "You don't have recent calls"
  },
  profile: {
    title: 'My profile'
  },
  myProfile: {
    title: 'My profile',
    qrCOde: 'QR Code',
    editImage: 'Edit image'
  },
  invitations: {
    title: 'Invitations',
    noData: "You don't have invitations.",
    noDataFilter: 'There are no invitations associated to the search.',
    deleteProyectConfirm: {
      title: 'Are you sure?',
      message: 'You are the only admin, if you continue the projected will be deleted to all users. This action cannot be undone.'
    },
    contacts: {
      acept: 'Accept',
      reject: 'Reject',
      noModifyInfo: 'Its not possible to reject this contact as you have projects in common.'
    },
    projects: {
      acept: 'Accept',
      reject: 'Exit group'
    }
  },
  terms: {
    title: 'Terms & Privacy',
    terms: 'Terms & Conditions',
    privacy: 'Privacy policy'
  },
  myProfileEdit: {
    title: 'Edit profile'
  },
  languaje: {
    title: 'Language',
    es: 'Español',
    en: 'English'
  },
  sendgrid: {
    inviteApp: {
      subject: '{name} {surname} ({email}) has invited you to WGL',
      content: 'WGL is an app that helps you to keep all your contacts updated. Please visit www.workingrouplists.com'
    },
    inviteProject: {
      subject: '{name} {surname} ({email}) has invited you to participate in project {project}',
      content: 'You have been invited to a project, please log in and verify your profile.'
    },
    projectDeleted: {
      subject: '{name} {surname} ({email}) has deleted project {project}',
      content: 'The project has been deleted but all your project contacts have been saved as contacts.'
    }
  },

  success: {
    companyEdit: 'Campany has been edited correctly',
    companyAdd: 'Company has been created correctly',
    contactEdit: 'Contact has been edited correctly',
    contactAdd: 'Contact has been created correctly',
    contactAddError: 'An error has occurred and could not be added to the contact',
    contactInvite: 'Invitation sent',
    projectEdit: 'Project has been edited correctly',
    projectAdd: 'Project has been created correctly'
  },
  error: {
    message: 'An error has occurred'
  },

  search: 'Search',
  delete: 'Delete',
  invite: 'Invite',
  new: 'New',
  emailNoVerified: {
    title: 'Email not verified',
    message: 'You need to validate your account in order to use the app. Please check your inbox'
  },
  home: 'Home',
  help: 'Help',
  signOut: 'Sign out',
  landing: {
    available: 'Available'
  },
  mailto: {
    title: 'What is your email app?',
    message: 'Choose others in case it is not Outlook'
  },
  project: 'Project'
}
