import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AllColsHolderStyled } from './styled'

export default class AllColHolder extends Component {
  static propTypes = {
    col1: PropTypes.element,
    col2: PropTypes.element,
    col3: PropTypes.element,
    col4: PropTypes.element
  }

  static defaultProps = {
    col1: null,
    col2: null,
    col3: null,
    col4: null
  }

  render() {
    const { col1, col2, col3, col4 } = this.props

    return (
      <AllColsHolderStyled className={'mod mod-atoms mod-atoms-allColHolder regular-typo'}>
        <div className={'container'}>
          <div className={'inner-working-cols'}>
            <div className={'row'}>
              <div className={'col-xs-3 col-holder projects-holder'}>{col1}</div>
              <div className={'col-xs-3 col-holder business-holder'}>{col2}</div>
              <div className={'col-xs-3 col-holder contacts-holder'}>{col3}</div>
              <div className={'col-xs-3 col-holder single-view-holder'}>{col4}</div>
            </div>
          </div>
        </div>
      </AllColsHolderStyled>
    )
  }
}
