import styled from 'styled-components'

export const SectionComHeaderStyled = styled.header`
  background-color: ${props => props.theme.colors.mediumGrey};
  padding: 5px 0;
  border-bottom: 1px solid ${props => props.theme.colors.mediumGrey};

  .row {
    padding: 0 15px;
  }

  .inner-cell-com-div {
    .com-name-holder {
      h5 {
        font-size: 17px;
        margin-bottom: 0;
        em {
          opacity: 0.6;
          font-style: italic;
        }
      }
    }

    .actions-holder {
      text-align: right;
    }
  }
`
