//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { SplashScreen } from '../../../atoms'

export default class Splash extends PureComponent {
  static propTypes = {
    sesionLoaded: PropTypes.bool,
    uid: PropTypes.string,
    checkProfile: PropTypes.func,
    email: PropTypes.string,
    history: PropTypes.object
  }

  componentDidMount() {
    if (this.props.sesionLoaded) {
      this._checkSesion()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.sesionLoaded && this.props.sesionLoaded) {
      this._checkSesion()
    }
  }

  _checkSesion() {
    if (this.props.uid) {
      this.props.checkProfile(this.props.email)
    } else {
      this.props.history.replace('/login')
    }
  }

  render() {
    return <SplashScreen />
  }
}
