import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as colors from '../../../assets/colors'

import { InputColorStyled } from './styled'

export default class ColorInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    labelTop: PropTypes.string,
    labelBottom: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    parentClass: PropTypes.string,
    onChange: PropTypes.func,
    validation: PropTypes.func
  }

  static defaultProps = {
    labelTop: '',
    labelBottom: '',
    value: '',
    name: 'color',
    parentClass: '',
    onChange: () => {},
    validation: () => {}
  }

  state = {
    touched: false,
    valid: true,
    error: ''
  }

  options = [colors.blue, colors.yellow, colors.red, colors.green, colors.purple]

  validate(v) {
    if (this.props.validation) {
      const value = v != null ? v : this.props.value
      const validation = this.props.validation(value)
      this.setState({ valid: validation.isValid, error: validation.error, touched: true })
      return validation.isValid
    } else {
      return true
    }
  }

  _renderInputRadioColor = (i, name, color) => {
    const { onChange } = this.props
    return (
      <div className={'color-choose-holder'} key={'color' + i}>
        <input type={'radio'} name={'color'} value={name} id={'id-color-' + i} className={'color-picker'} onChange={() => onChange(color)} />
        <label htmlFor={'id-color-' + i} style={{ backgroundColor: color }}>
          {' XXX ' + color}
        </label>
      </div>
    )
  }

  render() {
    const { name, parentClass } = this.props

    return (
      <InputColorStyled className={this.props.className + ' mod mod-atoms mod-atoms-colorInput '}>
        <div className={'input-holder'}>
          <div className={'inner ' + parentClass}>
            {_.map(this.options, (color, i) => {
              const selected = color === this.props.value
              return this._renderInputRadioColor(i, name, color, selected)
            })}
          </div>
        </div>
      </InputColorStyled>
    )
  }
}
