import React from 'react'
import PropTypes from 'prop-types'
import QRIMageContactStyled from './styled'
import QRCode from 'qrcode.react'
import { blue2 } from '../../../assets/colors'
import { logoQR } from '../../../assets/images'
import { LANDING_URL } from '../../../config/api'

export default class TextInput extends React.Component {
  static propTypes = {
    profile: PropTypes.object
  }

  static defaultProps = {
    profile: {}
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { profile } = this.props
    return (
      <QRIMageContactStyled>
        <QRCode
          value={`${LANDING_URL}?profileid=${profile.id}`}
          renderAs="svg"
          size={128}
          fgColor={blue2}
          bgColor={'white'}
          imageSettings={{
            src: logoQR,
            height: 40,
            width: 40,
            imageExcavate: true,
            centerImage: true
          }}
        />
      </QRIMageContactStyled>
    )
  }
}
