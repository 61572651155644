//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Companies, Contact, Contacts, NavBar, Projects } from '../../../organisms'
import { AllColsHolder } from '../../../atoms'

export default class Main extends PureComponent {
  static propTypes = {
    searchText: PropTypes.string,
    setSearchText: PropTypes.func
  }

  render() {
    const { searchText, setSearchText } = this.props
    return (
      <div className={'motherwrapper home has-navbar'}>
        <NavBar showMyProfile showSearch searchText={searchText} onChangeSearchText={setSearchText} />
        <AllColsHolder col1={<Projects />} col2={<Companies />} col3={<Contacts />} col4={<Contact />} />
      </div>
    )
  }
}
