import styled from 'styled-components'

export const NavBarHolderStyler = styled.div`
  background: ${props => props.theme.colors.tint};
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  > .container {
    max-width: 100%;
  }
`
