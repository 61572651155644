import View from './view'
import { connect } from 'react-redux'
import { AuthOperations } from '../../../../redux/auth'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
  return {
    isFetching: state.auth.isFetching,
    profile: state.auth
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchMe: () => dispatch(AuthOperations.fetchMe()),
    onSubmit: data => dispatch(AuthOperations.updateProfile(data, props.history)),
    onUpdateImage: data => dispatch(AuthOperations.updateImage(data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(View))
