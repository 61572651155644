import * as types from './types'
import { AuthTypes } from '../auth'

const initialState = {
  isFetching: false,
  addProfile: null,
  contacts: [],
  contactsProfile: [],
  contactsEmailSend: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_ADD_CONTACT_PROFILE:
      return { ...state, addProfile: action.payload }
    case types.SET_CONTACTS:
      return { ...state, contacts: action.payload }
    case types.SET_CONTACTS_EMAIL_SEND:
      return { ...state, contactsEmailSend: action.payload }
    case types.SET_CONTACTS_PROFILE:
      return { ...state, contactsProfile: action.payload }
    case AuthTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}
