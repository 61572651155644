import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ContactsOperations } from '../../../../redux/contacts'
import { notify } from 'reapop'

const mapStateToProps = state => {
  return {
    contacts: state.contacts.contactsProfile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (data, contact) => dispatch(ContactsOperations.editContactProfile(data, contact)),
    notify: notif => dispatch(notify(notif))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
)
