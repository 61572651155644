//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonEmail, ButtonIcon, ColImageName, CardHolder } from '../../atoms'
import { ProjectsUtils } from '../../../redux/projects'
import { CompanyCellStyled } from './styled'
import _ from 'lodash'

export default class CompanyCard extends PureComponent {
  static propTypes = {
    company: PropTypes.object,
    profile: PropTypes.object,
    project: PropTypes.object,
    emailSelected: PropTypes.bool,
    selected: PropTypes.bool,
    onCompanyTapped: PropTypes.func,
    onEmailTapped: PropTypes.func,
    actions: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element, PropTypes.bool]),
    onEditTapped: PropTypes.func,
    forceActions: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.element, PropTypes.object])
  }

  static defaultProps = {
    company: null,
    onCompanyTapped: () => {},
    onEmailTapped: () => {},
    onEditTapped: () => {},
    emailSelected: false,
    selected: false,
    forceActions: false
  }

  _renderCompanyInfo = (img, initials, name) => {
    return <ColImageName img={img} initials={initials} name={name} />
  }

  _renderCompanyActions = (isProjectAdmin, emailSelected) => {
    const iconBackColor = emailSelected ? 'tint' : 'mediumGrey'
    return (
      <span>
        {isProjectAdmin ? <ButtonIcon label={'Edit'} color={'white'} icon={'edit'} onClick={this.props.onEditTapped} back={'mediumGrey'} /> : null}
        <ButtonEmail label={'email'} back={iconBackColor} onClick={this.props.onEmailTapped} selected={emailSelected} />
      </span>
    )
  }

  render() {
    const { company, emailSelected, profile, project, selected, forceActions } = this.props
    const name = _.get(company, 'name', '')
    const img = _.get(project, `company_images[${name}]`, null)
    const initials = name.substring(0, 2)
    const isProjectAdmin = ProjectsUtils.isProyectAdmin(profile, project)
    const info = this._renderCompanyInfo(img, initials, name)
    const actions = this._renderCompanyActions(isProjectAdmin, emailSelected)

    return (
      <CompanyCellStyled className={'mod mod-molecules mod-molecules-companyCard'}>
        <CardHolder onClick={this.props.onCompanyTapped} addClass={'test'} info={info} actions={actions} selected={selected} forceActions={forceActions} />
      </CompanyCellStyled>
    )
  }
}
