//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ProfileForm } from '../../../molecules'
import i18n from '../../../../assets/i18n'

export default class extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    contacts: PropTypes.array,
    isFetching: PropTypes.bool,
    history: PropTypes.object,
    notify: PropTypes.func
  }

  state = {
    profile: null,
    isFetching: false
  }

  _onClickClose = () => this.props.history.push('/')

  componentDidMount() {
    const contactEmail = _.get(this.props, 'match.params.email', null)
    this._findProfileByEmail(contactEmail)
  }

  _findProfileByEmail(email) {
    if (email) {
      const { contacts } = this.props
      const profile = _.find(contacts, { email })
      this.setState({ profile })
    }
  }

  _onSubmit = data => {
    const { profile } = this.state
    this.setState({ isFetching: true })
    return this.props.onSubmit(data, profile).then(() => {
      this.setState({ isFetching: false })
      this.props.history.replace('/projects')
      this.props.notify({
        message: i18n.success.contactEdit,
        status: 'success'
      })
    })
  }

  _renderProfileForm(onClickModal) {
    const { profile, isFetching } = this.state
    if (profile) {
      return (
        <ProfileForm profile={profile} onSubmit={this._onSubmit} onClickCloseModal={onClickModal} headTitle={i18n.contactEdit.title} isFetching={isFetching} />
      )
    }
  }

  render() {
    return (
      <div className={'motherwrapper edit-user'}>
        <div className={'container ts ts-quick'}>{this._renderProfileForm(this._onClickClose)}</div>
      </div>
    )
  }
}
