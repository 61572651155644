/* eslint-disable no-console */

import _ from 'lodash'
import { AuthActions, AuthUtils } from './'
import { ContactsOperations } from '../contacts'
import { CallsOperations } from '../calls'
import { ProjectsOperations } from '../projects'
import { FIREBASE_CONFIG } from '../../config/firebase'

export const checkSesion = () => (dispatch, getState, { firebase, api, i18n }) => {
  const { languaje } = getState().auth
  i18n.locale = languaje
  i18n.setLanguage(languaje)

  firebase.initializeApp(FIREBASE_CONFIG)
  setTimeout(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.email && user.uid && user.uid !== 'undefined') {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(token => {
            dispatch(AuthActions.setUid(user.uid))
            dispatch(AuthActions.setEmail(user.email))
            dispatch(AuthActions.setToken(token))
            api.configureToken(token)
            dispatch(AuthActions.setSesionLoaded(true))
            dispatch(initListeners())
            dispatch(checkUserEmailValidation())
          })
          .catch(err => {
            console.log('getIdToken error: ', err)
            setTimeout(() => dispatch(signOut()), 250)
            dispatch(AuthActions.setSesionLoaded(true))
          })
      } else {
        setTimeout(() => dispatch(signOut()), 250)
        dispatch(AuthActions.setSesionLoaded(true))
      }
    })
  }, 250)
}

export const signIn = ({ email, password }, history) => (dispatch, getState, { firebase, api }) => {
  if (!email || !password) return

  dispatch(AuthActions.setIsFetching(true))

  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => firebase.auth().currentUser.getIdToken(true))
    .then(token => {
      api.configureToken(token)
      dispatch(AuthActions.setToken(token))
      dispatch(checkProfile(email, history))
    })
    .catch(error => {
      console.log('signIn error', error)
      dispatch(showModalError(error))
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const signOut = () => (dispatch, getState, { firebase, api }) => {
  dispatch(AuthActions.logOut())
  ContactsOperations.removeContactsListener()
  CallsOperations.removeCallsListener()
  ProjectsOperations.removeProjectsListener()
  firebase.auth().currentUser && firebase.auth().signOut()
  if (firebase.auth().currentUser) {
    firebase.auth().signOut()
    dispatch(AuthActions.setToken(null))
    api.configureToken(null)
  }
}

export const sendPasswordResetEmail = ({ email }) => (dispatch, getState, { firebase, i18n, notify }) => {
  if (!email) return Promise.resolve()

  dispatch(AuthActions.setIsFetching(true))
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => dispatch(notify({ title: i18n.modal.info, message: i18n.auth.restorePassSuccess, status: 'success' })))
    .catch(error => dispatch(showModalError(error)))
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const sendPasswordReset = () => (dispatch, getState, { firebase, i18n, notify }) => {
  const { email } = getState().auth
  dispatch(AuthActions.setIsFetching(true))
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => dispatch(notify({ title: i18n.modal.info, message: i18n.auth.restorePassSuccess, status: 'success' })))
    .catch(error => dispatch(showModalError(error)))
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const createUser = ({ email, password, checked }, history) => (dispatch, getState, { firebase, api }) => {
  if (!email || !password || !checked) return

  dispatch(AuthActions.setIsFetching(true))

  firebase
    .auth()
    .createUserAndRetrieveDataWithEmailAndPassword(email, password)
    .then(() => firebase.auth().currentUser.getIdToken(true))
    .then(token => {
      api.configureToken(token)
      dispatch(AuthActions.setToken(token))
      dispatch(checkProfile(email, history))
    })
    .catch(error => {
      dispatch(AuthActions.setIsFetching(false))
      dispatch(showModalError(error))
    })
}

export const completeRegister = (data, history) => (dispatch, getState, { firebase, api }) => {
  const { isFetching } = getState().auth

  if (isFetching) return

  const currentUser = firebase.auth().currentUser
  if (_.isNil(currentUser) || _.isNil(currentUser.email)) {
    dispatch(signOut())
    return
  }

  console.log('completeRegister', currentUser.email, data)

  dispatch(AuthActions.setIsFetching(true))
  const profileData = { ...data, email: currentUser.email, completed: true }
  api
    .patchMeProfile(profileData)
    .then(response => {
      console.log('completeRegister', response)
      const currentUser = firebase.auth().currentUser
      if (currentUser && currentUser.uid && currentUser.uid !== 'undefined' && !_.isNil(currentUser.uid)) {
        dispatch(AuthActions.setUid(currentUser.uid))
        dispatch(AuthActions.setEmail(currentUser.email))
        dispatch(AuthActions.setProfile(data))
        dispatch(checkProfile(currentUser.email, history))
      } else {
        console.log('No firebase user')
        dispatch(signOut())
      }
    })
    .catch(error => {
      console.log('completeRegister error: ', error)
      dispatch(showModalError(error))
      dispatch(signOut())
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const updateProfile = (data, history) => (dispatch, getState, { api, notify, i18n }) => {
  dispatch(AuthActions.setProfile(data))
  api
    .patchMeProfile(data)
    .then(() => {
      history.goBack()
      dispatch(notify({ title: i18n.modal.info, message: i18n.auth.changeProfileSucces, status: 'success' }))
    })
    .catch(error => {
      console.log('updateProfile error: ', error)
      dispatch(showModalError(error))
    })
}

export const updatePassword = (data, history) => (dispatch, getState, { firebase, i18n, notify }) => {
  dispatch(AuthActions.setIsFetching(true))
  const { oldPassword, newPassword } = data
  const { currentUser } = firebase.auth()
  const { email } = currentUser
  const credentials = firebase.auth.EmailAuthProvider.credential(email, oldPassword)
  currentUser
    .reauthenticateAndRetrieveDataWithCredential(credentials)
    .then(() => firebase.auth().currentUser.updatePassword(newPassword))
    .then(() => {
      history.goBack()
      dispatch(notify({ title: i18n.modal.info, message: i18n.auth.changePasswordSucces, status: 'success' }))
    })
    .catch(error => {
      console.log('updatePassword error: ', error)
      dispatch(showModalError(error))
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const updateEmail = (data, history) => (dispatch, getState, { firebase, api, i18n, notify }) => {
  const { oldPassword, newEmail } = data
  const { currentUser } = firebase.auth()
  const { email } = currentUser
  const credentials = firebase.auth.EmailAuthProvider.credential(email, oldPassword)
  dispatch(AuthActions.setIsFetching(true))
  currentUser
    .reauthenticateAndRetrieveDataWithCredential(credentials)
    .then(() => currentUser.updateEmail(newEmail))
    .then(() => {
      dispatch(AuthActions.setEmail(newEmail))
      api.patchMeProfile({ email: newEmail }).catch(error => console.log(error))
      history.goBack()
      dispatch(notify({ title: i18n.modal.info, message: i18n.auth.changeEmailSucces, status: 'success' }))
    })
    .catch(error => {
      console.log('updateEmail error: ', error)
      dispatch(showModalError(error))
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const fetchMe = () => (dispatch, getState, { firebase, firebaseConfig }) => {
  const { id } = getState().auth
  dispatch(AuthActions.setIsFetching(true))
  const meProfileRef = `${firebaseConfig.refs.profile}/${id}`
  firebase
    .database()
    .ref(meProfileRef)
    .once('value')
    .then(snapshot => {
      const data = snapshot.val()
      data && dispatch(AuthActions.setProfile(data))
    })
    .catch(error => console.log(error))
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const checkProfile = (email, history, from) => (dispatch, getState, { firebase, firebaseConfig, notify, i18n }) => {
  //Recuperar ficha del usuario
  //si no tiene ficha - Ir a completar registro
  //si ya tiene ficha y NO ha tomado posesion - Ir a completar registro con datos prerrellenados
  //si ya tiene ficha y SI ha tomado posesion - Ir a la home
  dispatch(AuthActions.setIsFetching(true))

  firebase
    .database()
    .ref(`${firebaseConfig.refs.profile}/`)
    .orderByChild('email')
    .equalTo(email.toLowerCase().trim())
    .once('value')
    .then(snapshot => {
      const data = snapshot.val()
      if (!data) {
        //dispatch(signOut())
        dispatch(postMeProfile())
          .then(() => history.push('complete-register'))
          .catch(() => dispatch(signOut()))
      } else {
        const profile = _.first(Object.values(data))
        if (!profile || !profile.completed) {
          console.log('checkProfile no profile.completed', profile)
          dispatch(AuthActions.setId(profile.id))
          history.push({
            pathname: 'complete-register',
            state: { profile }
          })
        } else {
          const currentUser = firebase.auth().currentUser
          if (currentUser && currentUser.uid && currentUser.uid !== 'undefined') {
            dispatch(AuthActions.setId(profile.id))
            dispatch(AuthActions.setProfile(profile))
            dispatch(AuthActions.setUid(currentUser.uid))
            dispatch(AuthActions.setEmail(currentUser.email))
            dispatch(gotoHome(history, from))
          } else {
            dispatch(signOut())
          }
        }
      }
    })
    .catch(error => {
      console.log('checkProfile error: ', error)
      dispatch(signOut())
      dispatch(notify({ title: i18n.modal.info, message: error.message, status: 'error' }))
    })
    .finally(() => setTimeout(() => dispatch(AuthActions.setIsFetching(false)), 500))
}

export const showModalError = error => (dispatch, getState, { i18n, notify }) => {
  const code = AuthUtils.isFirebaseAuthError(error) ? error.code : 'unknown'
  const title = _.get(i18n, `auth.errors[${code}].title`)
  const message = _.get(i18n, `auth.errors[${code}].message`)
  dispatch(notify({ title, message, status: 'error' }))
}

export const postMeProfile = () => (dispatch, getState, { api }) => {
  return api
    .postMeProfile()
    .then(response => {
      console.log('postMeProfile', response)
      if (_.isNil(response.id) || response.id === 'undefined' || _.isNil(response.email) || response.email === 'undefined') {
        dispatch(signOut())
        return
      }

      dispatch(AuthActions.setEmail(response.email))
      dispatch(AuthActions.setId(response.id))
    })
    .catch(error => {
      console.log('postMeProfile', error)
      dispatch(signOut())
    })
}

export const initListeners = () => dispatch => {
  dispatch(ContactsOperations.setContactsListener())
  dispatch(CallsOperations.setCallsListener())
  dispatch(ProjectsOperations.setProjectsListener())
}

export const gotoHome = (history, from = false) => dispatch => {
  dispatch(updateApiLanguaje())
  dispatch(initListeners())
  dispatch(checkUserEmailValidation())

  if (from) {
    history.push(from)
  } else {
    history.push('/projects')
  }
}

export const sendEmailVerification = () => (dispatch, getState, { i18n, firebase, notify }) => {
  firebase
    .auth()
    .currentUser.reload()
    .then(() => {
      const user = firebase.auth().currentUser
      if (user && !_.get(user, 'emailVerified')) {
        user
          .sendEmailVerification()
          .then(() => {
            dispatch(
              notify({
                title: i18n.auth.errors.emailSended.title,
                message: i18n.auth.errors.emailSended.message,
                status: 'success'
              })
            )
          })
          .catch(error => {
            if (error && error.code === 'auth/too-many-requests') {
              dispatch(
                notify({
                  title: i18n.auth.errors.tooManyRequests.title,
                  message: i18n.auth.errors.tooManyRequests.message,
                  status: 'warning'
                })
              )
            } else {
              notify({
                title: i18n.auth.errors.unknown.title,
                message: i18n.auth.errors.unknown.message,
                status: 'error'
              })
            }
          })
      }
    })
}

export const checkUserEmailValidation = () => (dispatch, getState, { i18n, firebase, notify }) => {
  firebase
    .auth()
    .currentUser.reload()
    .then(() => {
      const user = firebase.auth().currentUser
      if (user && !_.get(user, 'emailVerified')) {
        dispatch(
          notify({
            title: i18n.auth.errors.emailNoVerified.title,
            message: i18n.auth.errors.emailNoVerified.message,
            status: 'error',
            buttons: [
              {
                name: i18n.auth.errors.emailNoVerified.resend,
                primary: true,
                onClick: () => dispatch(sendEmailVerification())
              }
            ]
          })
        )
      }
    })
}

export const checkLocale = () => (dispatch, getState, { i18n }) => {
  const { languaje } = getState().auth
  if (!languaje) {
    const locale = _.get(i18n.locale, '[0]', '') + _.get(i18n.locale, '[1]', '')
    i18n.locale = locale
    dispatch(AuthActions.setLanguaje(locale))
  } else {
    i18n.locale = languaje
    dispatch(AuthActions.setLanguaje(languaje))
  }
}

export const updateLocale = newLenguaje => (dispatch, getState, { i18n }) => {
  i18n.locale = newLenguaje
  i18n.setLanguage(newLenguaje)
  dispatch(AuthActions.setLanguaje(newLenguaje))
  dispatch(updateApiLanguaje())
}

export const updateApiLanguaje = () => (dispatch, getState, { firebase }) => {
  if (firebase.auth().currentUser) {
    // const { languaje } = getState().auth
    // api
    //   .patchMeProfile({ languaje })
  }
}

export const updateImage = ({ image }) => (dispatch, getState, { firebase, api }) => {
  const currentUser = firebase.auth().currentUser
  if (_.isNil(currentUser) || _.isNil(currentUser.email)) {
    dispatch(signOut())
    return
  }

  dispatch(AuthActions.setIsFetching(true))
  api
    .patchMeProfile({ image })
    .then(response => {
      console.log('updateImage', response)
    })
    .catch(error => {
      console.log('completeRegister error: ', error)
      dispatch(showModalError(error))
      dispatch(signOut())
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}
