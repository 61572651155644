//React
import React from 'react'
import google_badge from '../../../assets/images/google-play-badge.png'
import itunes_badge from '../../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'

const DownloadApp = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
    <div style={{ width: 30 }} />
    <img
      style={{ width: 100, height: 50 }}
      alt="Itunes"
      src={itunes_badge}
      onClick={() => window.open('https://itunes.apple.com/es/app/working-group-lists/id1450815137')}
    />
    <img
      style={{ width: 130, height: 50 }}
      alt="Play Store"
      src={google_badge}
      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.wgl.app')}
    />
  </div>
)

export default DownloadApp
