import View from '../edit/view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ProjectsOperations } from '../../../../redux/projects'
import { notify } from 'reapop'

const mapStateToProps = state => {
  return {
    contacts: state.contacts.contactsProfile,
    profile: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: data => dispatch(ProjectsOperations.addNewProject(data)),
    notify: notif => dispatch(notify(notif))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
)
