import styled from 'styled-components'

export const ContainerStyled = styled.div`
  text-align: center;
  h4.title {
    margin: 0 !important;
    padding: 0 !important;
    color: #0d5bdc;
    font-size: 16px;
    font-weight: bold;
  }
  h4.subtitle {
    margin: 0 !important;
    padding: 0 !important;
    color: grey;
    font-size: 13px;
  }
`
