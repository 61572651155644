//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../assets/i18n'
import { ContactCard, MailToCard, SectionColHeader, SectionDepHeader } from '../../molecules'
import { ButtonIcon, ButtonEmail } from '../../atoms'
import { ProjectsUtils } from '../../../redux/projects'
import { ContactsUtils } from '../../../redux/contacts'
import { sanitize } from '../../../utils/utils'

export default class Contacts extends PureComponent {
  static propTypes = {
    contacts: PropTypes.array,
    profile: PropTypes.object,
    sendEmailContacts: PropTypes.func,
    onPressCall: PropTypes.func,
    history: PropTypes.object,
    projects: PropTypes.array,
    projectsAccepted: PropTypes.array,
    searchText: PropTypes.string,
    setContactsEmailsSend: PropTypes.func,
    contactsEmailSend: PropTypes.array,
    removeContactsEmailsSend: PropTypes.func,
    addContactsEmailsSend: PropTypes.func,
    sendContactsEmailsSend: PropTypes.func,
    resetContactsEmailsSend: PropTypes.func
  }

  state = {
    project: null,
    contacts: [],
    emailTo: []
  }

  componentDidMount() {
    const company = _.get(this.props, 'match.params.company', null)
    const projectName = _.get(this.props, 'match.params.project', null)
    if (company) {
      this._loadContactsByCompany(company)
    } else if (projectName) {
      this._loadContactsByProject(projectName)
    }
  }

  componentDidUpdate(prevProps) {
    const company = _.get(this.props, 'match.params.company', null)
    const prevCompany = _.get(prevProps, 'match.params.company', null)
    const projectName = _.get(this.props, 'match.params.project', null)
    const prevProjectName = _.get(prevProps, 'match.params.project', null)
    if (projectName !== prevProjectName) {
      this._loadContactsByProject(projectName)
    } else if (company !== prevCompany) {
      this._loadContactsByCompany(company)
    }
  }

  _loadContactsByCompany = companyName => {
    const { projectsAccepted, contacts, profile } = this.props
    const projectName = _.get(this.props, 'match.params.project', null)
    if (companyName && projectName) {
      const project = _.find(projectsAccepted, ({ name }) => _.isEqual(name.trim().toUpperCase(), projectName.trim().toUpperCase()))
      const members = ProjectsUtils.getProjectMembers({ project, contacts, profile })
      const filteredContacts = ContactsUtils.findContactsByCompany(companyName, members)
      this.setState({ project, contacts: filteredContacts, emailTo: [] })
    } else {
      this.setState({ project: null, contacts: [], emailTo: [] })
    }
  }

  _loadContactsByProject = projectName => {
    const { projectsAccepted, contacts, profile } = this.props
    const project = _.find(projectsAccepted, ({ name }) => _.isEqual(name.trim().toUpperCase(), projectName.trim().toUpperCase()))
    const members = ProjectsUtils.getProjectMembers({ project, contacts, profile })
    this.setState({ project, contacts: members, emailTo: [] })
  }

  _onEmailTapped = (e, item) => {
    e.stopPropagation()
    this.props.setContactsEmailsSend([item])
  }

  _onEmailCancel = () => {
    this.props.resetContactsEmailsSend()
  }

  _onEmailAllTapped = () => {
    const { contacts } = this.state
    this.setState({ emailTo: [...contacts] })
  }

  _onEmailDepartmentTapped = (contacts, department, selected) => {
    if (selected) {
      this.props.removeContactsEmailsSend(contacts)
    } else {
      this.props.addContactsEmailsSend(contacts)
    }
  }

  _sendEmailTo = contacts => {
    this.props.sendContactsEmailsSend(contacts)
  }

  _onCallTapped = ({ e, item }) => {
    e.stopPropagation()
    this.props.onPressCall(item)
  }

  _onCreateContactTapped = () => {
    const url = '/add'
    this.props.history.push(url)
  }

  _onContactTapped = item => {
    if (item.id === _.get(this.props, 'profile.id')) {
      this.props.history.push('/my-profile/my-data')
    } else {
      const project = _.get(this.props, 'match.params.project', '')
      let company = _.get(this.props, 'match.params.company', '')
      if (!company) {
        company = encodeURI(item.company)
      }
      const encodedContactEmail = encodeURI(item.email)
      const url = '/projects/' + project + '/' + company + '/' + encodedContactEmail
      this.props.history.push(url)
    }
  }

  _getList = () => {
    const { searchText } = this.props
    const { contacts } = this.state
    let list = contacts

    if (!_.isEmpty(searchText)) {
      list = _.filter(list, ({ name, surname, department, email, position }) => {
        const filter = sanitize(searchText).toUpperCase()
        if (
          name &&
          sanitize(name)
            .toUpperCase()
            .includes(filter)
        )
          return true
        else if (
          surname &&
          sanitize(surname)
            .toUpperCase()
            .includes(filter)
        )
          return true
        else if (
          department &&
          sanitize(department)
            .toUpperCase()
            .includes(filter)
        )
          return true
        else if (
          email &&
          sanitize(email)
            .toUpperCase()
            .includes(filter)
        )
          return true
        else if (
          position &&
          sanitize(position)
            .toUpperCase()
            .includes(filter)
        )
          return true
        else return false
      })
    }
    list.sort((a, b) => a.name.toUpperCase() - b.name.toUpperCase() || a.surname.toUpperCase() - b.surname.toUpperCase())
    let groupedList = _.groupBy(list, 'department')

    _.forEach(groupedList, (v, k) => {
      groupedList[k] = _.sortBy(v, 'name')
    })
    return groupedList
  }

  _renderContactCard = (item, i) => {
    const { contactsEmailSend } = this.props
    const emailSelected = _.findIndex(contactsEmailSend, email => email === item.email) !== -1
    const selected = _.get(this.props, 'match.params.contact', '') === item.email
    const { profile } = this.props
    return (
      <ContactCard
        key={'contact' + i}
        contact={item}
        onContactTapped={() => this._onContactTapped(item, i)}
        onEmailTapped={e => this._onEmailTapped(e, item, i)}
        onCallTapped={e => this._onCallTapped({ e, item, i })}
        emailSelected={emailSelected}
        selected={selected}
        profile={profile}
      />
    )
  }

  _renderDepartmentHeader = (list, department) => {
    let selected = true
    const { contactsEmailSend } = this.props
    _.forEach(list, c1 => {
      if (!_.find(contactsEmailSend, e1 => c1.email === e1)) {
        selected = false
      }
    })

    return (
      <div key={department}>
        <SectionDepHeader department={department}>
          <ButtonIcon label={'Email'} onClick={() => this._onEmailDepartmentTapped(list, department, selected)} selected={selected} />
          <ButtonEmail label={'email'} onClick={() => this._onEmailDepartmentTapped(list, department, selected)} selected={selected} />
        </SectionDepHeader>
        <div>{_.map(list, this._renderContactCard.bind(this))}</div>
      </div>
    )
  }

  render() {
    return (
      <div className={'col-cells-holder col-projects'}>
        <SectionColHeader title={i18n.contacts.title}>
          <ButtonIcon label={'Nuevo'} onClick={this._onCreateContactTapped} color={'white'} icon={'add'} />
          <ButtonIcon label={'Email'} onClick={this._onEmailAllTapped} color={'white'} icon={'mail'} />
        </SectionColHeader>
        <MailToCard emailToList={[]} onCancel={this._onEmailCancel} onAccept={this._sendEmailTo} />
        <div className={'col-loop ts mailcard-hidden'}>{_.map(this._getList(), this._renderDepartmentHeader)}</div>
      </div>
    )
  }
}
