//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../assets/i18n'
import { CompanyCard, MailToCard, SectionColHeader } from '../../molecules'
import { ButtonIcon } from '../../atoms'
import { ProjectsUtils } from '../../../redux/projects'
import { sanitize } from '../../../utils/utils'

export default class Companies extends PureComponent {
  static propTypes = {
    projectsAccepted: PropTypes.array,
    contacts: PropTypes.array,
    profile: PropTypes.object,
    history: PropTypes.object,
    sendEmailProjects: PropTypes.func,
    sendEmailCompanys: PropTypes.func,
    searchText: PropTypes.string,
    contactsEmailSend: PropTypes.array,
    removeContactsEmailsSend: PropTypes.func,
    addContactsEmailsSend: PropTypes.func,
    sendContactsEmailsSend: PropTypes.func,
    resetContactsEmailsSend: PropTypes.func
  }

  state = {
    project: null,
    companies: [],
    emailTo: []
  }

  componentDidMount() {
    const project = _.get(this.props, 'match.params.project', null)
    this._loadCompaniesByProject(project)
  }

  componentDidUpdate(prevProps) {
    const project = _.get(this.props, 'match.params.project', null)
    const prevProject = _.get(prevProps, 'match.params.project', null)
    if (project !== prevProject) {
      this._loadCompaniesByProject(project)
    }
  }

  _loadCompaniesByProject = projectName => {
    const { projectsAccepted, contacts, profile } = this.props
    const project = _.find(projectsAccepted, { name: projectName })
    if (project) {
      const companies = ProjectsUtils.getProjectCompanys({ project, contacts, profile })
      this.setState({ project, companies, emailTo: [] })
    } else {
      this.setState({ project: null, companies: [], emailTo: [] })
    }
  }

  _onEditTapped = (e, item) => {
    e.stopPropagation()
    const project = _.get(this.props, 'match.params.project', '')
    const encodedCompanyName = encodeURI(item.name)
    const url = '/projects/' + project + '/' + encodedCompanyName + '/edit'
    this.props.history.push(url)
  }

  _onEmailTapped = (e, item, i, emailSelected) => {
    e.stopPropagation()

    const { project } = this.state
    const { contacts, profile } = this.props

    if (project) {
      const projectContacts = []
      _.forEach(project.members, (value, id) => {
        const isSameCompany = (item, contact) => _.get(item, 'name', '').toUpperCase() === _.get(contact, 'company', '').toUpperCase()
        const c1 = _.find(contacts, c => c.id === id)
        if (c1 && isSameCompany(item, c1)) {
          projectContacts.push(c1)
        } else if (profile.id === id && isSameCompany(item, profile)) {
          projectContacts.push(profile)
        }
      })

      if (emailSelected) {
        this.props.removeContactsEmailsSend(projectContacts)
      } else {
        this.props.addContactsEmailsSend(projectContacts)
      }
    }
  }

  _onEmailCancel = () => {
    this.props.resetContactsEmailsSend()
  }

  _onEmailAllTapped = () => {
    const { project } = this.state
    if (project) {
      this.props.sendEmailProjects([project])
    }
  }

  _sendEmailTo = () => {
    this.props.sendContactsEmailsSend()
  }

  _onCompanyTapped = item => {
    const project = _.get(this.props, 'match.params.project', '')
    const encodedCompanyName = encodeURI(item.name)
    const url = '/projects/' + project + '/' + encodedCompanyName
    this.props.history.push(url)
  }

  _renderCompanyCard = (item, i) => {
    let emailSelected = true
    const { project } = this.state
    const { contacts, contactsEmailSend, profile } = this.props

    if (project) {
      _.forEach(project.members, (value, id) => {
        const c1 = profile.id === id ? profile : _.find(contacts, c => c.id === id)
        if (!c1) return

        if (_.get(item, 'name', '').toUpperCase() !== _.get(c1, 'company', '').toUpperCase()) return

        const find = _.find(contactsEmailSend, e1 => c1.email === e1)
        if (!find) {
          emailSelected = false
        }
      })
    }

    const selected = _.get(this.props, 'match.params.company', '') === item.name
    return (
      <CompanyCard
        key={'company' + i}
        company={item}
        selected={selected}
        emailSelected={emailSelected}
        onCompanyTapped={() => this._onCompanyTapped(item, i)}
        onEmailTapped={e => this._onEmailTapped(e, item, i, emailSelected)}
        onEditTapped={e => this._onEditTapped(e, item, i)}
        project={this.state.project}
        profile={this.props.profile}
      />
    )
  }

  render() {
    const { searchText /*, contactsEmailSend*/ } = this.props
    const { companies } = this.state
    let list = companies
    if (searchText) {
      list = _.filter(list, ({ name }) =>
        sanitize(name)
          .toUpperCase()
          .includes(sanitize(searchText).toUpperCase())
      )
    }
    list = _.orderBy(list, ({ name }) => name.toUpperCase())

    const mailCardClass = /*contactsEmailSend.length > 0 ? 'mailcard-show' :*/ 'mailcard-hidden'

    return (
      <div className={'col-cells-holder col-projects'}>
        <SectionColHeader title={i18n.companies}>
          <ButtonIcon label={'Email'} onClick={this._onEmailAllTapped} color={'white'} icon={'mail'} />
        </SectionColHeader>
        <MailToCard emailToList={[] /*contactsEmailSend*/} onCancel={this._onEmailCancel} onAccept={this._sendEmailTo} />
        <div className={'col-loop ts ' + mailCardClass}>{_.map(list, this._renderCompanyCard)}</div>
      </div>
    )
  }
}
