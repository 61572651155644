//React
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { NavBar } from '../../../organisms'
import i18n from '../../../../assets/i18n'

export default class Conditions extends PureComponent {
  render() {
    return (
      <div className={'motherwrapper terms has-navbar'}>
        <NavBar showMyProfile />
        <div className={'container'}>
          <div className={'inner-working-cols'}>
            <div className={'row'}>
              <div className={'col-xs-12 col-holder regular-typo static-typo-legal'}>{i18n.getLanguage() === 'es' ? <TermsEs /> : <TermsEn />}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const TermsEs = () => (
  <div>
    <h1>TÉRMINOS Y CONDICIONES DE USO</h1>
    <p>
      El presente texto (los <b>“Términos y Condiciones de Uso”</b>) regulan la descarga, el acceso y el uso de la aplicación móvil Workin’GroupLists o WGL (en
      adelante, la <b>“Aplicación”</b>) que Connecting Tools SL CIF: B88417266 (en adelante, <b>“la Sociedad”</b>) pone, gratuitamente, a disposición de sus
      usuarios (en adelante, los <b>“Usuarios”</b>).
    </p>
    <p>
      La mera descarga de la Aplicación implica el conocimiento, comprensión y aceptación, necesariamente y sin reserva alguna, de las condiciones estipuladas
      en los Términos y Condiciones de Uso. Por lo tanto, se recomienda a los Usuarios que los lean cuidadosamente, así como cualquier mensajes, instrucción o
      advertencia que les sean comunicadas mientras utilizan la Aplicación, en tanto que deberán cumplir con todos ellos. Si los Usuarios están en desacuerdo
      con estas estipulaciones, no deberán utilizar la Aplicación.
    </p>
    <p>
      La Sociedad se reserva el derecho de modificar o actualizar los Términos y Condiciones de Uso en cualquier momento y sin previo aviso, por exigencias
      normativas, por motivos técnicos, por cambios en los servicios ofrecidos por la Sociedad o por decisiones estratégicas de la compañía o del grupo al que
      pertenece, mediante la modificación o actualización del texto accesible a los Usuarios en los presentes Términos y Condiciones de Uso, por lo que se
      recomienda a los Usuarios que periódicamente lo revisen. Si los Usuarios no están conformes con los cambios, deberán dejar de utilizar la Aplicación y
      darse de baja como Usuario. La utilización de la Aplicación tras la realización de los cambios supondrá la aceptación de los mismos por los Usuarios.
    </p>
    <h2>Servicios</h2>
    <p>La Sociedad , a través de la Aplicación, proporciona la posibilidad de:</p>
    <ul>
      <li>
        Crear un perfil personal virtual donde el Usuario podrá incluir sus datos identificativos, datos de contacto y otros datos relacionados como fotografía,
        correo electrónico o puesto de trabajo.
      </li>
      <li>Que los Usuarios compartan su perfil y sus datos de contacto con el resto de Usuarios de la Aplicación.</li>
      <li>Que cada Usuario pueda formar su propia agenda de contactos con los datos de los perfiles de otros Usuarios.</li>
      <li>Formar grupos virtuales de varios Usuarios que compartan los datos de sus perfiles entre ellos.</li>
      <li>Conectar a los Usuarios a través del sistema de chat de la Aplicación.</li>
    </ul>
    <p>Asimismo, la Sociedad podrá crear o añadir con el paso del tiempo otros servicios y funcionalidades que considere de interés para los Usuarios.</p>
    <h2>Obligaciones de los Usuarios</h2>
    <p>
      Los Usuarios se comprometen a hacer un uso adecuado y lícito de la Aplicación, de conformidad con la legislación aplicable y los presente Términos y
      Condiciones de Uso.
    </p>
    <p>Los Usuarios deberán de ser mayores de edad para acceder a esta Aplicación.</p>
    <p>Los Usuarios deberán de cumplimentar el formulario de registro necesario para hacer uso de los servicios de la Aplicación.</p>
    <p>Los Usuarios deberán abstenerse de:</p>
    <ul>
      <li>Hacer un uso no autorizado o fraudulento de la Aplicación;</li>
      <li>Acceder o intentar acceder a recursos restringidos de la Aplicación;</li>
      <li>
        Utilizar la Aplicación con fines ilícitos, ilegales, contrarios a lo establecido en los presentes Términos y Condiciones, lesivos de los derechos e
        intereses de terceros, o que de cualquier forma puedan dañar, inutilizar o sobrecargar o impedir la normal utilización o disfrute de la Aplicación;
      </li>
      <li>Provocar daños en la Aplicación o en los sistemas de terceros;</li>
      <li>
        Introducir o difundir virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar daños en los sistemas de la
        Sociedad o de terceros;
      </li>
      <li>Intentar acceder, utilizar y/o manipular los datos de la Sociedad, de otros Usuarios o de terceros;</li>
      <li>
        Reproducir, copiar, distribuir, transformar o modificar los contenidos, permitir el acceso a terceros a través de cualquier modalidad de comunicación
        pública, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello esté legalmente permitido;
      </li>
      <li>
        Obtener o intentar obtener los contenidos empleando para ello medios o procedimientos distintos de los que, según los casos, se hayan puesto a su
        disposición a este efecto;
      </li>
      <li>Intentar acceder y/o utilizar las cuentas de correo electrónico de otros Usuarios y/o modificar o manipular sus mensajes; y</li>
      <li>
        Los Usuarios no transmitirán a terceros no autorizados su nombre de Usuario y su contraseña, debiendo de comunicar inmediatamente a la Sociedad el
        acceso por parte de un Usuario no autorizados.
      </li>
    </ul>
    <h2>Limitación de responsabilidad</h2>
    <p>
      La Sociedad no garantiza la disponibilidad y continuidad del funcionamiento de la Aplicación. En consecuencia, la Sociedad no será en ningún caso
      responsable por cualesquiera daños y perjuicios que puedan derivarse de:
    </p>
    <ul>
      <li>La falta de disponibilidad o accesibilidad a la Aplicación;</li>
      <li>
        La interrupción en el funcionamiento de la Aplicación o fallos informáticos, averías telefónicas, desconexiones, retrasos o bloqueos causados por
        deficiencias o sobrecargas en las líneas telefónicas, centros de datos, en el sistema de Internet o en otros sistemas electrónicos, producidos en el
        curso de su funcionamiento;
      </li>
      <li>La veracidad, integridad o actualización de los datos facilitados por los Usuarios; y</li>
      <li>Otros daños que puedan ser causados por terceros mediante intromisiones no autorizadas ajenas al control de la Sociedad.</li>
    </ul>
    <p>
      La Sociedad no garantiza la ausencia de virus ni de otros elementos en la Aplicación introducidos por terceros ajenos a la Sociedad que puedan producir
      alteraciones en los sistemas físicos o lógicos del Usuario o en los documentos electrónicos y ficheros almacenados en su sistema. En consecuencia, la
      Sociedad no será en ningún caso responsable de cualesquiera daños y perjuicios de toda naturaleza que pudieran derivarse de la presencia de virus u otros
      elementos que puedan producir alteraciones en los sistemas físicos o lógicos, documentos electrónicos o ficheros del Usuario.
    </p>
    <p>
      La Sociedad no garantiza que terceros no autorizados no puedan conocer las condiciones, características y circunstancias en las cuales los Usuarios
      acceden a la Aplicación. En consecuencia, la Sociedad no será en ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso
      no autorizado.
    </p>
    <p>
      Con la aceptación de los presentes Términos y Condiciones de Uso, los Usuarios declaran que mantendrán indemne a la Sociedad frente a cualquier
      reclamación contra la misma, sus directivos, socios, empleados, abogados y agentes derivada del incumplimiento por parte de los Usuarios de cualquier
      disposición contenida en los presentes Términos y Condiciones de Uso o de cualquier ley o regulación, como consecuencia del incumplimiento los mismos o
      con motivo de una violación de los derechos de terceros por parte de los Usuarios.
    </p>
    <p>
      En todo caso, la Sociedad sólo responderá de aquellos daños que los Usuarios pudieran sufrir como consecuencia de una actuación dolosa, o manifiestamente
      negligente por parte de esta sociedad, en relación con el acceso a la Aplicación, la prestación de sus servicios, así como el uso de los contenidos,
      herramientas y funcionalidades.
    </p>
    <h2>Enlaces a terceros</h2>
    <p>
      La Sociedad no controla el contenido de otros sitios desde los que se pueda acceder a esta Aplicación o a los que éste permita acceder a través de
      diferentes enlaces (links). Por tanto, la Sociedad no se hace responsable ni de la información contenida en los mismos ni de cualesquiera efectos que
      pudieran derivarse de dicha información. La Sociedad no ofrece ninguna garantía, expresa o implícita, sobre la veracidad, propiedad, validez o legalidad
      de cualquiera de los sitios enlazados y su contenido.
    </p>
    <h2>Indemnización</h2>
    <p>
      En caso de que la Sociedad, las empresas de su grupo, sus directivos, socios, empleados, agentes o colaboradores, así como cualquier otra de las personas
      involucradas en la creación, producción y distribución de esta Aplicación sufriera cualquier tipo de daños, perjuicios, pérdidas o costes (incluidos los
      honorarios de abogados y procuradores que hubieran sido necesarios) como consecuencia de un incumplimiento por parte de los Usuarios de estos Términos y
      Condiciones, los Usuarios vendrán obligados a resarcir en su totalidad los daños causados.
    </p>
    <h2>Propiedad Intelectual e Industrial</h2>
    <p>
      Esta Aplicación pertenece a la Sociedad. Sus contenidos (incluyendo, con carácter meramente enunciativo, su código fuente, marcas comerciales, imágenes,
      iconos, diseños, y presentación general) están protegidos, de acuerdo con la normativa española, comunitaria e internacional, por derechos de autor,
      marcas registradas y otros derechos de propiedad intelectual e industrial titularidad de la Sociedad, de sociedades de su grupo o de terceros. El acceso a
      esta Aplicación no confiere en ningún caso, ni podrá ser interpretado como autorización o licencia de ningún tipo sobre tales contenidos y derechos.
    </p>
    <p>
      Asimismo, ciertos nombres comerciales, marcas, logotipos, eslóganes y otros materiales expuestos en esta Aplicación están debidamente registrados a nombre
      de la Sociedad o de otras entidades. Los Usuarios no están autorizados para utilizar ninguno de dichos elementos, cuya propiedad permanecerá, en todo
      caso, bajo titularidad de la Sociedad o de dichas entidades.
    </p>
    <p>
      Queda expresamente prohibido cualquier acto de reproducción, distribución, transformación o comunicación pública, así como cualquier tipo de cesión, de
      todo o parte del contenido de la Aplicación y/o, en general, cualquier acto de explotación de la totalidad o parte de los contenidos (imágenes, textos,
      diseño, índices, formas, etc.) así como de las posibles bases de datos que la aplicación pudiera contener y de cualquier objeto, que sea protegible según
      la legislación vigente, especialmente conforme a la normativa de propiedad intelectual e industrial.
    </p>
    <p>
      En particular, los Usuarios no podrán, en ningún caso, explotar o servirse comercialmente, de forma directa o indirecta, total o parcial, de ninguno de
      los contenidos que conformen la Aplicación, así como modificar, copiar, distribuir, transmitir, exponer, publicitar, vender y/o conceder licencias de
      cualquier contenido de la Aplicación o crear trabajos que deriven de los mismos, sin la autorización previa y por escrito de la Sociedad y, en su caso,
      también de su titular. Cualquier autorización o licencia sobre la Aplicación y su contenido, no supone, bajo ningún concepto, la renuncia, transmisión o
      cesión, total ni parcial, de ninguno de los derechos anteriormente mencionados.
    </p>
    <p>
      La Sociedad se reserva, expresamente, cuantas acciones civiles y penales, en virtud de la legislación española y extranjera pudieran corresponderle como
      consecuencia de la vulneración de la pacífica posesión y/o utilización no autorizada de sus derechos de propiedad industrial e intelectual.
    </p>
    <h2>Protección de datos</h2>
    <p>
      El uso de esta Aplicación puede requerir que los Usuarios suministren datos de carácter personal. La Sociedad trata este tipo de información cumpliendo
      con la legislación aplicable, conforme a su <Link to="/privacy-policy">Política de Privacidad</Link> que forma parte de los presentes Términos y
      Condiciones de Uso.
    </p>
    <h2>Notificaciones</h2>
    <p>
      La Sociedad podrá realizar las notificaciones oportunas a través de la dirección de correo electrónico facilitada por los Usuarios en los formularios de
      registro o a través de cualquier otro medio facilitado en los datos de contacto por parte del Usuario.
    </p>
    <h2>Salvaguardia e interpretación</h2>
    <p>
      Estos Términos y Condiciones constituyen un acuerdo entre cada uno de los Usuarios y la Sociedad. Si la autoridad competente declara alguna disposición
      como ilegal, inválida o no ejecutable, la misma deberá ser interpretada de la manera más próxima a la intención original de tal disposición. Tal
      declaración respecto de una o varias cláusulas no perjudicará la validez de las restantes. El hecho de que la Sociedad no exija el cumplimiento estricto
      de alguno de las estipulaciones de estos Términos y Condiciones no constituye ni podrá interpretarse en ningún caso como una renuncia por su parte a
      exigir su estricto cumplimiento en el futuro.
    </p>
    <h2>Ley aplicable y jurisdicción</h2>
    <p>
      Salvo en los casos en que la normativa vigente de aplicación obligue a someterse a un fuero o legislación distinta, la Aplicación, sus contenidos y
      servicios, las relaciones entre los Usuarios y la Sociedad se rigen por la ley española, así como estos Términos y Condiciones de Uso, se regirán y
      deberán ser interpretados conforme a la ley española; y cualquier cuestión o controversia derivada o relativa a los mismos se someterá a los juzgados y
      tribunales de Madrid capital (España), a cuya jurisdicción, con renuncia expresa a cualquier fuero que pudiera corresponderles, acuerdan someterse las
      Partes.
    </p>
  </div>
)

const TermsEn = () => (
  <div>
    <h1>TERMS AND CONDITIONS OF USE</h1>
    <p>
      The present text (the <b>“Terms and Conditions of Use”</b>) regulates the downloading, access and use of the mobile application Workin’GroupLists or WGL
      (hereinafter, the <b>“Application”</b>) which Connecting Tools SL CIF: B88417266 (hereinafter, <b>“the Company”</b>) places free of charge at the disposal
      of its users (hereinafter, the <b>“Users”</b>).
    </p>
    <p>
      The mere downloading of the Application entails the awareness, understanding and acceptance, of necessity and with no reservation whatsoever, of the
      conditions set forth in the Terms and Conditions of Use. Consequently, it is recommended that the Users read them carefully, along with any messages,
      instructions or warnings which may be sent to them while using the Application, insofar as they will have to comply with all of them. Any User who
      disagrees with these stipulations must not use the Application.
    </p>
    <p>
      The Company reserves the right to modify or update the Terms and Conditions of Use at any moment and without prior notice, due to legislative
      requirements, technical reasons, changes in the services offered by the Company or strategic decisions of the Company or the group to which it belongs, by
      means of the modification or updating of the text accessible to the Users in the present Terms and Conditions of Use, and therefore it is recommended that
      the Users review them periodically. Any User who does not accept the changes must stop using the Application and deregister as a User. Use of the
      Application following the execution of the changes will signify their acceptance by the Users.
    </p>
    <h2>Services</h2>
    <p>Via the Application, the Company provides the following possibilities:</p>
    <ul>
      <li>
        For creating a virtual personal profile where the User can include their identifying data, contact data and other related items such a photograph,
        e-mail address or workplace.{' '}
      </li>
      <li>For Users to share their profiles and contact data with the other Users of the Application.</li>
      <li>For each User to create their own list of contacts with the data of profiles of other Users.</li>
      <li>For forming virtual groups of several Users who share the data of their profiles among themselves.</li>
      <li>For connecting Users via the Application’s chat system.</li>
    </ul>
    <p>In addition, over time the Company may create or add other services and functionalities which it believes may be of interest for the Users.</p>
    <h2>Users’ obligations</h2>
    <p>
      Users undertake to make appropriate and lawful use of the Application, in accordance with the applicable legislation and the present Terms and Conditions
      of Use.
    </p>
    <p>Users must be of legal age to access this Application.</p>
    <p>Users must complete the obligatory registration form to make use of the Application’s services.</p>
    <p>Users must abstain from: </p>
    <ul>
      <li>Making unauthorised or fraudulent use of the Application;</li>
      <li>Accessing or attempting to access restricted resources of the Application;</li>
      <li>
        Using the Application for purposes which are illicit, illegal, contrary to the provisions of these Terms and Conditions or harmful to the rights and
        interests of third parties, or which in any way may harm, overload or prevent the normal use or enjoyment of the Application;
      </li>
      <li>Causing damage to the Application or the third-party systems;</li>
      <li>
        Introducing or disseminating computer viruses or any other physical or logical systems which may be capable of causing damage to the systems of the
        Company or third parties;
      </li>
      <li>Attempting to access, use and/or manipulate the data of the Company, other Users or third parties;</li>
      <li>
        Reproducing, copying, distributing, transforming or modifying the contents, permitting access to third parties via any modality of public communication,
        unless they are so authorised by the holder of the corresponding rights or such action is legally permitted;
      </li>
      <li>
        Obtaining or attempting to obtain the contents by using means or procedures other than those which have been placed at their disposal for this purpose;
      </li>
      <li>Attempting to access and/or use the e-mail accounts of other Users and/or modifying or manipulating their messages; and</li>
      <li>
        Transmitting to unauthorised third parties their username and password: any access by an unauthorised User must be reported to the Company immediately.
      </li>
    </ul>
    <h2>Limitation of liability</h2>
    <p>
      The Company does not guarantee the availability and continuity of the functioning of the Application. Consequently, under no circumstances will the
      Company be liable for any damages which may arise out of:
    </p>
    <ul>
      <li>Lack of availability or accessibility of the Application;</li>
      <li>
        Interruption in the functioning of the Application, or IT failures, telephone breakdowns, disconnections, delays or blockages caused by deficiencies or
        overloads in the telephone lines, data centres, the Internet system or other electronic systems occurring in the course of its functioning;
      </li>
      <li>The veracity, integrity or current validity of the data provided by the Users; and</li>
      <li>Any other damages which may be caused by third parties by means of unauthorised intromissions beyond the Company’s control.</li>
    </ul>
    <p>
      The Company does not guarantee the absence of viruses or other elements in the Application introduced by third parties foreign to the Company which may
      cause alterations in the User’s physical or logical systems or the electronic documents and files stored in their system. Consequently, under no
      circumstances will the Company be liable for damages of any nature which may arise out of the presence of any viruses or other elements which may cause
      alterations in the User’s physical or logical systems, electronic documents or files.{' '}
    </p>
    <p>
      The Company does not guarantee that unauthorised third parties will be unable to discover the conditions, characteristics and circumstances in which the
      Users access the Application. Consequently, under no circumstances will the Company be liable for any damages which may arise out of such unauthorised
      access.
    </p>
    <p>
      By accepting the present Terms and Conditions of Use, the Users declare that they will hold the Company harmless towards any claim against it, its
      executives, shareholders, employees, lawyers and agents arising out of non-compliance by the Users with any provisions contained in these Terms and
      Conditions of Use or with any law or regulation as a consequence of non-compliance therewith or arising out of a breach of the rights of third parties by
      the Users.
    </p>
    <p>
      In any case, the Company will only answer for any damages which the Users may suffer as a consequence of a malevolent or manifestly negligent action
      committed by this Company in relation with access to the Application, the provision of its services or the use of its contents, tools and functionalities.
    </p>
    <h2>Third-party links</h2>
    <p>
      The Company does not control the contents of other websites from which this Application can be accessed or to which it permits access via different links.
      Consequently, the Company accepts no liability for the information contained in them or for any effects which may arise out of such information. The
      Company offers no guarantee, express or implicit, on the veracity, propriety, validity or legality of any of the linked websites and their contents.
    </p>
    <h2>Compensation</h2>
    <p>
      In the event that the Company, the companies of its group, its executives, shareholders, employees, agents or collaborators, or any other person involved
      in the creation, production and distribution of this Application suffers any type of damages, harm, losses or costs (including the fees of any lawyers and
      barristers whose services may have been necessary) as a consequence of non-compliance by the Users of these Terms and Conditions, the Users will be
      obliged to compensate in full the damages caused.
    </p>
    <h2>Intellectual and industrial property</h2>
    <p>
      This Application belongs to the Company. Its contents (including, to purely illustrative purposes, its source code, trademarks, images, icons, designs and
      general presentation) are protected, pursuant to the Spanish, European Community and international legislation, by copyrights, registered trademarks and
      other intellectual and industrial property rights held by the Company, the companies of its group or third parties. Under no circumstances does this
      Application confer any authorisation or licence of any type on said contents and rights nor may it be interpreted to do so.
    </p>
    <p>
      Similarly, certain commercial names, trademarks, logotypes, slogans and other materials displayed in this Application are duly registered in the name of
      the Company or other entities. The Users are not authorised to use any such elements, the ownership of which will remain in all cases in the power of the
      Company or such other entities.
    </p>
    <p>
      It is expressly prohibited to perform any act of reproduction, distribution, transformation or public communication and any type of assignment of all or
      part of the contents of the Application and/or, in general, any act of exploitation of all or part of the contents (images, texts, design, indices, forms,
      etc.) and of any databases which the application may contain and of any object which may be entitled to protection under the current legislation,
      especially in accordance with the regulations on intellectual and industrial property.
    </p>
    <p>
      In particular, the Users may not, in any case, exploit or make commercial use, directly or indirectly, in whole or in part, of any of the contents of the
      Application, or modify, copy, distribute, transmit, exhibit, publicise, sell and/or grant licences on any contents of the Application or create works
      deriving from them without the prior written authorisation of the Company and, as the case may be, also of their proprietor. Any authorisation or licence
      on the Application and its contents does not signify in any respect the waiver, transfer or assignment, in whole or in part, of any of the aforementioned
      rights.
    </p>
    <p>
      The Company expressly reserves the right to bring any and all civil and penal actions which may correspond to it under Spanish and foreign legislation as
      a consequence of any infringement of its peaceful possession and/or unauthorised use of its industrial and intellectual property rights.
    </p>
    <h2>Data protection</h2>
    <p>
      The use of this Application may require the Users to supply data of a personal nature. The Company processes this type of information in compliance with
      the applicable legislation, in accordance with its <Link to="/privacy-policy">Privacy Policy</Link> which forms part of these Terms and Conditions of Use.
    </p>
    <h2>Notifications</h2>
    <p>
      The Company may make the pertinent notifications via the e-mail address provided by the Users in the registration forms or via any other means indicated
      by the Users in their contact data.
    </p>
    <h2>Safeguard and interpretation</h2>
    <p>
      These Terms and Conditions constitute an agreement between each one of the Users and the Company. Should the competent authority declare any provision to
      be illegal, invalid or unenforceable, such provision must be interpreted in the manner closest to its original intention. Such declaration in regard to
      one or more clauses shall not affect the validity of the rest. The fact that the Company may not demand strict compliance with one or more of the
      stipulations contained in these Terms and Conditions does not constitute and cannot be interpreted in any case as a waiver on its part of demanding strict
      compliance therewith in the future.
    </p>
    <h2>Applicable law and jurisdiction</h2>
    <p>
      Except in any cases in which the currently applicable legislation obliges submission to a different legislation, the Application, its contents and
      services and the relationships between the Users and the Company are governed by Spanish Law, and these Terms and Conditions of Use shall be governed and
      must be interpreted in accordance with Spanish Law; and any question or controversy arising out of or relating to them shall be submitted to the courts
      and tribunals of the city of Madrid (Spain), to whose jurisdiction the Parties agree to submit, expressly waiving any other jurisdiction which may
      correspond to them.
    </p>
  </div>
)
