import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SectionComHeaderStyled } from './styled'

export default class SectionComHeader extends Component {
  static propTypes = {
    company: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
  }

  static defaultProps = {
    company: ''
  }

  render() {
    const com = this.props.company
    const comname = com && com !== '' ? this.props.company : <em>{''}</em>
    return (
      <SectionComHeaderStyled className={'mod mod-atoms mod-molecules-sectionCompHeader regular-typo'}>
        <div className={'container'}>
          <div className={'inner-cell-com-div'}>
            <div className={'row middle-xs'}>
              <div className={'col-xs-8 com-name-holder'}>
                <h5>{comname}</h5>
              </div>
              <div className={'col-xs-4 actions-holder'}>{this.props.children}</div>
            </div>
          </div>
        </div>
      </SectionComHeaderStyled>
    )
  }
}
