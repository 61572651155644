//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../assets/i18n'
import Utils from '../../../../utils'
import { Button, TextInput, ColorInput, ModalFormHeader } from '../../../atoms'
import { MemberSelector } from '../../../molecules'
import { ProjectsUtils } from '../../../../redux/projects'
import _ from 'lodash'
import Spinner from 'react-spinkit'
import ProjectQR from '../projectQR'

class ProjectForm extends PureComponent {
  static propTypes = {
    project: PropTypes.object,
    onSubmit: PropTypes.func,
    contacts: PropTypes.array,
    profile: PropTypes.object,
    isFetching: PropTypes.bool,
    history: PropTypes.object,
    onClickClose: PropTypes.func,
    headTitle: PropTypes.string,
    onAddContact: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.formInputs = {}

    const { project, contacts, profile } = this.props

    const projectMembers = ProjectsUtils.getProjectMembers({ project, contacts, profile })
    const members = _.filter(projectMembers, ({ id }) => profile.id !== id)
    this.state = {
      name: _.get(this.props, 'project.name', ''),
      color: _.get(this.props, 'project.color', ''),
      admins: _.get(this.props, 'project.admins', {}),
      members,
      isFetching: false
    }
  }

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      const data = {
        color: _.get(this.state, 'color', null),
        name: _.get(this.state, 'name', ''),
        members: _.get(this.state, 'members', []),
        admins: _.get(this.state, 'admins', {})
      }
      this.setState({ isFetching: true })
      this.props
        .onSubmit(data, this.props.project)
        .then(() => this.setState({ isFetching: false }))
        .catch(() => this.setState({ isFetching: false }))
    }
  }

  _handleChangeName = name => this.setState({ name })

  _handleChangeColor = color => this.setState({ color })

  _handleChangeMembers = (members, admins) => this.setState({ members, admins })

  render() {
    const { onClickClose, headTitle, contacts, onAddContact, project } = this.props
    const { name, color, members, admins, isFetching } = this.state
    return (
      <form className={'row regular-form big-form-holder-nofloat middle'} onSubmit={this._onSubmit}>
        <ModalFormHeader title={headTitle} onClickClose={onClickClose} />
        <div className={'col col-xs-12 col-sm-6'}>
          <TextInput
            placeholder={' '}
            innerRef={i => (this.formInputs.name = i)}
            id={'name'}
            label={i18n.firstName}
            value={name}
            onChange={this._handleChangeName}
            validation={v => Utils.mandatoryValidate(v)}
          />
          <TextInput label={i18n.projectAdd.colorTitle} value={i18n.projectAdd.colorLabel} disabled={'disabled'} />
          <ColorInput value={color} onChange={this._handleChangeColor} validation={v => Utils.mandatoryValidate(v)} parentClass={'no-padd'} />
          <ProjectQR project={project} />
        </div>
        <div className={'col col-xs-12 col-sm-6'}>
          <MemberSelector contactsOptions={contacts} members={members} admins={admins} onChange={this._handleChangeMembers} parentClass={'no-bottom-padd'} />
          <Button label={i18n.contactAdd.createContact} onClick={onAddContact} className={'black-link-alike has-apicon apicon-rgarrow'} />
        </div>
        <div className={'col col-xs-12 center'}>
          {isFetching ? (
            <Spinner color={'#0D5BDC'} name={'circle'} fadeIn={'none'} style={{ margin: '0 auto' }} />
          ) : (
            <Button label={i18n.save} className={'clear-blue inline'} parentClass={'no-bottom-padd'} />
          )}

          <Button label={i18n.cancel} className={'link-alike inline'} onClick={onClickClose} />
        </div>
      </form>
    )
  }
}

export default class extends PureComponent {
  static propTypes = {
    project: PropTypes.object,
    onSubmit: PropTypes.func,
    contacts: PropTypes.array,
    profile: PropTypes.object,
    isFetching: PropTypes.bool,
    history: PropTypes.object,
    notify: PropTypes.func,
    projectsAccepted: PropTypes.array
  }

  state = {
    project: null
  }

  isEdit = _.get(this.props, 'match.params.project', false)

  componentDidMount() {
    this._findProjectByName(this.isEdit)
  }

  _findProjectByName(name) {
    if (name) {
      const { projectsAccepted } = this.props
      const project = _.find(projectsAccepted, { name })
      this.setState({ project })
    }
  }

  _onSubmit = (data, project) => {
    if (!data.name) {
      this.props.notify({
        message: i18n.projectAdd.noName,
        status: 'warning'
      })
      return Promise.resolve()
    }

    return this.props.onSubmit(data, project).then(() => {
      const encodedProjectName = encodeURI(data.name)
      const url = '/projects/' + encodedProjectName
      this.props.history.replace(url)
      this.props.notify({
        message: this.isEdit ? i18n.success.projectEdit : i18n.success.projectAdd,
        status: 'success'
      })
    })
  }

  _onAddContact = () => {
    const url = '/projects/add/contact'
    this.props.history.push(url, { modal: true })
  }

  _onClickClose = e => {
    e.preventDefault()
    this.props.history.push('/')
  }

  _renderProjectForm() {
    const { project } = this.state
    const { contacts, profile } = this.props

    if (!this.isEdit) {
      return (
        <ProjectForm
          contacts={contacts}
          profile={profile}
          onSubmit={this._onSubmit}
          onAddContact={this._onAddContact}
          onClickClose={this._onClickClose}
          headTitle={i18n.projectAdd.title}
        />
      )
    } else if (project) {
      return (
        <ProjectForm
          project={project}
          contacts={contacts}
          profile={profile}
          onSubmit={this._onSubmit}
          onAddContact={this._onAddContact}
          onClickClose={this._onClickClose}
          headTitle={i18n.projectEdit.title}
        />
      )
    }
  }

  render() {
    return (
      <div className={'motherwrapper add-project'}>
        <div className={'container ts ts-quick'}>{this._renderProjectForm(this._onClickClose)}</div>
      </div>
    )
  }
}
