//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'
import { TextInput, Button, ModalHolder } from '../../../atoms'
import Utils from '../../../../utils'

export default class UpdatePass extends PureComponent {
  static propTypes = {
    sendPasswordReset: PropTypes.func,
    onSubmit: PropTypes.func,
    profile: PropTypes.object,
    isFetching: PropTypes.bool,
    history: PropTypes.object
  }

  _onClickClose = () => this.props.history.goBack()
  _onClickForgot = () => this.props.sendPasswordReset()

  render() {
    const { profile, isFetching, onSubmit } = this.props
    return (
      <ModalHolder className={'has-navbar has-side'} addClose={true} CloseOnClick={this._onClickClose}>
        <Form profile={profile} isFetching={isFetching} onSubmit={onSubmit} onClickForgot={this._onClickForgot} />
      </ModalHolder>
    )
  }
}

class Form extends PureComponent {
  static propTypes = {
    onClickForgot: PropTypes.func,
    onSubmit: PropTypes.func,
    profile: PropTypes.object,
    isFetching: PropTypes.bool
  }

  state = {
    oldPassword: '',
    newPassword: ''
  }

  formInputs = {}

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      this.props.onSubmit(this.state)
    }
  }

  render() {
    const { isFetching, onClickForgot } = this.props
    const { oldPassword, newPassword } = this.state
    const submitEnabled = _.size(oldPassword) > 7 && _.size(newPassword) > 7

    return (
      <form onSubmit={this._onSubmit} className={'row regular-form slim-form-holder-nofloat'}>
        <div className={'col col-xs-12'}>
          <h2>{i18n.auth.changePassword}</h2>
        </div>
        <div className={'col col-xs-12'}>
          <TextInput
            innerRef={i => (this.formInputs.oldPassword = i)}
            id={'oldPassword'}
            value={oldPassword}
            label={i18n.auth.currentPass}
            placeholder={' '}
            onChange={oldPassword => this.setState({ oldPassword })}
            type={'password'}
            validation={v => Utils.mandatoryValidate(v)}
          />
          <TextInput
            innerRef={i => (this.formInputs.newPassword = i)}
            id={'newPassword'}
            value={newPassword}
            label={i18n.auth.newPass}
            placeholder={' '}
            onChange={newPassword => this.setState({ newPassword })}
            type={'password'}
            validation={v => Utils.mandatoryValidate(v)}
          />
        </div>
        <div className={'col col-xs-12 center'}>
          <Button
            label={i18n.auth.changePassword}
            disabled={!submitEnabled}
            isFetching={isFetching}
            className={'clear-blue compact inline'}
            parentClass={'no-bottom-padd'}
          />
          <Button label={i18n.auth.forgotPassword} onClick={onClickForgot} className={'link-alike compact inline'} />
        </div>
      </form>
    )
  }
}
