//React
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SelectInput, ButtonIcon } from '../../atoms/'
import { ContactCard } from '../../molecules/'
import i18n from '../../../assets/i18n'
import { MemberSelectorStyled } from './styled'
import _ from 'lodash'
import Switch from 'react-switch'

const CustomOption = ({ data, innerProps, isDisabled, profile }) => {
  if (isDisabled) return null
  return (
    <div {...innerProps}>
      <ContactCard contact={data} forceActions={'noaction'} profile={profile} />
    </div>
  )
}

CustomOption.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  parentClass: PropTypes.string,
  profile: PropTypes.object
}

export default class MemberSelector extends Component {
  static propTypes = {
    contactsOptions: PropTypes.array,
    members: PropTypes.array,
    admins: PropTypes.object,
    onChange: PropTypes.func,
    parentClass: PropTypes.string,
    profile: PropTypes.object,
    value: PropTypes.string
  }

  static defaultProps = {
    contactsOptions: [],
    members: [],
    admins: {},
    onChange: () => {},
    parentClass: ''
  }

  _onAddMember = member => {
    const members = [...this.props.members, member]
    this.props.onChange(members, this.props.admins)
  }

  _onRemoveMember = (member, isAdmin) => {
    const members = _.filter(this.props.members, v => v.id !== member.id)
    const admins = { ...this.props.admins, [member.id]: !isAdmin }
    this.props.onChange(members, admins)
  }

  _onSelectAdmins = (member, isAdmin) => {
    const admins = { ...this.props.admins, [member.id]: !isAdmin }
    this.props.onChange(this.props.members, admins)
  }

  _renderMemberCard = (member, i) => {
    const { profile } = this.props
    const isAdmin = _.get(this.props.admins, member.id, false)
    const rmvbtn = (
      <div>
        <div className={'added-members-admin'} onClick={() => this._onSelectAdmins(member, isAdmin)}>
          <span>{i18n.projectEdit.admin}</span>
          <Switch onChange={checked => this._onSelectAdmins(member, checked)} checked={isAdmin} />
        </div>
        <ButtonIcon label={'remove'} icon={'close'} color={'blue'} onClick={() => this._onRemoveMember(member, isAdmin)} />
      </div>
    )
    return (
      <div key={'member' + i} className={'added-members-loop'}>
        <ContactCard contact={member} forceActions={rmvbtn} profile={profile} />
      </div>
    )
  }

  render() {
    return (
      <MemberSelectorStyled className={'mod mod-molecules mod-molecules-memberSelector'}>
        <SelectInput
          label={i18n.projectAdd.addMember}
          placeholder={i18n.projectAdd.addMemberPlaceholder}
          options={this.props.contactsOptions}
          value={this.props.value}
          onChange={this._onAddMember}
          components={{ Option: CustomOption }}
          parentClass={this.props.parentClass}
        />
        {_.map(this.props.members, this._renderMemberCard)}
      </MemberSelectorStyled>
    )
  }
}
