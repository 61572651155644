import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataItemStyled } from './styled'
import _ from 'lodash'

export default class DataItem extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array])
  }

  static defaultProps = {
    label: '',
    value: ''
  }

  _renderDataItemLabel = (label, value) => {
    if (value && value !== '') {
      return <span className={'label'}>{label}</span>
    } else {
      return null
    }
  }

  _renderDataItemsLoop = (label, value) => {
    if (!_.size(value)) return null

    if (!_.isArray(value)) return <span className={'value'}>{value}</span>

    return (
      <span>
        {_.map(value, (v, i) => (
          <span className={'value value-' + i} key={'value-' + label + '-' + i}>
            {v.name}
          </span>
        ))}
      </span>
    )
  }

  _renderDataItemValue = value => {
    if (value && value !== '') {
      return <span className={'value'}>{value}</span>
    } else {
      return null
    }
  }

  render() {
    const { label, value } = this.props
    return (
      <DataItemStyled className={'mod mod-atoms mod-atoms-dataItem regular-typo'}>
        {this._renderDataItemLabel(label, value)}
        {this._renderDataItemsLoop(label, value)}
      </DataItemStyled>
    )
  }
}
