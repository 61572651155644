//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import { ButtonIcon, DataItem, ButtonEmail, Button, TextInput } from '../../atoms'
import { SectionColHeader, SingleContactHeader, SectionDepHeader, ContactCard, SectionComHeader, NavBarSearch } from '../../molecules'
import { ContactsUtils } from '../../../redux/contacts'
import { ProjectsUtils } from '../../../redux/projects'
import _ from 'lodash'
import white_close from '../../../assets/images/icons/white-close.svg'
import { Animated } from 'react-animated-css'
import { BookFilter } from './styled'
import filterIcon from '../../../assets/images/icons/filter.png'
import { sanitize } from '../../../utils/utils'
import { checkIncludesContacts, filterContactsByCompany, filterContactsByCompanyDepartment } from '../../../redux/contacts/utils'

export default class Contact extends PureComponent {
  static propTypes = {
    contacts: PropTypes.array,
    profile: PropTypes.object,
    sendEmailContacts: PropTypes.func,
    onPressCall: PropTypes.func,
    history: PropTypes.object,
    projects: PropTypes.array,
    userProjects: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }

  state = {
    contact: null,
    editable: false,
    showContacts: false,
    emailTo: [],
    filterSearchText: '',
    showFilters: false,
    department: '',
    position: '',
    company: ''
  }

  componentDidMount() {
    const contact = _.get(this.props, 'match.params.contact', null)
    this._loadContactByEmail(contact)
  }

  componentDidUpdate(prevProps) {
    const contact = _.get(this.props, 'match.params.contact', null)
    const prevContact = _.get(prevProps, 'match.params.contact', null)
    if (contact !== prevContact) {
      this._loadContactByEmail(contact)
      this.state.showContacts && this.setState({ showContacts: false })
    }
  }

  _toggleShowContacts = () => this.setState({ showContacts: !this.state.showContacts })

  _toggleShowFilters = () => this.setState({ showFilters: !this.state.showFilters })

  _onChangeFilterSearchText = filterSearchText => this.setState({ filterSearchText })

  _loadContactByEmail = contactEmail => {
    const { contacts, profile } = this.props
    if (contactEmail) {
      const contact = _.find(contacts, { email: contactEmail })
      const editable = ContactsUtils.canEditProfile({ contact, profile })
      this.setState({ contact, editable })
    } else {
      this.setState({ contact: null, editable: false })
    }
  }

  _sendEmailTo = () => {
    const { emailTo } = this.state
    this.props.sendEmailContacts(emailTo).then(() => this.setState({ emailTo: [] }))
  }

  _onEmailTapped = () => {
    if (this.state.contact) {
      this.props.sendEmailContacts([this.state.contact])
    }
  }

  _onCallTapped = (e, contact) => {
    e.stopPropagation()
    if (contact) {
      this.props.onPressCall(contact)
    } else if (this.state.contact) {
      this.props.onPressCall(this.state.contact)
    }
  }

  _onEditTapped = () => {
    if (this.state.contact) {
      const encodedContactEmail = encodeURI(this.state.contact.email)
      const url = '/edit/' + encodedContactEmail
      this.props.history.push(url)
    }
  }

  _onContactTapped = item => {
    if (item.id === _.get(this.props, 'profile.id')) {
      this.props.history.push('/my-profile/my-data')
    } else {
      const project = '_'
      const company = '_'
      const encodedContactEmail = encodeURI(item.email)
      const url = '/projects/' + project + '/' + company + '/' + encodedContactEmail
      this.props.history.push(url)

      this.setState({ showContacts: false })
    }
  }

  _onContactEmailTapped = (e, item) => {
    e.stopPropagation()
    const itemIndexInEmailTo = _.findIndex(this.state.emailTo, { email: item.email })
    if (itemIndexInEmailTo !== -1) {
      this.setState(prevState => ({ emailTo: _.filter(prevState.emailTo, (v, i) => i !== itemIndexInEmailTo) }))
    } else {
      this.setState(prevState => ({ emailTo: [...prevState.emailTo, item] }))
    }
  }

  _onEmailDepartmentTapped = ({ list, department, company }, isSelected) => {
    const { emailTo } = this.state

    if (isSelected) {
      const newContacts = filterContactsByCompanyDepartment(company, department, emailTo)
      this.setState({ emailTo: newContacts })
    } else {
      let newContacts = []
      _.forEach(list, contact => {
        if (!_.find(emailTo, { email: contact.email })) {
          newContacts.push(contact)
        }
      })

      this.setState(prevState => ({ emailTo: [...prevState.emailTo, ...newContacts] }))
    }
  }

  _onEmailCompanyTapped = ({ list, company }, isSelected) => {
    const { emailTo } = this.state

    if (isSelected) {
      const newContacts = filterContactsByCompany(company, emailTo)
      this.setState({ emailTo: newContacts })
    } else {
      let newContacts = []
      _.forEach(list, depContacts => {
        _.forEach(depContacts, contact => {
          if (!_.find(emailTo, { email: contact.email })) {
            newContacts.push(contact)
          }
        })
      })

      this.setState(prevState => ({ emailTo: [...prevState.emailTo, ...newContacts] }))
    }
  }

  _onAllEmailsTapped = () => {
    const { contacts } = this.props
    this.props.sendEmailContacts(contacts)
  }

  _renderCompanytHeader = ({ list, company }) => {
    const { emailTo } = this.state

    let emailSelected = true
    _.forEach(list, depContacts => {
      if (!checkIncludesContacts(depContacts, emailTo)) {
        emailSelected = false
      }
    })
    const iconBackColor = emailSelected ? 'tint' : 'mediumGrey'
    return (
      <div key={company} style={{ margin: 0 }}>
        <SectionComHeader company={company}>
          <ButtonEmail
            back={iconBackColor}
            label={'email'}
            onClick={() => this._onEmailCompanyTapped({ list, company }, emailSelected)}
            selected={emailSelected}
          />
        </SectionComHeader>
        {_.map(list, (depContacts, department) => this._renderDepartmentHeader({ list: depContacts, department, company }))}
      </div>
    )
  }

  _renderDepartmentHeader = ({ list, department, company }) => {
    const { emailTo } = this.state
    const emailSelected = checkIncludesContacts(list, emailTo)
    const iconBackColor = emailSelected ? 'tint' : 'mediumGrey'
    return (
      <div key={department}>
        <SectionDepHeader department={department}>
          <ButtonEmail
            back={iconBackColor}
            label={'email'}
            onClick={() => this._onEmailDepartmentTapped({ list, department, company }, emailSelected)}
            selected={emailSelected}
          />
        </SectionDepHeader>
        <div>{_.map(list, contact => this._renderContactCard({ contact, department, company }))}</div>
      </div>
    )
  }

  _renderContactCard = ({ contact, department, company }) => {
    const emailSelected = _.findIndex(this.state.emailTo, { email: contact.email }) !== -1
    const selected = _.get(this.props, 'match.params.contact', '') === contact.email
    const { profile } = this.props
    return (
      <ContactCard
        key={'contact' + contact.id}
        contact={contact}
        onContactTapped={() => this._onContactTapped(contact, department, company)}
        onEmailTapped={e => this._onContactEmailTapped(e, contact)}
        onCallTapped={e => this._onCallTapped(e, contact)}
        emailSelected={emailSelected}
        selected={selected}
        profile={profile}
      />
    )
  }

  _renderBookContactsFilter = () => {
    const { filterSearchText, showFilters } = this.state
    return (
      <BookFilter showFilters={showFilters}>
        <div className="search-bar">
          <NavBarSearch text={filterSearchText} onChange={this._onChangeFilterSearchText} />
          <img src={filterIcon} className="filter-icon" alt="filter" onClick={this._toggleShowFilters} />
        </div>
        <div className="search-filters">
          {showFilters && (
            <>
              <div style={{ height: 10 }} />
              <TextInput id={'company'} label={i18n.company} placeholder={' '} value={this.state.company} onChange={company => this.setState({ company })} />
              <TextInput
                id={'department'}
                label={i18n.department}
                placeholder={' '}
                value={this.state.department}
                onChange={department => this.setState({ department })}
              />
              <TextInput
                id={'position'}
                label={i18n.position}
                placeholder={' '}
                value={this.state.position}
                onChange={position => this.setState({ position })}
              />
            </>
          )}
        </div>
      </BookFilter>
    )
  }

  _renderBookContacts = () => {
    const { showContacts, emailTo, filterSearchText } = this.state
    const { contacts } = this.props
    const fst = sanitize(filterSearchText).toUpperCase()
    let filterContacts = _.filter(contacts, ({ name, surname, company, department, position, email }) => {
      return (
        `${sanitize(name)} ${sanitize(surname)}`.toUpperCase().includes(fst) ||
        `${sanitize(company)}`.toUpperCase().includes(fst) ||
        `${sanitize(department)}`.toUpperCase().includes(fst) ||
        `${sanitize(position)}`.toUpperCase().includes(fst) ||
        `${sanitize(email)}`.toUpperCase().includes(fst)
      )
    })
    const { department: fDepartment, position: fPosition, company: fCompany } = this.state
    if (fCompany) {
      filterContacts = _.filter(filterContacts, ({ company }) => {
        return `${company}`.toUpperCase().includes(fCompany.toUpperCase())
      })
    }
    if (fDepartment) {
      filterContacts = _.filter(filterContacts, ({ department }) => {
        return `${department}`.toUpperCase().includes(fDepartment.toUpperCase())
      })
    }
    if (fPosition) {
      filterContacts = _.filter(filterContacts, ({ position }) => {
        return `${position}`.toUpperCase().includes(fPosition.toUpperCase())
      })
    }

    const groupedList = ContactsUtils.groupContactsByCompanyDepartment(filterContacts)
    const aux1 = _.map(groupedList, (departments, company) => ({ company, departments }))
    const aux2 = _.sortBy(aux1, item => item.company)
    const wraperStyle = { position: 'absolute', left: 0, top: 0, right: 0, height: showContacts ? 'calc(100% - 33px)' : '100%', zIndex: 9999 }
    const submitButtonWraperStyle = { position: 'absolute', bottom: 0, left: 20, right: 20, zIndex: 99999 }
    const scrollStyle = { marginTop: '48px', height: '100%', overflow: 'scroll', background: 'white', paddingBottom: 100 }
    return (
      <Animated style={wraperStyle} animationIn="slideInUp" animationOut="slideOutDown" isVisible={showContacts} animateOnMount={false}>
        <SectionColHeader title={<ContactsTitle />} onClickTitle={this._toggleShowContacts}>
          <ButtonIcon label={'Email'} onClick={this._onAllEmailsTapped} color={'white'} icon={'mail'} />
        </SectionColHeader>

        <div style={scrollStyle}>
          <div>
            <div className={'col-loop ts '}>
              {this._renderBookContactsFilter()}
              {_.map(aux2, item => this._renderCompanytHeader({ list: item.departments, company: item.company }))}
            </div>
          </div>
        </div>

        <Animated style={submitButtonWraperStyle} animationIn="bounceInUp" animationOut="bounceOutDown" isVisible={!_.isEmpty(emailTo)} animateOnMount={false}>
          <div>
            <Button className={'clear-blue block'} label={i18n.sendEmail} onClick={this._sendEmailTo} parentClass={'no-padd'} />
          </div>
        </Animated>
      </Animated>
    )
  }

  render() {
    const { profile, projects, userProjects } = this.props
    const { contact, editable } = this.state
    const showCallButton = _.get(contact, `contacts[${profile.id}]`) === true && (!_.isEmpty(_.get(contact, 'phone1')) || !_.isEmpty(_.get(contact, 'phone2')))
    return (
      <div className={'col-cells-holder col-single-view'}>
        <SectionColHeader title={i18n.contact}>
          <ButtonIcon label={'Email'} onClick={this._onEmailTapped} color={'white'} icon={'mail'} />
          {showCallButton && <ButtonIcon label={'Call'} onClick={this._onCallTapped} color={'white'} icon={'call'} />}
          {editable ? <ButtonIcon label={'Edit'} onClick={this._onEditTapped} color={'white'} icon={'edit'} /> : null}
        </SectionColHeader>
        <div className={'col-loop'}>{contact ? <ContactData contact={contact} profile={profile} projects={projects} userProjects={userProjects} /> : null}</div>
        <SectionColHeader title={i18n.contacts.book} className={'section-footer'} onClickTitle={this._toggleShowContacts}>
          <ButtonIcon label={'Email'} onClick={this._onAllEmailsTapped} color={'white'} icon={'mail'} />
        </SectionColHeader>
        {this._renderBookContacts()}
      </div>
    )
  }
}

const ContactsTitle = () => (
  <span style={{ display: 'flex' }}>
    <img style={{ marginRight: 8 }} src={white_close} alt="Contacts" />
    {i18n.contacts.book}
  </span>
)

const ContactData = ({ contact, profile, projects, userProjects }) => {
  const completeName = ContactsUtils.getCompleteName(contact)
  const initials = ContactsUtils.getInitials(contact)
  const img = _.get(contact, 'image', null)
  const company = _.get(contact, 'company', '')
  const position = _.get(contact, 'position', '')
  const department = _.get(contact, 'department', '')
  const phone1 = _.get(contact, 'phone1', '')
  const phone2 = _.get(contact, 'phone2', '')
  const email = _.get(contact, 'email', '')
  const projects0 = _.get(contact, 'projects')
  const commonProjects = ProjectsUtils.getCommonProjects({ projects0, projects1: userProjects, projectsInfo: projects })

  const isCreator = contact.creator === profile.id
  const showPhone = (_.get(contact, `contacts[${profile.id}]`) === 0 && isCreator) || _.get(contact, `contacts[${profile.id}]`) === true

  return (
    <article className={'module module-cell-single-view regular-typo'}>
      <div className={'container'}>
        <div className={'inner-cell-single-view'}>
          <div className={'row'}>
            <SingleContactHeader name={completeName} position={position} img={img} initials={initials} />

            <div className={'col col-xs-12 info-holder regular-form'}>
              <ul>
                <DataItem label={i18n.company} value={company} />
                <DataItem label={i18n.position} value={position} />
                <DataItem label={i18n.department} value={department} />
                {showPhone && <DataItem label={i18n.phone1} value={phone1} />}
                {showPhone && <DataItem label={i18n.phone2} value={phone2} />}
                <DataItem label={i18n.email} value={email} />
                <DataItem label={i18n.commonProjects} value={commonProjects} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}

ContactData.propTypes = {
  contacts: PropTypes.array,
  profile: PropTypes.object,
  sendEmailContacts: PropTypes.func,
  onPressCall: PropTypes.func,
  contact: PropTypes.object,
  projects: PropTypes.array,
  userProjects: PropTypes.object
}
