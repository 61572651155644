// Component
import Page from './view'
import { AuthOperations } from '../../../../redux/auth'
import { withRouter } from 'react-router'

// Redux
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    isFetching: state.auth.isFetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: data => dispatch(AuthOperations.sendPasswordResetEmail(data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Page))
