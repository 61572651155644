import React, { PureComponent } from 'react'
import { NotFoundStyled } from './styled'
import logo_white from '../../../assets/images/logo-tagblue.svg'

export default class NotFound extends PureComponent {
  render() {
    return (
      <NotFoundStyled>
        <img src={logo_white} alt="WGL" />

        <p className="title">404</p>
        <p className="subtitle">Page Not Found</p>
      </NotFoundStyled>
    )
  }
}
