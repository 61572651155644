import React from 'react'
import { Provider } from 'react-redux'
import { store, persistor } from '../../../config/redux'
import { BrowserRouter } from 'react-router-dom'
import Routes from '../routes'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme, GlobalStyle, Reset, FlexGrid, SetStormyTrans } from '../../../config/styled'
import '../../../config/styles'
import materialTheme from '../../../config/material'

const App = () => {
  return (
    <Provider store={store}>
      <Reset />
      <SetStormyTrans />
      <FlexGrid />
      <GlobalStyle />

      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={materialTheme}>
              <Routes />
            </MuiThemeProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
