import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContainerStyled } from './styled'

export default class ColImageName extends Component {
  static propTypes = {
    img: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    initials: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    position: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }

  static defaultProps = {
    img: false,
    name: false,
    initials: false,
    position: false
  }

  _renderPicture = (image, name) => {
    return (
      <figure>
        <img src={image} alt={name} />
      </figure>
    )
  }

  _renderInitials = initials => {
    return (
      <div className={'initials'}>
        <div className={'initials-inner grad-gray'}>
          <span>{initials}</span>
        </div>
      </div>
    )
  }

  _renderName = (name, position) => {
    return (
      <div className={'copy'}>
        <p>{name}</p>
        {position ? <p>{position}</p> : null}
      </div>
    )
  }

  render() {
    const { img, name, position, initials } = this.props
    const display = img ? this._renderPicture(img, name) : this._renderInitials(initials)
    const copy = this._renderName(name, position)

    return (
      <ContainerStyled className={'mod mod-atoms mod-atoms-ColImageName regular-typo'}>
        {display}
        {copy}
      </ContainerStyled>
    )
  }
}
