import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;

  figure {
    width: 50px;
    height: 50px;
    position: relative;
    flex: 0 0 50px;
    border-radius: 50%;
    img {
      border-radius: 50%;
      display: block;
      width: 45px;
      height: 45px;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .initials {
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    flex: 0 0 50px;
    text-transform: uppercase;
    .initials-inner {
      display: block;
      width: 42px;
      height: 42px;
      text-align: center;
      line-height: 45px;
      color: #fff;
      border-radius: 50%;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: ${props => props.theme.gradients.grey2.lineBG1};
      background: ${props => props.theme.gradients.grey2.lineBG2};
      background: ${props => props.theme.gradients.grey2.lineBG3};
      background: ${props => props.theme.gradients.grey2.lineBG4};
      filter: ${props => props.theme.gradients.grey2.lineFLT};
      span {
        position: relative;
        top: -1px;
      }
    }
  }

  .copy {
    display: block;
    padding: 0 10px;
    flex: 1 1 auto;
    p {
      margin-bottom: 0;
    }
    p + p {
      opacity: 0.44;
      font-size: 0.9em;
      line-height: 1.3em;
    }
  }
`
