import styled from 'styled-components'
import { BlueToRight } from '../../../assets/images/'

export const SelectInputStyled = styled.div`
  .inner {
    position: relative;
    padding: 19px 0 22px 0;
    .error-message {
      font-size: 11px;
      color: ${props => props.theme.colors.tint} !important;
      line-height: 16px;
      padding: 0;
      position: absolute;
      bottom: 4px;
    }

    > div {
      background: transparent !important;
      padding: 0 !important;
      position: relative;
      top: -4px;
      border: none !important;

      > span {
        padding: 20px !important;
        background: blue !important;
        border: 2px solid green !important;
      }

      > div {
        background: ${props => props.theme.colors.white} !important;
        position: relative;
        border-radius: 0 !important;
        border-width: 0 0 1px 0 !important;
        border-bottom-color: ${props => props.theme.colors.grey} !important;
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: none !important;
        > div {
          border-color: transparent !important;
          padding: 0 !important;
          > div {
            font-size: 16px;
            line-height: 23px;
            padding: 0 !important;
            margin: 0 !important;
          }
        }
        > div + div {
          transform: rotate(90deg);
          background: ${props => props.theme.colors.white} center center no-repeat url(${BlueToRight});
          background-size: 10px;
          position: absolute;
          top: 7px;
          right: 0;
          line-height: 24px !important;
          height: 24px !important;
          width: 24px !important;
          span,
          svg {
            display: none;
          }
        }
      }
    }
  }

  .inner.no-padd {
    padding: 0 !important;
  }

  .inner.no-bottom-padd {
    padding-bottom: 0 !important;
  }

  .inner.no-top-padd {
    padding-top: 0 !important;
  }

  .inner.bottom-padd-big {
    padding-bottom: 45px;
  }

  .append-note {
    color: ${props => props.theme.colors.black};
    display: block;
    padding: 0;
    font-size: 14px;
    line-height: 19px;
  }

  label {
    color: ${props => props.theme.colors.black};
    font-weight: 400;
    font-style: normal;
    position: absolute;
    opacity: 1;
    pointer-events: none;
    top: 0;
    font-size: 14px;
    line-height: 19px;
    padding: 0;
    height: 19px;
    display: block;
    width: 100%;
    .mand {
      color: ${props => props.theme.colors.tint};
      font-size: 16px;
      font-weight: bold;
      position: relative;
      top: 4px;
      left: 4px;
    }
  }
`
