import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ButtonIcon } from '../../atoms'
import { ModalStyled } from './styled'

export default class ModalHolder extends Component {
  static propTypes = {
    addClose: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    CloseOnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
  }

  static defaultProps = {
    children: '',
    addClose: false,
    CloseOnClick: null
  }

  render() {
    const { className, children, addClose, CloseOnClick } = this.props

    return (
      <ModalStyled className={'mod mod-atoms mod-atoms-modalProfileHolder regular-typo'}>
        <div className={'holder-overlay ' + className}>
          <div className={'overlay'}>
            {addClose && <ButtonIcon onClick={CloseOnClick} color={'blue'} icon={'close'} back={'lightGrey'} className={'close-modal'} />}
            <div className={'container'}>
              <div className={'inner ts ts-quick'}>
                <div className={'row'}>
                  <div className={'col-xs-12'}>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalStyled>
    )
  }
}
