import styled from 'styled-components'

export const InputColorStyled = styled.div`
  .inner {
    position: relative;
    padding: 19px 0 22px 0;
    .error-message {
      font-size: 11px;
      color: ${props => props.theme.colors.tint} !important;
      line-height: 16px;
      padding: 0;
      position: absolute;
      bottom: 4px;
    }
  }

  .inner.no-padd {
    padding: 0;
  }

  .inner.no-top-padd {
    padding-top: 0 !important;
  }

  .inner.bottom-padd-big {
    padding-bottom: 45px;
  }

  .append-note {
    color: ${props => props.theme.colors.black};
    display: block;
    padding: 0;
    font-size: 14px;
    line-height: 19px;
  }

  .color-choose-holder {
    padding: 2px 3px;
    display: inline-block;
    background: ${props => props.theme.colors.white};
    input {
      display: none;
      + label {
        position: relative;
        cursor: pointer;
        height: 30px;
        width: 55px;
        display: block;
        border: 2px solid ${props => props.theme.colors.white};
        text-indent: -999px;
        overflow: hidden;
      }
      :checked {
        + label {
          border-color: ${props => props.theme.colors.tint};
        }
      }
    }
  }
`
