//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ModalHolder } from '../../../atoms'
import AddContactForm from '../add'

export default class Modal extends PureComponent {
  static propTypes = {
    history: PropTypes.object
  }

  _onClickClose = () => this.props.history.goBack()

  render() {
    return (
      <ModalHolder className={'on-center'} addClose={false}>
        <AddContactForm isModal={true} className={'slim-form-holder'} onClickCloseModal={this._onClickClose} />
      </ModalHolder>
    )
  }
}
