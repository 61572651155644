import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { CardHolderStyled } from './styled'

export default class CardHolder extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    actions: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element, PropTypes.bool]),
    addClass: PropTypes.string,
    forceActions: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.element, PropTypes.object])
  }

  static defaultProps = {
    onClick: null,
    info: '',
    actions: '',
    addClass: '',
    selected: false,
    forceActions: false
  }

  render() {
    const { onClick, info, actions, addClass, selected, forceActions } = this.props
    const isSelectedClass = selected ? 'is-selected-card' : ''
    const hasActionsInfoClass = forceActions && forceActions === 'noaction' ? 'col-xs-12 info-no-actions' : 'col-xs-8 info-has-actions'
    const hasActionsActClass = forceActions && forceActions === 'noaction' ? 'actions-holder no-actions' : 'col-xs-4 actions-holder has-actions'
    const renderActions = forceActions && forceActions === 'noaction' ? '' : forceActions ? forceActions : actions

    return (
      <CardHolderStyled onClick={onClick} className={'mod mod-atoms mod-atoms-cardHolder regular-typo ' + addClass}>
        <div className={'container ts ts-quick ' + isSelectedClass}>
          <div className={'inner-cell ts ts-quick'}>
            <div className={'row middle-xs'}>
              <div className={hasActionsInfoClass}>{info}</div>
              <div className={hasActionsActClass}>{renderActions}</div>
            </div>
          </div>
        </div>
      </CardHolderStyled>
    )
  }
}
