//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../assets/i18n'
import { Button, ModalFormHeader } from '../../../atoms'
import { ProfileForm, EmailForm, ProfileInfoStep } from '../../../molecules'
import _ from 'lodash'
import Utils from '../../../../utils'

const STEPS = {
  EMAIL_VALIDATION: 0,
  NEW_PROFILE: 1,
  ADD_CONTACT: 2,
  IS_CURRENT_USER: 3,
  ALREADY_EXISTS_CONTACT: 4
}

export default class ContactsAdd extends PureComponent {
  state = {
    step: STEPS.EMAIL_VALIDATION,
    email: '',
    isFetching: false
  }

  static propTypes = {
    resetAddProfile: PropTypes.func,
    addProfile: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isFetching: PropTypes.bool,
    history: PropTypes.object,
    contacts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    checkProfileExist: PropTypes.func,
    addNewContactProfile: PropTypes.func,
    isModal: PropTypes.bool,
    notify: PropTypes.func,
    addExistingContactProfile: PropTypes.func,
    onClickCloseModal: PropTypes.func
  }

  static defaultProps = {
    isModal: false
  }

  componentWillUnmount() {
    this.props.resetAddProfile()
  }

  componentDidUpdate() {
    const { step } = this.state
    const { addProfile, isFetching, contacts } = this.props
    const isCurrentUser = _.get(this.props, 'email') === _.get(this.props, 'addProfile.email')
    const isAlreadyExistsContact = addProfile ? _.find(contacts, (_, key) => key === addProfile.id) : false
    const hasProfile = addProfile

    if (step === STEPS.EMAIL_VALIDATION && !isFetching && !_.isNull(addProfile)) {
      if (!hasProfile) {
        this._showNewProfileStep()
      } else if (isCurrentUser) {
        this._showIsCurrentUserStep()
      } else if (isAlreadyExistsContact) {
        this._showAlreadyExistsContactStep()
      } else {
        this._showAddContactStep()
      }
    }
  }

  _showNewProfileStep() {
    this.setState({ step: STEPS.NEW_PROFILE })
  }

  _showIsCurrentUserStep() {
    this.setState({ step: STEPS.IS_CURRENT_USER })
  }

  _showAlreadyExistsContactStep() {
    this.setState({ step: STEPS.ALREADY_EXISTS_CONTACT })
  }

  _showAddContactStep() {
    this.setState({ step: STEPS.ADD_CONTACT })
  }

  _onSubmitValidateEmail = email => {
    if (Utils.emailValidate(email).isValid) {
      this.props.checkProfileExist(email)
      this.setState({ email })
    }
  }

  _onSubmitNewProfile = data => {
    const { email } = this.state
    const newProfile = { ...data, email }
    this.setState({ isFetching: true })
    this.props.addNewContactProfile(newProfile).then(response => {
      this.setState({ isFetching: false })

      if (response) {
        if (this.props.isModal) {
          this.props.history.goBack()
        } else {
          this.props.history.replace('/projects')
        }
        this.props.notify({
          message: i18n.success.contactAdd,
          status: 'success'
        })
      } else {
        this.props.history.goBack()
        this.props.notify({
          message: i18n.success.contactAddError,
          status: 'error'
        })
      }
    })
  }

  _onSubmitAddContact = () => {
    this.props.addExistingContactProfile().then(() => {
      this.props.history.replace('/projects')
      this.props.notify({
        message: i18n.success.contactInvite,
        status: 'success'
      })
    })
  }

  _onSubmitReset = () => {
    this.props.resetAddProfile()
    this.setState({
      step: STEPS.EMAIL_VALIDATION,
      email: ''
    })
  }

  _renderStepAddContact() {
    const { addProfile, onClickCloseModal } = this.props

    // eslint-disable-next-line no-unused-vars
    const { key, ...profile } = addProfile

    return (
      <div className={'slim-form-holder-nofloat'}>
        <ModalFormHeader title={i18n.contactAdd.createContact} onClickClose={onClickCloseModal} />
        <div className={'col col-xs-12'}>
          <ProfileInfoStep label={i18n.contactAdd.alreadyMember} profile={profile} />
        </div>
        <div className={'col col-xs-12 center'}>
          <Button label={i18n.invite} onClick={this._onSubmitAddContact} className={'clear-blue inline'} />
        </div>
      </div>
    )
  }

  _renderStepIsCurrentUser() {
    const { addProfile, onClickCloseModal } = this.props

    // eslint-disable-next-line no-unused-vars
    const { key, ...profile } = addProfile

    return (
      <div className={'slim-form-holder-nofloat'}>
        <ModalFormHeader title={i18n.contactAdd.createContact} onClickClose={onClickCloseModal} />
        <div className={'col col-xs-12'}>
          <ProfileInfoStep label={i18n.contactAdd.isCurrentUser} profile={profile} />
        </div>
        <div className={'col col-xs-12 center'}>
          <Button label={i18n.new} onClick={this._onSubmitReset} className={'clear-blue inline'} />
        </div>
      </div>
    )
  }

  _renderStepAlreadyExistsContact() {
    const { addProfile, onClickCloseModal } = this.props

    // eslint-disable-next-line no-unused-vars
    const { key, ...profile } = addProfile

    return (
      <div className={'slim-form-holder-nofloat'}>
        <ModalFormHeader title={i18n.contactAdd.createContact} onClickClose={onClickCloseModal} />
        <div className={'col col-xs-12'}>
          <ProfileInfoStep label={i18n.contactAdd.alreadyContact} profile={profile} />
        </div>
        <div className={'col col-xs-12 center'}>
          <Button label={i18n.new} onClick={this._onSubmitReset} className={'clear-blue inline'} />
        </div>
      </div>
    )
  }

  _renderStepEmailValidation() {
    const { isFetching, onClickCloseModal } = this.props
    return <EmailForm isFetching={isFetching} onSubmit={this._onSubmitValidateEmail} onClickCloseModal={onClickCloseModal} />
  }

  _renderStepNewProfile() {
    const { addProfile, onClickCloseModal } = this.props
    const { isFetching } = this.state
    return (
      <ProfileForm
        onSubmit={this._onSubmitNewProfile}
        headTitle={i18n.contactAdd.createContact}
        profile={addProfile}
        isFetching={isFetching}
        addNote={i18n.contactAdd.noMember}
        onClickCloseModal={onClickCloseModal}
      />
    )
  }

  _renderCurrentStep() {
    const { step } = this.state
    switch (step) {
      case STEPS.EMAIL_VALIDATION:
        return this._renderStepEmailValidation()
      case STEPS.NEW_PROFILE:
        return this._renderStepNewProfile()
      case STEPS.ADD_CONTACT:
        return this._renderStepAddContact()
      case STEPS.IS_CURRENT_USER:
        return this._renderStepIsCurrentUser()
      case STEPS.ALREADY_EXISTS_CONTACT:
        return this._renderStepAlreadyExistsContact()
      default:
        break
    }
  }

  _renderAsModal() {
    return <div className={'is-modal'}>{this._renderCurrentStep()}</div>
  }

  _renderAsNoModal() {
    return (
      <div className={'motherwrapper edit-user no-modal'}>
        <div className={'container ts ts-quick'}>{this._renderCurrentStep()}</div>
      </div>
    )
  }

  render() {
    const isModal = this.props.isModal
    const display = isModal ? this._renderAsModal() : this._renderAsNoModal()
    return <div>{display}</div>
  }
}
