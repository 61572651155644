import styled from 'styled-components'

export const EmailToListStyled = styled.div`
  background: ${props => props.theme.colors.lightGrey};
  border-bottom: 1px solid ${props => props.theme.colors.mediumGrey};
  position: absolute;
  left: 0;
  top: 48px;
  width: 100%;
  z-index: 99;
  padding: 0 5px;
  text-align: center;

  .col {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .container {
    max-width: 100%;
    .inner {
      padding: 14px 0;
      .row {
        padding: 5px 0;
      }
    }
  }
`
