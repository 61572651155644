import styled from 'styled-components'
import {
  WhiteSettings,
  WhiteAdd,
  WhiteMagGlass,
  WhiteCall,
  WhiteClose,
  WhiteToRight,
  WhiteMail,
  WhiteMailAlt,
  WhiteEdit,
  BlackSettings,
  BlackAdd,
  BlackMagGlass,
  BlackCall,
  BlackClose,
  BlackToRight,
  BlackMail,
  BlackMailAlt,
  BlackEdit,
  BlueSettings,
  BlueAdd,
  BlueMagGlass,
  BlueCall,
  BlueClose,
  BlueToRight,
  BlueToBottom,
  BlueMail,
  BlueMailAlt,
  BlueEdit
} from '../../../assets/images/'

export const ButtonStyled = styled.span`
  display: inline-block;

  button {
    height: 36px;
    width: 36px;
    line-height: 36px;
    border-radius: 50%;
    position: relative;
    padding: 0;
    background: transparent;

    span {
      display: none;
    }

    :before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 26px;
      height: 26px;
      content: '';
      display: block;
      background-size: 21px;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .no-label:hover {
    transform: scale(1.1, 1.1);
  }

  .display-label {
    span {
      line-height: 20px;
      padding: 2px 5px;
      top: -15px;
      display: inline-block;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      background: ${props => props.theme.colors.tint};
      color: ${props => props.theme.colors.white};
      left: 50%;
      -ms-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      border-radius: 5px;
    }
  }

  .display-label:hover {
    span {
      opacity: 1;
      top: -20px;
    }
  }

  .edit.white {
    button {
      :before {
        background-image: url(${WhiteEdit});
      }
    }
  }
  .mail.white {
    button {
      :before {
        background-image: url(${WhiteMail});
        background-size: 22px;
      }
    }
  }
  .mail-alt.white {
    button {
      :before {
        background-image: url(${WhiteMailAlt});
      }
    }
  }
  .add.white {
    button {
      :before {
        background-image: url(${WhiteAdd});
      }
    }
  }
  .call.white {
    button {
      :before {
        background-image: url(${WhiteCall});
        background-size: 18px;
      }
    }
  }
  .search.white {
    button {
      :before {
        background-image: url(${WhiteMagGlass});
        background-size: 18px;
      }
    }
  }
  .close.white {
    button {
      :before {
        background-image: url(${WhiteClose});
        background-size: 18px;
      }
    }
  }
  .settings.white {
    button {
      :before {
        background-image: url(${WhiteSettings});
      }
    }
  }
  .toright.white {
    button {
      :before {
        background-image: url(${WhiteToRight});
        background-size: 12px;
      }
    }
  }

  .edit.black {
    button {
      :before {
        background-image: url(${BlackEdit});
      }
    }
  }
  .mail.black {
    button {
      :before {
        background-image: url(${BlackMail});
        background-size: 22px;
      }
    }
  }
  .mail-alt.black {
    button {
      :before {
        background-image: url(${BlackMailAlt});
      }
    }
  }
  .add.black {
    button {
      :before {
        background-image: url(${BlackAdd});
      }
    }
  }
  .call.black {
    button {
      :before {
        background-image: url(${BlackCall});
        background-size: 18px;
      }
    }
  }
  .search.black {
    button {
      :before {
        background-image: url(${BlackMagGlass});
        background-size: 18px;
      }
    }
  }
  .close.black {
    button {
      :before {
        background-image: url(${BlackClose});
        background-size: 18px;
      }
    }
  }
  .settings.black {
    button {
      :before {
        background-image: url(${BlackSettings});
      }
    }
  }
  .toright.black {
    button {
      :before {
        background-image: url(${BlackToRight});
        background-size: 12px;
      }
    }
  }

  .edit.blue {
    button {
      :before {
        background-image: url(${BlueEdit});
      }
    }
  }
  .mail.blue {
    button {
      :before {
        background-image: url(${BlueMail});
        background-size: 22px;
      }
    }
  }
  .mail-alt.blue {
    button {
      :before {
        background-image: url(${BlueMailAlt});
      }
    }
  }
  .add.blue {
    button {
      :before {
        background-image: url(${BlueAdd});
      }
    }
  }
  .call.blue {
    button {
      :before {
        background-image: url(${BlueCall});
        background-size: 18px;
      }
    }
  }
  .search.blue {
    button {
      :before {
        background-image: url(${BlueMagGlass});
        background-size: 18px;
      }
    }
  }
  .close.blue {
    button {
      :before {
        background-image: url(${BlueClose});
        background-size: 18px;
      }
    }
  }
  .settings.blue {
    button {
      :before {
        background-image: url(${BlueSettings});
      }
    }
  }
  .toright.blue {
    button {
      :before {
        background-image: url(${BlueToRight});
        background-size: 12px;
      }
    }
  }
  .tobottom.blue {
    button {
      :before {
        background-image: url(${BlueToBottom});
        background-size: 12px;
      }
    }
  }

  .back-blue0 {
    button {
      background: ${props => props.theme.colors.blue0};
    }
  }
  .back-blue1 {
    button {
      background: ${props => props.theme.colors.blue1};
    }
  }
  .back-blue2 {
    button {
      background: ${props => props.theme.colors.blue2};
    }
  }
  .back-blue3 {
    button {
      background: ${props => props.theme.colors.blue3};
    }
  }
  .back-blue4 {
    button {
      background: ${props => props.theme.colors.blue4};
    }
  }
  .back-black {
    button {
      background: ${props => props.theme.colors.black};
    }
  }
  .back-white {
    button {
      background: ${props => props.theme.colors.white};
    }
  }
  .back-tint {
    button {
      background: ${props => props.theme.colors.tint};
    }
  }
  .back-red {
    button {
      background: ${props => props.theme.colors.red};
    }
  }
  .back-blue {
    button {
      background: ${props => props.theme.colors.blue};
    }
  }
  .back-yellow {
    button {
      background: ${props => props.theme.colors.yellow};
    }
  }
  .back-green {
    button {
      background: ${props => props.theme.colors.green};
    }
  }
  .back-purple {
    button {
      background: ${props => props.theme.colors.purple};
    }
  }
  .back-grey {
    button {
      background: ${props => props.theme.colors.grey};
    }
  }
  .back-grey2 {
    button {
      background: ${props => props.theme.colors.grey2};
    }
  }
  .back-darkGrey {
    button {
      background: ${props => props.theme.colors.darkGrey};
    }
  }
  .back-mediumGrey {
    button {
      background: ${props => props.theme.colors.mediumGrey};
    }
  }
  .back-lightGrey {
    button {
      background: ${props => props.theme.colors.lightGrey};
    }
  }
  .back-background {
    button {
      background: ${props => props.theme.colors.background};
    }
  }
  .back-trans {
    button {
      background: transparent;
    }
  }
`
