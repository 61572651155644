//React
import React from 'react'

import fast from '../../../assets/images/fast.png'
import safe from '../../../assets/images/safe.png'
import simple from '../../../assets/images/simple.png'
import { HELP_EMAIL } from '../../../config/constants'

import { LandingHeaderStyled } from './styled'

const LandingFooter = () => (
  <LandingHeaderStyled>
    <div className="info">
      <div className="container">
        <div className="item">
          <img className="image" src={fast} alt="fast" />
          <p className="title">FAST</p>
          <div className="line" />
          <p className="text">Easy and intuitive service that helps create Working Group Lists in seconds</p>
          <p className="text">Updated information at all times (each user owns their info)</p>
          <p className="text">No need to create new contacts or download v-cards</p>
          <p className="text">Invite new members instantly and give them full access to Projects and Teams</p>
        </div>
        <div className="item">
          <img className="image" src={safe} alt="fase" />
          <p className="title">SAFE</p>
          <div className="line" />
          <p className="text">Private list, only list participants can see the info</p>
          <p className="text">Decide the information you share Information secured and not share with third parties</p>
          <p className="text">Information secured and not share with third parties</p>
          <p className="text">ISO 27001 certifications held (Powered by firebase)</p>
        </div>
        <div className="item">
          <img className="image" src={simple} alt="simple" />
          <p className="title">SIMPLE</p>
          <div className="line" />
          <p className="text">Keep all you professional contacts in one place</p>
          <p className="text">No need to update contacts</p>
          <p className="text">Send multiple emails to project members easily</p>
          <p className="text">Multi-platform product</p>
        </div>
      </div>
    </div>

    <p className="bottom">
      For any doubts or more info please email us at <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>
    </p>
  </LandingHeaderStyled>
)

export default LandingFooter
