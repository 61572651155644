import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import { ButtonStyled } from './styled'

export default class ButtonIcon extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    icon: PropTypes.string,
    color: PropTypes.string,
    back: PropTypes.string,
    parentClass: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    display: PropTypes.bool
  }

  static defaultProps = {
    label: '',
    onClick: null,
    isFetching: false,
    disabled: false,
    icon: '',
    color: 'white',
    back: 'trans',
    display: false,
    parentClass: '',
    className: ''
  }

  _handleClick(e) {
    if (!this.props.isFetching) {
      this.props.onClick && this.props.onClick(e)
    }
  }

  _renderContent() {
    if (this.props.isFetching) {
      return (
        <span>
          <Spinner color={'#11A8AD'} name={'circle'} fadeIn={'none'} />
        </span>
      )
    } else {
      return <span className={'ts ts-quick '}>{this.props.label}</span>
    }
  }

  render() {
    const type = this.props.onClick ? 'button' : 'submit'
    const { color, icon, back, display } = this.props
    const displayClass = display ? 'display-label' : 'no-label'

    return (
      <ButtonStyled className={'mod mod-atoms mod-atoms-buttonIcon'}>
        <div className={'input-holder no-padd' + this.props.parentClass + ' ' + color + ' ' + icon + ' back-' + back + ' ' + displayClass}>
          <button className={'ts ts-quick ' + this.props.className} disabled={this.props.disabled} type={type} onClick={this._handleClick.bind(this)}>
            {this._renderContent()}
          </button>
        </div>
      </ButtonStyled>
    )
  }
}
