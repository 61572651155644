//React
import React, { PureComponent } from 'react'
import i18n from '../../../assets/i18n'
import Utils from '../../../utils'
import PropTypes from 'prop-types'
import { Button, ModalFormHeader, FileInput } from '../../atoms'
import _ from 'lodash'

export default class CompanyForm extends PureComponent {
  static propTypes = {
    project: PropTypes.object,
    onSubmit: PropTypes.func,
    onClickCloseModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    isFetching: PropTypes.bool,
    headTitle: PropTypes.string
  }

  static defaultProps = {
    project: null,
    image: null,
    isFetching: false,
    onSubmit: () => {},
    headTitle: '',
    onClickCloseModal: false
  }

  constructor(props) {
    super(props)
    this.formInputs = {}
    this.state = {
      image: _.get(props, 'image', null),
      edited: false
    }
  }

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      const { image, edited } = this.state
      this.props.onSubmit(image, edited)
    }
  }

  render() {
    const { onClickCloseModal, headTitle } = this.props
    return (
      <form className={'row regular-form slim-form-holder-nofloat'} onSubmit={this._onSubmit}>
        <ModalFormHeader title={headTitle} onClickClose={onClickCloseModal} />
        <div className={'row scrollbox'}>
          <div className={'col col-xs-12'}>
            <FileInput
              ref={i => (this.formInputs.image = i)}
              id={'image'}
              label={i18n.imageLabel}
              value={this.state.image}
              onChange={image => this.setState({ image, edited: true })}
              validation={v => Utils.mandatoryValidate(v)}
            />
          </div>
          <div className={'col col-xs-12 center'}>
            <Button label={i18n.save} isFetching={this.props.isFetching} className={'clear-blue inline'} />
          </div>
        </div>
      </form>
    )
  }
}
