import React from 'react'
import PropTypes from 'prop-types'
import { ContainerStyled } from './styled'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import Utils from '../../../utils'

export default class FileInput extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    accept: PropTypes.array,
    validation: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    parentClass: PropTypes.string,
    appendNote: PropTypes.element
  }

  static defaultProps = {
    id: null,
    label: '',
    value: '',
    accept: ['image/png', 'image/jpeg'],
    validation: null,
    onChange: () => {},
    className: '',
    parentClass: '',
    appendNote: null
  }

  constructor(props) {
    super(props)
    this.state = {
      touched: false,
      valid: true,
      error: ''
    }
  }

  validate(v) {
    if (this.props.validation) {
      const value = v != null ? v : this.props.value
      const validation = this.props.validation(value)
      this.setState({ valid: validation.isValid, error: validation.error, touched: true })
      return validation.isValid
    } else {
      return true
    }
  }

  setValid(touched, valid, error) {
    this.setState({ touched, valid, error })
  }

  _onChange = acceptedFiles => {
    const file = _.get(acceptedFiles, '[0]', null)
    if (file) {
      Utils.getBase64(file)
        .then(base64 => {
          this.props.onChange(base64)
        })
        .catch(() => {
          this._onDeleteImage()
        })
    } else {
      this._onDeleteImage()
    }
  }

  _onDeleteImage = () => {
    this.props.onChange(null)
  }

  render() {
    const showError = this.state.touched && this.state.error ? true : false
    const showAppendote = this.props.appendNote ? <span className={'append-note'}>{this.props.appendNote}</span> : ''

    return (
      <ContainerStyled className={this.props.className + ' mod mod-atoms mod-atoms-fileInput '}>
        <div className={'input-holder'}>
          <div className={'inner ' + this.props.parentClass}>
            <div className={'dropzone'}>
              <Dropzone accept={this.props.accept} onDrop={this._onChange}>
                {({ getRootProps, getInputProps }) => {
                  return (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {this.props.value ? (
                        <figure>
                          <img alt="ts" src={this.props.value} className={' ts ts-instant ts-ease-out '} />
                        </figure>
                      ) : (
                        <figure>{this.props.placeholder}</figure>
                      )}
                    </div>
                  )
                }}
              </Dropzone>
              <label htmlFor={this.props.id}>{this.props.label}</label>
            </div>
            {showError && <span>{this.state.error}</span>}
          </div>
          {showAppendote}
        </div>
      </ContainerStyled>
    )
  }
}
