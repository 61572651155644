import styled from 'styled-components'

export const ColChipNameStyled = styled.div`
  display: flex;
  align-items: center;
  .chip {
    width: 31px;
    height: 28px;
    overflow: hidden;
    flex: 0 0 31px;
    .inner-chip {
      height: 28px;
      width: 28px;
      display: block;
      margin: 0;
      border-radius: 50%;
      background: ${props => (props.color ? props.color : props.theme.colors.mediumGrey)};
    }
  }
  .copy {
    display: block;
    padding: 0 10px;
    flex: 1 1 auto;
    p {
      margin-bottom: 0;
    }
  }
`
