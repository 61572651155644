import View from './view'
import { connect } from 'react-redux'
import { AuthOperations } from '../../../redux/auth'
import { withRouter } from 'react-router'
import i18n from '../../../assets/i18n'

const mapStateToProps = () => {
  const languaje = i18n.getLanguage()
  const code = languaje === 'en' ? 'GB' : languaje.toUpperCase()
  return {
    defaultCountry: code
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateLocale: languaje => dispatch(AuthOperations.updateLocale(languaje)),
    signOut: () => dispatch(AuthOperations.signOut())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(View))
