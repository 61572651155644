import View from './view'
import { connect } from 'react-redux'
import { AuthOperations } from '../../../../redux/auth'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
  return {
    isFetching: state.auth.isFetching
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: data => dispatch(AuthOperations.signIn(data, props.history))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(View))
