import styled from 'styled-components'

export const MemberSelectorStyled = styled.div`
  .added-members-loop {
    .inner-cell {
      background: ${props => props.theme.colors.white} !important;
      :hover {
        background: ${props => props.theme.colors.white} !important;
      }
      .row {
        padding: 0 10px;
      }
    }
  }

  .added-members-admin {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    span {
      margin-right: 5px;
    }
  }
`
