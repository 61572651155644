//React
import React from 'react'
import { LandingInfoStyled } from './styled'
import mock3 from '../../../assets/images/pantallas.png'

const LandingInfo = () => (
  <LandingInfoStyled>
    <div className="info-container">
      <p>Welcome to your communication platform</p>
    </div>
    <img src={mock3} alt="mock" />
  </LandingInfoStyled>
)

export default LandingInfo
