import _ from 'lodash'

export const isLogedUser = state => _.size(_.get(state, 'auth.token')) > 0

export const isUnauthorizedError = error => _.get(error, 'code') === 401

export const isFirebaseAuthError = error =>
  _.get(error, 'code') === 'auth/invalid-email' ||
  _.get(error, 'code') === 'auth/user-disabled' ||
  _.get(error, 'code') === 'auth/user-not-found' ||
  _.get(error, 'code') === 'auth/wrong-password' ||
  _.get(error, 'code') === 'auth/email-already-in-use' ||
  _.get(error, 'code') === 'auth/operation-not-allowed' ||
  _.get(error, 'code') === 'auth/weak-password'
    ? true
    : false

export const isValidEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
