import * as types from './types'
import { AuthTypes } from '../auth'

const initialState = {
  isFetching: false,
  projects: [],
  projectsAccepted: [],
  projectsInfo: [],
  searchText: ''
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_PROJECTS:
      return { ...state, projects: action.payload }
    case types.SET_PROJECTS_ACCEPTED:
      return { ...state, projectsAccepted: action.payload }
    case types.SET_PROJECTS_INFO:
      return { ...state, projectsInfo: action.payload }
    case types.SET_SEARCH_TEXT:
      return { ...state, searchText: action.payload }
    case AuthTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}
