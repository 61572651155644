//React
import React from 'react'
import logo_white from '../../../assets/images/logo-white.svg'
import { LandingHeaderStyled } from './styled'

const LandingHeader = () => (
  <LandingHeaderStyled>
    <div className="container">
      <img src={logo_white} alt="WGL" />
      <p>{`WORKIN' GROUP LISTS`}</p>
    </div>
  </LandingHeaderStyled>
)

export default LandingHeader
