import styled from 'styled-components'

export const LandingHeaderStyled = styled.div`
  background: #0d5bdc;

  .container {
    padding: 10px 40px;

    display: flex;
    align-items: flex-end;

    img {
      width: 150px;
      object-fit: contain;
      margin-right: 20px;
    }

    p {
      color: white;
      font-size: 24px;
      line-height: 27px;
      text-transform: capitalize;
      font-weight: bold;
    }

    .flag-select {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 999999;

      .country-flag {
        img {
          top: 14px;
          width: 20px;
          height: 20px;
        }
      }

      .selected--flag--option {
        color: white;
        font-size: 14px !important;
        line-height: 0px !important;
        display: flex-inline !important;
        align-items: center !important;
        justify-content: center !important;
      }

      .arrow-down {
        color: white !important ;
      }
    }
  }
`
