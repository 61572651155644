//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../assets/i18n'
import { ProjectCard, MailToCard, SectionColHeader } from '../../molecules'
import { ButtonIcon } from '../../atoms'
import { sanitize } from '../../../utils/utils'

export default class Projects extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    projectsAccepted: PropTypes.array,
    sendEmailProjects: PropTypes.func,
    searchText: PropTypes.string,
    profile: PropTypes.object,
    contactsEmailSend: PropTypes.array,
    removeContactsEmailsSend: PropTypes.func,
    addContactsEmailsSend: PropTypes.func,
    sendContactsEmailsSend: PropTypes.func,
    contacts: PropTypes.array,
    resetContactsEmailsSend: PropTypes.func
  }

  state = {
    emailTo: []
  }

  _onCreateProjectTapped = () => {
    const url = '/projects/add'
    this.props.history.push(url)
  }

  _onEditTapped = (e, item) => {
    e.stopPropagation()
    const encodedProjectName = encodeURI(item.name)
    const url = '/projects/' + encodedProjectName + '/edit'
    this.props.history.push(url)
  }

  _onEmailTapped = (e, item, i, emailSelected) => {
    e.stopPropagation()

    const { contacts, profile } = this.props
    const projectContacts = []
    _.forEach(item.members, (value, id) => {
      const c1 = profile.id === id ? profile : _.find(contacts, c => c.id === id)
      if (!c1) return
      projectContacts.push(c1)
    })

    if (emailSelected) {
      this.props.removeContactsEmailsSend(projectContacts)
    } else {
      this.props.addContactsEmailsSend(projectContacts)
    }
  }

  _onEmailCancel = () => {
    this.props.resetContactsEmailsSend()
  }

  _onEmailAllTapped = () => {
    this.props.sendEmailProjects(this.props.projectsAccepted)
  }

  _sendEmailTo = () => {
    this.props.sendContactsEmailsSend()
  }

  _onProjectTapped = item => {
    const project = _.get(this.props, 'match.params.project', null)
    if (item.name !== project) {
      const encodedProjectName = encodeURI(item.name)
      const url = '/projects/' + encodedProjectName
      this.props.history.push(url)
    }
  }

  _renderProjectCard = (item, i) => {
    let emailSelected = true
    const { contacts, contactsEmailSend, profile } = this.props

    _.forEach(item.members, (value, id) => {
      const c1 = profile.id === id ? profile : _.find(contacts, c => c.id === id)
      if (!c1) return

      const find = _.find(contactsEmailSend, e1 => c1.email === e1)
      if (!find) {
        emailSelected = false
      }
    })

    const selected = _.get(this.props, 'match.params.project', '') === item.name
    return (
      <ProjectCard
        key={'project' + i}
        project={item}
        onProjectTapped={() => this._onProjectTapped(item, i)}
        onEmailTapped={e => this._onEmailTapped(e, item, i, emailSelected)}
        onEditTapped={e => this._onEditTapped(e, item, i)}
        emailSelected={emailSelected}
        selected={selected}
        profile={this.props.profile}
      />
    )
  }

  render() {
    const { projectsAccepted, searchText, contactsEmailSend } = this.props
    let list = projectsAccepted
    if (searchText) {
      list = _.filter(list, ({ name }) =>
        sanitize(name)
          .toUpperCase()
          .includes(sanitize(searchText).toUpperCase())
      )
    }
    list = _.orderBy(list, ({ name }) => name.toUpperCase())
    const mailCardClass = contactsEmailSend.length > 0 ? 'mailcard-show' : 'mailcard-hidden'

    return (
      <div className={'col-cells-holder col-projects'}>
        <SectionColHeader title={i18n.projects.title}>
          <ButtonIcon label={'Nuevo'} onClick={this._onCreateProjectTapped} color={'white'} icon={'add'} />
          <ButtonIcon label={'Email'} onClick={this._onEmailAllTapped} color={'white'} icon={'mail'} />
        </SectionColHeader>
        <MailToCard emailToList={contactsEmailSend} onCancel={this._onEmailCancel} onAccept={this._sendEmailTo} />
        <div className={'col-loop ts ' + mailCardClass}>{_.map(list, this._renderProjectCard)}</div>
      </div>
    )
  }
}
