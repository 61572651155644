import * as types from './types'
import { AuthTypes } from '../auth'

const initialState = {
  isFetching: false,
  lastCalls: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.SET_LAST_CALLS:
      return { ...state, lastCalls: action.payload }
    case AuthTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}
