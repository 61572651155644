import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import { ButtonStyled } from './styled'

export default class ButtonEmail extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    icon: PropTypes.string,
    color: PropTypes.string,
    back: PropTypes.string,
    parentClass: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string
  }

  static defaultProps = {
    label: '',
    onClick: null,
    isFetching: false,
    disabled: false,
    selected: false,
    icon: 'mail-alt',
    color: 'white',
    back: 'mediumGrey',
    parentClass: '',
    className: ''
  }

  _handleClick(e) {
    if (!this.props.isFetching) {
      this.props.onClick && this.props.onClick(e)
    }
  }

  _renderContent() {
    if (this.props.isFetching) {
      return (
        <span>
          <Spinner color={'#11A8AD'} name={'circle'} fadeIn={'none'} />
        </span>
      )
    } else {
      return <span>{this.props.label}</span>
    }
  }

  render() {
    const { color, back, icon } = this.props

    return (
      <ButtonStyled className={'mod mod-atoms mod-atoms-buttonEmail'} selected={this.props.selected}>
        <div className={'input-holder no-padd ts ts-quick ' + this.props.parentClass + ' ' + color + ' ' + icon + ' back-' + back}>
          <button
            className={this.props.className}
            disabled={this.props.disabled}
            selected={this.props.selected}
            type={'button'}
            onClick={this._handleClick.bind(this)}
          >
            {this._renderContent()}
          </button>
        </div>
      </ButtonStyled>
    )
  }
}
