import _ from 'lodash'
import i18n from '../assets/i18n'

export function validateForm(formInputs) {
  let valid = true
  _.map(formInputs, input => {
    if (input.validate != null) {
      let isValid = input.validate()
      if (!isValid) {
        valid = false
      }
    }
  })
  return valid
}

export function emailValidate(value) {
  if (!value) {
    return { isValid: false, error: i18n.validations.mandatory }
  }
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (re.test(value)) {
    return { isValid: true, error: '' }
  } else {
    return { isValid: false, error: i18n.validations.email }
  }
}

export function passwordValidate(value1, value2) {
  if (value1 && value2 && value1 !== value2) {
    return { isValid: false, error: i18n.validations.match }
  } else if (value1.length > 7) {
    return { isValid: true, error: '' }
  } else {
    return { isValid: false, error: i18n.validations.length }
  }
}

export function mandatoryValidate(value) {
  if (value !== null && value !== '' && value !== false) {
    return { isValid: true, error: '' }
  } else {
    return { isValid: false, error: i18n.validations.mandatory }
  }
}

export function minLengthValidate(value, min) {
  if (value.length >= min) {
    return { isValid: true, error: '' }
  } else {
    return { isValid: false, error: i18n.formatString(i18n.validations.minLength, min) }
  }
}

export function maxLengthValidate(value, max) {
  if (value.length <= max) {
    return { isValid: true, error: '' }
  } else {
    return { isValid: false, error: i18n.formatString(i18n.validations.maxLength, max) }
  }
}

export function minMaxLengthValidate(value, min, max) {
  if (value.length <= max && value.length >= min) {
    return { isValid: true, error: '' }
  } else if (value.length > max) {
    return { isValid: false, error: i18n.formatString(i18n.validations.maxLength, max) }
  } else if (value.length < min) {
    return { isValid: false, error: i18n.formatString(i18n.validations.minLength, min) }
  } else {
    return { isValid: false, error: i18n.formatString(i18n.validations.minLength, min) }
  }
}

export function getBase64(file) {
  return new Promise(function(resolve) {
    const reader = new FileReader()
    reader.onload = event => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export function urlValidation(url) {
  let re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/
  if (!re.test(url)) {
    return { isValid: false, error: i18n.validations.url }
  } else {
    return { isValid: true, error: '' }
  }
}

export function optionalUrlValidation(url) {
  let re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/
  if (url && !re.test(url)) {
    return { isValid: false, error: i18n.validations.url }
  } else {
    return { isValid: true, error: '' }
  }
}

export function dateRangeValidation(v) {
  if (v && v.startDate != null && v.endDate != null) {
    return { isValid: true, error: '' }
  } else {
    return { isValid: false, error: i18n.validations.mandatory }
  }
}
