import styled from 'styled-components'

export const TitleStyled = styled.div`
  img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
  }

  div.fileUploader {
    width: 160px;

    div.fileContainer {
      width: 160px;
      margin-bottom: 50px;
      box-shadow: none;
      padding: 0px;
    }
  }
`

export const QrWrapperStyled = styled.div`
  margin: 0 0 50px 0;
`
