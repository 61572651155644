//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ImageUploader from 'react-images-upload'
import i18n from '../../../../assets/i18n'
import { TextInput, PhoneInput, Button, ModalHolder, QrImageContact } from '../../../atoms'
import Utils from '../../../../utils'
import { TitleStyled, QrWrapperStyled } from './styled'

export default class MyData extends PureComponent {
  static propTypes = {
    fetchMe: PropTypes.func,
    profile: PropTypes.object,
    isFetching: PropTypes.bool,
    history: PropTypes.object,
    onSubmit: PropTypes.func,
    onUpdateImage: PropTypes.func
  }

  componentDidMount() {
    this.props.fetchMe()
  }

  _onClickClose = () => this.props.history.goBack()

  render() {
    const { profile, isFetching, onSubmit, onUpdateImage } = this.props
    return (
      <ModalHolder className={'has-navbar has-side'} addClose={true} CloseOnClick={this._onClickClose}>
        <Form profile={profile} isFetching={isFetching} onSubmit={onSubmit} onUpdateImage={onUpdateImage} />
      </ModalHolder>
    )
  }
}

class Form extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    profile: PropTypes.object,
    onUpdateImage: PropTypes.func
  }

  state = {
    edit: false,
    company: _.get(this.props, 'profile.company', ''),
    department: _.get(this.props, 'profile.department', ''),
    name: _.get(this.props, 'profile.name', ''),
    phone1: _.get(this.props, 'profile.phone1', ''),
    phone2: _.get(this.props, 'profile.phone2', ''),
    surname: _.get(this.props, 'profile.surname', ''),
    position: _.get(this.props, 'profile.position', ''),
    image: _.get(this.props, 'profile.image', '')
  }

  formInputs = {}

  _onClickEdit = () => this.setState({ edit: true })

  _onClickNoEdit = () => this.setState({ edit: false })

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      this.props.onSubmit({
        company: this.state.company,
        department: this.state.department,
        name: this.state.name,
        phone1: this.state.phone1,
        phone2: this.state.phone2,
        surname: this.state.surname,
        position: this.state.position
      })
      this.setState({ edit: false })
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.edit) {
      this.setState({
        ...prevProps.profile,
        edit: false
      })
    }
  }

  _onDrop = pictureFiles => {
    const { onUpdateImage } = this.props
    const reader = new FileReader()
    reader.readAsDataURL(_.first(pictureFiles))
    reader.onload = () => {
      const image = reader.result
      onUpdateImage({ image })
      this.setState({
        image,
        newImg: image
      })
    }
    reader.onerror = function(error) {
      // eslint-disable-next-line no-console
      console.log('Error: ', error)
    }
  }

  _renderTitle = () => {
    const { image, newImg } = this.state
    const title = i18n.myProfile.title
    const showImg = newImg ? newImg : image

    return (
      <TitleStyled className={'col col-xs-12'}>
        <h2>{title}</h2>
        {showImg && <img src={showImg} alt="Title" />}
        <ImageUploader
          withIcon={false}
          withLabel={false}
          withPreview={false}
          buttonText={i18n.myProfile.editImage}
          onChange={this._onDrop}
          imgExtension={['.jpg', '.gif', '.png', '.gif']}
          maxFileSize={1242880}
          singleImage
        />
      </TitleStyled>
    )
  }

  _renderRightFormSide = (edit, name, surname, company, position) => {
    return (
      <div className={'col col-xs-12 col-sm-6'}>
        <TextInput
          innerRef={i => (this.formInputs.name = i)}
          id={'name'}
          value={name}
          label={i18n.auth.name}
          placeholder={' '}
          onChange={name => this.setState({ name })}
          validation={v => Utils.mandatoryValidate(v)}
          disabled={!edit}
        />
        <TextInput
          innerRef={i => (this.formInputs.surname = i)}
          id={'surname'}
          value={surname}
          label={i18n.auth.surname}
          placeholder={' '}
          onChange={surname => this.setState({ surname })}
          validation={v => Utils.mandatoryValidate(v)}
          disabled={!edit}
        />
        <TextInput
          innerRef={i => (this.formInputs.company = i)}
          id={'company'}
          value={company}
          label={i18n.auth.company}
          placeholder={' '}
          onChange={company => this.setState({ company })}
          validation={v => Utils.mandatoryValidate(v)}
          disabled={!edit}
        />
        <TextInput
          innerRef={i => (this.formInputs.position = i)}
          id={'position'}
          value={position}
          label={i18n.auth.position}
          placeholder={' '}
          onChange={position => this.setState({ position })}
          validation={v => Utils.mandatoryValidate(v)}
          disabled={!edit}
        />
      </div>
    )
  }

  _renderLeftFormSide = (edit, department, phone1, phone2) => {
    return (
      <div className={'col col-xs-12 col-sm-6'}>
        <TextInput
          innerRef={i => (this.formInputs.department = i)}
          id={'department'}
          value={department}
          label={i18n.auth.department}
          placeholder={' '}
          onChange={department => this.setState({ department })}
          validation={v => Utils.mandatoryValidate(v)}
          disabled={!edit}
        />
        <PhoneInput
          innerRef={i => (this.formInputs.phone1 = i)}
          id={'phone1'}
          value={phone1}
          label={i18n.auth.phone1}
          placeholder={' '}
          onChange={phone1 => this.setState({ phone1 })}
          validation={v => Utils.mandatoryValidate(v)}
          disabled={!edit}
        />
        <PhoneInput
          innerRef={i => (this.formInputs.phone2 = i)}
          id={'phone2'}
          value={phone2}
          label={i18n.auth.phone2}
          placeholder={' '}
          onChange={phone2 => this.setState({ phone2 })}
          validation={v => Utils.mandatoryValidate(v)}
          disabled={!edit}
        />
      </div>
    )
  }

  _renderLeftFormActions = (edit, submitEnabled, isFetching, onClickEdit, onClickNoEdit) => {
    return (
      <div className={'col col-xs-12 inliner'}>
        {edit && <Button label={i18n.cancel} onClick={onClickNoEdit} className={'clear-gray compact inline'} />}
        {edit && <Button label={i18n.continue} disabled={!submitEnabled} isFetching={isFetching} className={'clear-blue compact inline'} />}
        {!edit && <Button label={i18n.edit} onClick={onClickEdit} className={'clear-blue compact inline'} />}
      </div>
    )
  }

  _renderQR = () => {
    return (
      <div className={'col col-xs-12 qr-wrapper'}>
        <QrWrapperStyled>
          <QrImageContact />
        </QrWrapperStyled>
      </div>
    )
  }
  render() {
    const { isFetching } = this.props
    const { company, department, name, phone1, phone2, surname, position, edit } = this.state
    const submitEnabled = _.size(company) > 0

    return (
      <form onSubmit={this._onSubmit} className={'row regular-form mid-form-holder-nofloat'}>
        {this._renderTitle()}
        {this._renderQR()}
        {this._renderRightFormSide(edit, name, surname, company, position)}
        {this._renderLeftFormSide(edit, department, phone1, phone2)}
        {this._renderLeftFormActions(edit, submitEnabled, isFetching, this._onClickEdit, this._onClickNoEdit)}
      </form>
    )
  }
}
