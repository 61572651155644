import View from './view'
import { connect } from 'react-redux'
import { ProjectsActions } from '../../../../redux/projects'

const mapStateToProps = state => {
  return {
    searchText: state.projects.searchText
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSearchText: text => dispatch(ProjectsActions.setSearchText(text))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View)
