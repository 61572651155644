import axios from 'axios'
import * as ApiConfig from '../config/api'
import firebase from 'firebase'

const instance = axios.create({
  baseURL: ApiConfig.BASE_URL,
  headers: { 'Content-type': 'application/json' }
})

export const configure = () => {
  addAxiosInterceptors()
}

export function configureAxiosDefaults(header, value) {
  instance.defaults.headers.common[header] = value
}

let isAlreadyFetchingAccessToken = false
let subscribers = []
let responseInterceptors = null

function onAccessTokenFetched(token) {
  subscribers = subscribers.filter(callback => callback(token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export const refreshToken = () => {
  if (firebase.auth().currentUser) {
    return firebase.auth().currentUser.getIdToken(true)
  } else {
    return Promise.reject()
  }
}

function addAxiosInterceptors() {
  const successCallBack = response => response
  const errorCallback = error => {
    const {
      config,
      response: { status }
    } = error

    if (status === 401 || status === 403) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        refreshToken().then(token => {
          instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
          isAlreadyFetchingAccessToken = false
          onAccessTokenFetched(token)
        })
      }

      const originalRequest = config
      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(token => {
          originalRequest.headers.Authorization = 'Bearer ' + token
          resolve(instance(originalRequest))
        })
      })
      return retryOriginalRequest
    } else {
      return Promise.reject(error)
    }
  }

  if (!responseInterceptors) {
    instance.interceptors.response.eject(responseInterceptors)
  }
  responseInterceptors = instance.interceptors.response.use(successCallBack, errorCallback)
}

export function get(url) {
  return instance
    .get(url)
    .then(response => {
      return response.data ? response.data : null
    })
    .catch(error => {
      if (error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function post(url, data) {
  return instance
    .post(url, data)
    .then(response => {
      return response.data ? response.data : null
    })
    .catch(error => {
      if (error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function put(url, data) {
  return instance
    .put(url, data)
    .then(response => {
      return response.data ? response.data : null
    })
    .catch(error => {
      if (error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function patch(url, data) {
  return instance
    .patch(url, data)
    .then(response => {
      return response.data ? response.data : null
    })
    .catch(error => {
      if (error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}

export function remove(url) {
  return instance
    .delete(url)
    .then(response => {
      return response.data ? response.data : null
    })
    .catch(error => {
      if (error.response) {
        const res = { code: error.response.status, msg: error.response.data, error: error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error: error }
        throw res
      }
    })
}
