import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavBarHolderStyler } from './styled'
import { ButtonHome } from '../../atoms'

export default class NavBarHolder extends Component {
  static propTypes = {
    addClass: PropTypes.string,
    menu: PropTypes.element,
    search: PropTypes.element
  }

  static defaultProps = {
    menu: null,
    search: null,
    addClass: ''
  }

  render() {
    const { menu, search, addClass } = this.props

    return (
      <NavBarHolderStyler className={'mod mod-atoms mod-atoms-navBarHolder regular-typo ' + addClass}>
        <div className={'container'}>
          <div className={'inner-nav-header'}>
            <div className={'row'}>
              <div className={'col col-xs-12 col-sm-6 col-md-5 logo-holder'}>
                <ButtonHome />
              </div>
              <div className={'col col-xs-12 col-sm-6 col-md-7 nav-holder'}>
                {menu}
                {search}
              </div>
            </div>
          </div>
        </div>
      </NavBarHolderStyler>
    )
  }
}
