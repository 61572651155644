//React
import React, { PureComponent } from 'react'
import i18n from '../../../../assets/i18n'
import { NavBar } from '../../../organisms'

export default class Politic extends PureComponent {
  render() {
    return (
      <div className={'motherwrapper terms has-navbar'}>
        <NavBar showMyProfile />
        <div className={'container'}>
          <div className={'inner-working-cols'}>
            <div className={'row'}>
              <div className={'col-xs-12 col-holder regular-typo static-typo-legal'}>{i18n.getLanguage() === 'es' ? <PoliticEs /> : <PoliticEn />}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const PoliticEs = () => (
  <div>
    <h1>POLÍTICA DE PRIVACIDAD</h1>
    <p>
      La presente política de privacidad (la <b>“Política de Privacidad”</b>) forma parte de los Términos y Condiciones de Uso y tiene por finalidad informar,
      de manera clara y precisa, a los usuarios (los <b>“Usuarios”</b>) de la aplicación móvil Workin’GroupLists o WGL (la <b>“Aplicación”</b>) sobre el
      tratamiento de los datos de carácter personal recabados por la sociedad Connecting Tools SL (<b>“la Sociedad”</b>), cuyos datos identificativos se recogen
      debajo, a través de la referida Aplicación.
    </p>
    <p>
      La presente Política de Privacidad sólo es aplicable a la Aplicación, declinando la Sociedad cualquier responsabilidad sobre las diferentes políticas de
      privacidad y protección de datos de carácter personal que puedan contener las páginas web a las cuales el Usuario pueda acceder a través de los
      hipervínculos que figuren en la misma.
    </p>
    <p>
      La inclusión de sus datos personales en la Aplicación por parte de los Usuarios, así como los datos proporcionados durante la prestación de los servicios,
      implica necesariamente, y sin reservas, el conocimiento y aceptación de la presente Política de Privacidad, por lo que se recomienda a los Usuarios que,
      con carácter previo, lean detenidamente la misma.
    </p>
    <p>
      En caso de que el Usuario facilite datos de terceros o comparta y/o permita el acceso a tales datos por parte de otros terceros o Usuarios, éste
      manifiesta contar con el consentimiento de aquellos y se compromete a trasladarle la información contenida en la presente Política de Privacidad,
      eximiendo a la Sociedad de cualquier responsabilidad en este sentido.
    </p>
    <h2>Identidad y datos de contacto del responsable del tratamiento:</h2>
    <p>
      Connecting Tools SL, (<b>“la Sociedad”</b>), con domicilio Doctor Arce 32 B, con C.I.F. B88417266, inscrita en el Registro Mercantil de Madrid Tomo 39360,
      Folio 182, inscripción 1 con hoja M-698911, de la entidad Connecting Tools SL
    </p>
    <p>
      Su e-mail de contacto: <a href="mailto:info@workingrouplists.com">info@workingrouplists.com</a>
    </p>
    <h2>Datos necesarios para determinados tratamientos.</h2>
    <p>
      La entrega de los datos solicitados en los formularios de la Aplicación es absolutamente voluntaria para los Usuarios si bien no proporcionar determinados
      datos en los procesos de registro o en los formularios electrónicos que se presenten puede provocar la imposibilidad de acceso a los servicios.{' '}
    </p>
    <p>
      Los Usuarios responderán, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los datos personales facilitados, reservándose la
      Sociedad el derecho a excluir, de los servicios suministrados, a los Usuarios que hayan facilitado datos falsos, sin prejuicio de las demás acciones que
      procedan en Derecho.
    </p>
    <h2>Información que se recopila de los Usuarios</h2>
    <p>
      La Sociedad recoge datos personales meramente identificativos, tales como nombre, apellidos, ; datos de contacto como la dirección postal o número de
      teléfono y cualquier otro dato que los Usuarios deseen incluir para completar su perfil tales como fotografías, dirección de correo electrónico, puesto de
      trabajo, cargo e información relacionada.
    </p>
    <p>
      Asimismo, otros Usuarios podrán compartir y suministrar datos como los anteriormente señalados sobre usted, del mismo modo que usted podría suministrar y
      compartir los datos de otros Usuarios. En todo caso, en tanto que todos los Usuarios han aceptado previamente la presente Política de Privacidad, vienen
      exigidos a haber recabado el consentimiento previo antes de introducir en la Aplicación o proporcionar a la Sociedad los datos personales de terceros.
    </p>
    <h2>Finalidades y bases jurídicas del tratamiento</h2>
    <p>
      Los datos de carácter personal recabados a través de la Aplicación serán tratados por la Sociedad, que ostenta la condición de Responsable del tratamiento
      con la finalidad de hacer posible que la Aplicación cumpla su función de permitir compartir los datos de los Usuarios entre ellos, conforme a los
      propósitos y decisiones de éstos.
    </p>
    <p>
      Dicho tratamiento tiene como base jurídica el consentimiento prestado por los Usuarios a la presente Política de Privacidad y así como hacer posible que
      la Aplicación cumpla su función, poder prestar los servicios descritos en los Términos y Condiciones de Uso, que han sido aceptados por los Usuarios, y
      cumplir con el desarrollo de la relación contractual. De lo contrario la Aplicación no podría cumplir su funcionalidad.
    </p>
    <h2>Consentimiento de los Usuarios</h2>
    <p>
      Mediante la aceptación de la presente Política de Privacidad, el Usuario reconoce haber leído y entendido los términos de la misma y acepta, de forma
      expresa e inequívoca su vigencia durante la utilización de la Aplicación y la prestación de los correspondientes servicios.
    </p>
    <p>
      Los Usuarios deberán modificar los datos introducidos en la Aplicación conforme estos queden desactualizados, respondiendo, en cualquier caso, de la
      veracidad y exactitud de los datos suministrados en cada momento. La Sociedad se reserva, en su caso y sin perjuicio de otras acciones que pudieran
      corresponderle, el derecho a no prestar los servicios o a dar de baja a aquellos Usuarios que faciliten datos falsos o incompletos.{' '}
    </p>
    <p>
      La Sociedad no asume responsabilidad alguna por los daños o perjuicios que pudieran derivarse de la falsedad o inexactitud de los datos suministrados, de
      los que responderá únicamente el Usuario.
    </p>
    <p>
      Asimismo, la Sociedad podrá revelar los datos personales recabados en los casos concretos en que dicha cesión se encuentre establecida y sea exigible por
      la normativa aplicable, o cuando así lo exijan las autoridades competentes.
    </p>
    <h2>Comunicación de datos y transferencias internacionales</h2>
    <p>
      Los datos personales recogidos a través de la Aplicación no serán cedidos a ningún tercero para ser utilizados para sus propios fines distintos de
      aquellos para los que el Usuario ha consentido su tratamiento.{' '}
    </p>
    <p>No obstante, podrán acceder a los datos de carácter personal otros Usuarios.</p>
    <p>
      Asimismo, algunos de los Usuarios que podrían acceder a los datos pueden situarse fuera del Espacio Económico Europeo en países que la Unión Europea
      considera que no tienen aún un nivel de protección de datos adecuado. Por ejemplo, sus leyes pueden no garantizarle los mismos derechos, o puede no
      existir una autoridad de control en materia de protección de datos capaz de abordar sus reclamaciones.{' '}
    </p>
    <h2>Plazos de conservación</h2>
    <p>
      Los datos personales de los Usuarios serán conservados durante el tiempo estrictamente necesario para las finalidades del tratamiento para cuyo uso hayan
      sido proporcionados, siempre que el Usuario no haya revocado su consentimiento y elimine sus datos personales de la aplicación, posibilidad que le queda
      abierta de forma sencilla en todo momento.
    </p>
    <p>
      En cualquier caso, los datos personales de los Usuarios serán conservados al menos mientras continúe utilizando la Aplicación y se eliminarán una vez que
      se considere que se ha practicado el servicio de forma satisfactoria para los Usuarios.
    </p>
    <h2>Derechos de los Usuarios.</h2>
    <p>Cualquier Usuario puede, en cualquier momento mediante comunicación escrita, ejercer los siguientes derechos:</p>
    <ul>
      <li>de acceso: consultar qué datos personales sobre Usted trata la Sociedad.</li>
      <li>de rectificación: modificar los datos personales que trata la Sociedad cuando estos sean inexactos. </li>
      <li>de supresión: solicitar que la Sociedad elimine sus datos personales.</li>
      <li>de portabilidad: solicitar que la Sociedad le entregue en un formato informático la información que trata sobre usted.</li>
      <li>de oposición: solicitar que la Sociedad cese en el tratamiento de sus datos.</li>
    </ul>
    <p>
      El ejercicio de estos derechos deberá realizarse por escrito firmado por el titular de los datos, con indicación de su domicilio, adjuntando copia de su
      Documento Nacional de Identidad, pasaporte o documento equivalente, dirigiéndose a la Sociedad, al correo electrónico{' '}
      <a href="mailto:info@workingrouplists.com">info@workingrouplists.com</a>
    </p>
    <h2>Uso de cookies</h2>
    <p>La Aplicación no usa cookies.</p>
    <h2>Modificaciones de la política de privacidad</h2>
    <p>
      La Sociedad se reserva el derecho a modificar esta Política de Privacidad de acuerdo con la legislación aplicable en cada momento. Por ello, le
      recomendamos que revise periódicamente esta Política de Privacidad para estar informado de cómo se tratan y protegen los datos personales que nos
      facilita.{' '}
    </p>
    <p>
      Si tiene cualquier duda sobre nuestra Política de Privacidad, por favor, contacta con nosotros enviando un e-mail a{' '}
      <a href="mailto:info@workingrouplists.com">info@workingrouplists.com</a>. Asimismo, si considera que sus derechos no se atienden debidamente, tiene
      derecho a presentar una reclamación ante la Agencia Española de Protección de Datos, cuyos datos de contacto son: Teléfonos: 901 100 099 y 912 663 517;
      Dirección Postal: C/ Jorge Juan, 6 28001-Madrid; Sede Electrónica:{' '}
      <a href="https://sedeagpd.gob.es/sede-electronica-web/">https://sedeagpd.gob.es/sede-electronica-web/</a> y página web:{' '}
      <a href="www.agpd.es">www.agpd.es</a>
      www.agpd.es
    </p>
  </div>
)

const PoliticEn = () => (
  <div>
    <h1>PRIVACY POLICY </h1>
    <p>
      This privacy policy (the <b>“Privacy Policy”</b>) forms part of the Terms and Conditions of Use and has the purpose of clearly and precisely informing the
      users (the <b>“Users”</b>) of the mobile application Workin’GroupLists or WGL (the <b>“Application”</b>) on the processing of the personal data gathered
      by the company Connecting Tools SL (<b>“the Company”</b>), whose identifying data are set out below, by way of said Application.{' '}
    </p>
    <p>
      The present Privacy Policy is only applicable to the Application, the Company rejecting any liability regarding any different privacy and personal data
      protection policies contained in any websites which a User may access via any hyperlinks displayed therein.
    </p>
    <p>
      The inclusion by Users of their personal data in the Application, along with the data provided during the provision of the services, entails of necessity
      and without reservations the awareness and acceptance of the present Privacy Policy, and therefore it is recommended that the Users read it carefully in
      advance.
    </p>
    <p>
      In the event that the User provides data of third parties or shares and/or permits access to such data by other third parties or Users, said User declares
      that they have the consent of such parties and undertakes to transfer to them the information contained in this Privacy Policy, exempting the Company from
      any liability in this respect.
    </p>
    <h2>Identity and contact data of the party responsible for data processing:</h2>
    <p>
      Connecting Tools SL, (<b>“the Company”</b>), with registered address at Doctor Arce 32 B, with VAT code B88417266, registered in the Companies Register of
      Madrid in Volume 39360, Folio 182, Inscription 1 with page M-698911, of the entity Connecting Tools SL
    </p>
    <p>
      Contact e-mail: <a href="mailto:info@workingrouplists.com">info@workingrouplists.com</a>
    </p>
    <h2>Data required for certain processing’s.</h2>
    <p>
      The provision by the Users of the data requested in the forms of the Application is totally voluntary, but not providing certain data in the registration
      processes or in the electronic forms presented may make it impossible to access the services.{' '}
    </p>
    <p>
      The Users shall answer, in all cases, for the veracity, accuracy, currency and authenticity of the personal data provided, the Company reserving the right
      to exclude from the services supplied any Users who have provided false data, without prejudice to any other actions which may correspond to it in Law.
    </p>
    <h2>Information gathered on the Users</h2>
    <p>
      The Company gathers merely identifying personal data, such as forename(s), surname(s), etc.; contact details such as the postal address or telephone
      number, and any other data which the Users may wish to include in order to complete their profile, such as photographs, e-mail address, workplace,
      position, professional projects, distribution lists, and related information.
    </p>
    <p>
      Likewise, other Users may share and provide data like the aforesaid on yourself, and you may provide and share the data of other Users. In any case,
      insofar as all the Users have previously accepted this Privacy Policy, they are required to have received prior consent before introducing the personal
      data of third parties into the Application or providing them to the Company.
    </p>
    <h2>Purposes and legal bases of processing</h2>
    <p>
      The personal data gathered via the Application will be processed by the Company, which has the condition of Responsible Party for Processing, for the
      purpose of enabling the Application to fulfil its function of permitting the Users to share their data among themselves in accordance with their own
      intentions and decisions.
    </p>
    <p>
      The processing of said data has as its legal basis the consent given by the Users to this Privacy Policy, and also the requirement of ensuring that the
      Application fulfils its function, being able to provide the services described in the Terms and Conditions of Use which have been accepted by the Users
      and complying with the performance of the contractual relationship. In the contrary case, the Application would be unable to fulfil its function.
    </p>
    <h2>Users’ consent </h2>
    <p>
      By accepting the present Privacy Policy, the User acknowledges that they have read and understood its terms and expressly and unequivocally accepts its
      validity during the use of the Application and the provision of the corresponding services.
    </p>
    <p>
      The Users will have to modify the data introduced into the Application as they fall out of date, answering in all cases for the veracity and accuracy of
      the data provided at each moment. As the case may be, and without prejudice to any other actions which may correspond to it, the Company reserves the
      right not to provide the services or to exclude any Users who provide false or incomplete data.{' '}
    </p>
    <p>
      The Company accepts no liability whatsoever for any damages which may arise out of falseness or inaccuracy of the data provided, for which only the User
      shall answer.
    </p>
    <p>
      In addition, the Company may disclose the personal data gathered in specific cases in which such disclosure is established and requirable under the
      applicable regulations, or when so required by the competent authorities.
    </p>
    <h2>Communication of data and international transfers </h2>
    <p>
      The personal data gathered via the Application will not be assigned to any third party to be used for its own purposes other than those for which the User
      has consented their processing. However, other Users may access the personal data.
    </p>
    <p>
      Some of the Users who could access the data may be located outside of the European Economic Area in countries which the European Union believes still do
      not have an adequate level of data protection: for example, their laws may not guarantee such users the same rights, or there may not be a control
      authority in data protection matters capable of processing their claims.{' '}
    </p>
    <h2>Conservation periods</h2>
    <p>
      The User’s personal data will be conserved during the time strictly necessary for the purposes of the processing for which they have been provided, as
      long as the User has not revoked their consent and eliminated their personal data from the application, a possibility which remains open to them in a
      simple form at all times.{' '}
    </p>
    <p>
      In any case, the User’s personal data will be conserved at least as long as they continue to use the Application, and will be eliminated once it is
      considered that the service has been provided in a satisfactory manner for the Users.
    </p>
    <h2>Users’ Rights</h2>
    <p>Any User may, at any moment and by means of written communication, exercise the following rights:</p>
    <ul>
      <li>Access: consulting what personal data of the User the Company has.</li>
      <li>Rectification: modifying the personal data processed by the Company when they are inaccurate. </li>
      <li>Cancellation: requesting the Company to eliminate their personal data.</li>
      <li>Portability: requesting the Company to deliver to them in an IT format the information it holds on the User.</li>
      <li>Opposition: requesting to the Company to cease in processing of Users’ personal data.</li>
    </ul>
    <p>
      These rights must be exercised in a written document signed by the holder of the data, stating their home address, attaching a copy of their National
      Identity Document, passport or equivalent document, sent to the Company at the e-mail address{' '}
      <a href="mailto:info@workingrouplists.com">info@workingrouplists.com</a>
    </p>
    <h2>Use of cookies</h2>
    <p>The Application does not use cookies.</p>
    <h2>Modifications of the Privacy Policy </h2>
    <p>
      The Company reserves the right to modify this Privacy Policy in accordance with the applicable legislation from time to time. Therefore, we recommend that
      you review this Privacy Policy periodically in order to be informed of how the personal data you provide to us are processed and protected.{' '}
    </p>
    <p>
      If you have any doubts concerning our Privacy Policy, please contact us by sending an e-mail to{' '}
      <a href="mailto:info@workingrouplists.com">info@workingrouplists.com</a>. Also, if you consider that your rights are not being duly safeguarded, you are
      entitled to submit a complaint to the Spanish Data Protection Agency, whose contact details are: telephones 901 100 099 and 912 663 517; postal address
      C/Jorge Juan 6, 28001 Madrid; electronic office <a href="https://sedeagpd.gob.es/sede-electronica-web/">https://sedeagpd.gob.es/sede-electronica-web/</a>{' '}
      and website <a href="www.agpd.es">www.agpd.es</a>
      www.agpd.es
    </p>
  </div>
)
