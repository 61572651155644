import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ButtonHomeStyled } from './styled'
import { logoWhite } from '../../../assets/images/'

export default class ButtonHome extends Component {
  render() {
    return (
      <ButtonHomeStyled className={'mod mod-atoms mod-atoms-buttonHome'}>
        <Link to={'/'}>
          <figure>
            <img src={logoWhite} alt={'WGL'} />
          </figure>
        </Link>
      </ButtonHomeStyled>
    )
  }
}
