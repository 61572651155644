//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../atoms'
import { EmailToListStyled } from './styled'
import i18n from '../../../assets/i18n'
import _ from 'lodash'

export default class MailToCard extends PureComponent {
  static propTypes = {
    emailToList: PropTypes.array,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func,
    notify: PropTypes.func
  }

  static defaultProps = {
    emailToList: [],
    onCancel: () => {},
    onAccept: () => {},
    notify: () => {}
  }

  shareEmailContacts = (contacts, separator = ',') => {
    const text = _.map(contacts, email => (email ? email : '')).join(`${separator} `)
    navigator.clipboard.writeText(`${text}`)

    this.props.notify({
      message: i18n.copyClipboard,
      status: 'info'
    })
  }

  handleClickCopyClipboard = () => {
    if (navigator && navigator.clipboard) {
      const { emailToList } = this.props

      const onClickOutlook = () => this.shareEmailContacts(emailToList, ';')
      const onClickOthers = () => this.shareEmailContacts(emailToList, ',')

      this.props.notify({
        title: i18n.mailto.title,
        message: i18n.mailto.message,
        status: 'info',
        dismissAfter: 0,
        dismissible: true,
        buttons: [
          {
            name: i18n.outlook,
            primary: false,
            onClick: onClickOutlook
          },
          {
            name: i18n.others,
            primary: true,
            onClick: onClickOthers
          }
        ]
      })
    }
  }

  render() {
    const { emailToList, onCancel, onAccept } = this.props
    if (!emailToList || !emailToList.length) return null
    return (
      <EmailToListStyled className={'mod mod-molecules mod-molecules-mailToCard'}>
        <div className={'container'}>
          <div className={'inner ts ts-quick'}>
            <div className={'row'}>
              <div className={'col col-xs-4'}>
                <Button label={i18n.cancel} onClick={onCancel} parentClass={'no-padd'} className={'block mini clear-gray'} />
              </div>
              <div className={'col col-xs-4'}>
                <Button label={i18n.copyEmail} onClick={this.handleClickCopyClipboard} parentClass={'no-padd'} className={'block mini clear-blue inline'} />
              </div>
              <div className={'col col-xs-4'}>
                <Button label={i18n.sendEmail} onClick={() => onAccept(emailToList)} parentClass={'no-padd'} className={'block mini clear-blue'} />
              </div>
            </div>
          </div>
        </div>
      </EmailToListStyled>
    )
  }
}
