import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ProfileColsHolderStyled } from './styled'

export default class ProfileColHolder extends Component {
  static propTypes = {
    col1: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    col2: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClickResendEmailVerification: PropTypes.func
  }

  static defaultProps = {
    col1: null,
    col2: null
  }

  render() {
    const { col1, col2 } = this.props

    return (
      <ProfileColsHolderStyled className={'mod mod-atoms mod-atoms-profileColHolder regular-typo'}>
        <div className={'container'}>
          <div className={'inner-working-cols'}>
            <div className={'row'}>
              <div className={'col-xs-12 col-sm-4 col-md-3 col-lg-2 col-holder sidebar-holder'}>{col1}</div>
              <div className={'col-xs-12 col-sm-8 col-md-9 col-lg-10 col-holder main-holder'}>{col2}</div>
            </div>
          </div>
        </div>
      </ProfileColsHolderStyled>
    )
  }
}
