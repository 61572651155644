import _ from 'lodash'
import { ProjectsUtils } from '../projects'

export const getCompleteName = user => `${_.get(user, 'name', '')} ${_.get(user, 'surname', '')}`
export const getInitials = user => `${_.first(_.get(user, 'name', ''))}${_.first(_.get(user, 'surname', ''))}`

export const groupContactsByCompanyDepartment = contacts => {
  const companyContacs = _.groupBy(contacts, ({ company }) => company)
  const companyDepartmentContacs = {}
  _.forEach(companyContacs, (value, key) => {
    let departmentContacs = _.groupBy(value, ({ department }) => department)
    _.forEach(departmentContacs, (v, k) => {
      departmentContacs[k] = _.sortBy(v, 'name')
    })

    companyDepartmentContacs[key] = departmentContacs
  })
  return companyDepartmentContacs
}

export const findContactByContact = (contact, contacts) => {
  return _.find(contacts, ({ id }) => _.get(contact, 'id') === id)
}

export const findContactByEmail = (searchEmail, contacts) => {
  return _.find(contacts, ({ email }) => email === searchEmail)
}

export const findContactsByCompanyDepartment = (filterCompany, filterDepartment, contacts) => {
  return _.filter(
    contacts,
    ({ department, company }) => _.upperCase(filterDepartment) === department.toUpperCase() && filterCompany.toUpperCase() === company.toUpperCase()
  )
}

export const findContactsByCompany = (filterCompany, contacts) => {
  return _.filter(contacts, ({ company }) => filterCompany.toUpperCase() === company.toUpperCase())
}

export const filterContactsByContact = (contact, contacts) => {
  return _.filter(contacts, ({ id }) => _.get(contact, 'id') !== id)
}

export const filterContactsByCompanyDepartment = (filterCompany, filterDepartment, contacts) => {
  return _.filter(
    contacts,
    ({ department, company }) => !(filterDepartment.toUpperCase() === department.toUpperCase() && filterCompany.toUpperCase() === company.toUpperCase())
  )
}

export const filterContactsByCompany = (filterCompany, contacts) => {
  return _.filter(contacts, ({ company }) => filterCompany.toUpperCase() !== company.toUpperCase())
}

export const filterContactsByText = (searchText, sourceContacts) => {
  const sText = searchText.toUpperCase()
  const contacts = !searchText
    ? sourceContacts
    : _.filter(
        sourceContacts,
        ({ name, surname, email }) =>
          name.toUpperCase().includes(sText) ||
          surname.toUpperCase().includes(sText) ||
          getCompleteName({ name, surname })
            .toUpperCase()
            .includes(sText) ||
          email.toUpperCase().includes(sText)
      )
  return contacts
}

export const checkIncludesContacts = (findContacts, contacts) => {
  let check = true
  _.forEach(findContacts, contact1 => {
    if (!_.find(contacts, contact2 => contact1.id === contact2.id)) {
      check = false
    }
  })
  return check
}

export const diffContacts = (contacs1, contacts2) => {
  const diff = _.differenceBy(contacs1, contacts2, ({ id }) => id)
  const newSelected = contacts2 ? [...contacts2, ...diff] : [...diff]
  return newSelected
}

export const canEditProfile = ({ contact, profile }) => _.get(profile, 'id') === _.get(contact, 'creator') && !_.get(contact, 'completed')

export const filterValidContacts = state => {
  const { contactsProfile, contacts } = state.contacts
  const { projects, projectsInfo } = state.projects
  const { id } = state.auth
  const validContacts = []
  _.forEach(contactsProfile, contact => {
    //Casos válidos:
    // 1. Soy el creador, el usuario no ha tomado posesión y lo tengo como aceptado
    // 2. El usuario me ha aceptado como contacto y he aceptado al contacto
    // 3. Tenemos algún proyecto en común que ambos hemos aceptado

    let isValidContact = false
    if (!contact) {
      isValidContact = false
    } else if (contact.creator === id && _.isUndefined(contact.completed) && _.get(contacts, `[${contact.id}]`)) {
      isValidContact = true
    } else if (
      (_.get(contact, `contacts[${id}]`) || _.get(contact, `contacts[${id}]`) === 0) &&
      (_.get(contacts, `[${contact.id}]`) || _.get(contacts, `[${contact.id}]`) === 0)
    ) {
      isValidContact = true
    } else if (!contact.projects) {
      isValidContact = false
    } else {
      const commonProjects = ProjectsUtils.getCommonProjects({ projects0: contact.projects, projects1: projects, projectsInfo })
      _.forEach(commonProjects, project => {
        if (projects[project.id] && contact.projects[project.id]) {
          isValidContact = true
        }
      })
    }

    if (isValidContact) validContacts.push(contact)
  })
  return validContacts
}

export const allInvitationsTreated = invitations => {
  let allInvitationsTreated = true
  _.forEach(invitations, accepted => {
    if (accepted === 0) {
      allInvitationsTreated = false
    }
  })

  return allInvitationsTreated
}
