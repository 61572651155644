import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { SectionColHeaderStyled } from './styled'

export default class SectionColHeader extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    onClickTitle: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
  }

  static defaultProps = {
    title: '',
    className: '',
    onClickTitle: null
  }

  render() {
    const { onClickTitle, title } = this.props
    return (
      <SectionColHeaderStyled clickTitleEnabled={!_.isNil(onClickTitle)} className={'mod mod-atoms mod-atoms-sectionColHeader regular-typo'}>
        <div className={'container ' + this.props.className}>
          <div className={'inner-cell-actions'}>
            <div className={'row'}>
              <div className={'col-xs-6 title-holder'}>
                <h5 onClick={onClickTitle}>{_.isFunction(title) ? title() : title}</h5>
              </div>
              <div className={'col-xs-6 actions-holder'}>{this.props.children}</div>
            </div>
          </div>
        </div>
      </SectionColHeaderStyled>
    )
  }
}
