import styled from 'styled-components'

export const ButtonHomeStyled = styled.div`
  background: ${props => props.theme.colors.tint};

  a {
    display: inline-block;
    figure {
      display: block;
      margin: 0;
      width: 100%;
      img {
        display: block;
        margin: 0 auto;
        max-width: 120px;
      }
    }
  }
`
