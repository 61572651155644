import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { notify } from 'reapop'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    notify: notif => dispatch(notify(notif))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(View))
