import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContactCard } from './../../molecules'
import { ProfileInfoStepStyled } from './styled'

export default class ProfileInfoStep extends Component {
  static propTypes = {
    label: PropTypes.string,
    profile: PropTypes.object,
    userProfile: PropTypes.object
  }

  static defaultProps = {
    label: '',
    profile: null
  }

  render() {
    const { label, profile, userProfile } = this.props
    return (
      <ProfileInfoStepStyled className={'mod mod-atoms mod-molecules-profileInfoStep regular-typo'}>
        {profile && <ContactCard contact={profile} profile={userProfile} />}
        <p className={'clear'}>
          <em>{label}</em>
        </p>
      </ProfileInfoStepStyled>
    )
  }
}
