//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonIcon, ButtonEmail, ColImageName, CardHolder } from '../../atoms'
import { ContactsUtils } from '../../../redux/contacts'
import { ContactCellStyled } from './styled'

import _ from 'lodash'

export default class contactCard extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    contact: PropTypes.object,
    onContactTapped: PropTypes.func,
    onEmailTapped: PropTypes.func,
    onCallTapped: PropTypes.func,
    emailSelected: PropTypes.bool,
    selected: PropTypes.bool,
    forceActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element])
  }

  static defaultProps = {
    contact: {},
    profile: {},
    onContactTapped: () => {},
    onEmailTapped: () => {},
    onCallTapped: () => {},
    emailSelected: false,
    selected: false,
    forceActions: false
  }

  _renderContactInfo = (img, initials, name, position) => {
    return <ColImageName img={img} initials={initials} name={name} position={position} />
  }

  _renderContactActions = emailSelected => {
    const { contact, profile } = this.props
    const iconBackColor = emailSelected ? 'tint' : 'mediumGrey'
    const isCreator = contact.creator === profile.id
    const isContact = (_.get(contact, `contacts[${profile.id}]`) === 0 && isCreator) || _.get(contact, `contacts[${profile.id}]`) === true
    const showCallButton = isContact && (!_.isEmpty(contact.phone1) || !_.isEmpty(contact.phone2))
    return (
      <span>
        {showCallButton && <ButtonIcon label={'Llamar'} color={'blue'} icon={'call'} back={'white'} onClick={this.props.onCallTapped} />}
        <ButtonEmail label={'email'} onClick={this.props.onEmailTapped} selected={emailSelected} back={iconBackColor} />
      </span>
    )
  }

  render() {
    const { contact, emailSelected, selected, forceActions } = this.props
    const img = _.get(contact, 'image', null)
    const initials = ContactsUtils.getInitials(contact)
    const completeName = ContactsUtils.getCompleteName(contact)
    const position = _.get(contact, 'position', null)

    const info = this._renderContactInfo(img, initials, completeName, position)
    const actions = forceActions === 'noaction' ? false : this._renderContactActions(emailSelected)

    return (
      <ContactCellStyled className={'mod mod-molecules mod-molecules-contactCard'}>
        <CardHolder onClick={this.props.onContactTapped} addClass={'contact'} info={info} actions={actions} selected={selected} forceActions={forceActions} />
      </ContactCellStyled>
    )
  }
}
