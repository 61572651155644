export default {
  refs: {
    profile: 'profile',
    image: 'image',
    project: 'project'
  }
}

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBl8yRhv4vsvx6nPS7ikXhWUKWUcI1TKVM',
  authDomain: 'workingroup-app.firebaseapp.com',
  databaseURL: 'https://workingroup-app.firebaseio.com',
  projectId: 'workingroup-app',
  storageBucket: 'workingroup-app.appspot.com',
  messagingSenderId: '66834558399'
}
