import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ContactsOperations } from '../../../../redux/contacts'

const mapStateToProps = state => {
  return {
    contacts: state.contacts.contacts,
    contactsProfile: state.contacts.contactsProfile,
    isFetching: state.contacts.isFetching,
    userProfile: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refresh: () => dispatch(ContactsOperations.updateContactsProfile()),
    toggleIvitation: data => dispatch(ContactsOperations.toggleContactInvitation(data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(View))
