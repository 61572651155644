//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { NavBar } from '../../../organisms'
import { ProfileColsHolder } from '../../../molecules'
import { SidebarProfile } from '../../../molecules'

export default class MyProfile extends PureComponent {
  static propTypes = {
    showInvitationsBadge: PropTypes.bool,
    history: PropTypes.object
  }

  componentDidMount() {
    //FIX: Force redirect to MyProfile
    if (_.get(this.props, 'location.pathname') === '/my-profile/my-data') {
      this._onClickMyData()
    }
  }

  _onClickMyData = () => this.props.history.push('/my-profile/my-data', { modal: true })
  _onClickChangePass = () => this.props.history.push('/my-profile/update-pass', { modal: true })
  _onClickChangeEmail = () => this.props.history.push('/my-profile/update-email', { modal: true })
  _onClickInvitations = () => this.props.history.push('/my-profile/invitations', { modal: true })
  _onClickMyQR = () => this.props.history.push('/my-profile/my-qr', { modal: true })
  _onClickTerms = () => this.props.history.push('/terms-and-conditions')
  _onClickPrivacy = () => this.props.history.push('/privacy-policy')

  _renderSidebar = () => {
    const { showInvitationsBadge } = this.props
    return (
      <SidebarProfile
        CLMyData={this._onClickMyData}
        CLChangePass={this._onClickChangePass}
        CLChangeEmail={this._onClickChangeEmail}
        CLInvitations={this._onClickInvitations}
        CLTerms={this._onClickTerms}
        CLPrivacy={this._onClickPrivacy}
        showInvitationsBadge={showInvitationsBadge}
        CLQr={this._onClickMyQR}
      />
    )
  }

  render() {
    return (
      <div className={'motherwrapper profile has-navbar'}>
        <NavBar showHome />
        <ProfileColsHolder col1={this._renderSidebar()} col2={''} />
      </div>
    )
  }
}
