//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonEmail, ButtonIcon, ColChipName, CardHolder } from '../../atoms'
import { ProjectsUtils } from '../../../redux/projects'
import { ProjectCellStyled } from './styled'

import _ from 'lodash'

export default class ProjectCard extends PureComponent {
  static propTypes = {
    onCallTapped: PropTypes.func,
    profile: PropTypes.object,
    project: PropTypes.object,
    onProjectTapped: PropTypes.func,
    onEmailTapped: PropTypes.func,
    onEditTapped: PropTypes.func,
    emailSelected: PropTypes.bool,
    selected: PropTypes.bool,
    forceActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element])
  }

  static defaultProps = {
    project: null,
    onProjectTapped: () => {},
    onEmailTapped: () => {},
    onEditTapped: () => {},
    emailSelected: false,
    selected: false,
    forceActions: false
  }

  _renderProjectInfo = (name, color) => {
    return <ColChipName name={name} color={color} />
  }

  _renderProjectActions = (isProjectAdmin, onEditTapped, onEmailTapped, emailSelected) => {
    const iconBackColor = emailSelected ? 'tint' : 'mediumGrey'
    return (
      <span>
        {isProjectAdmin ? <ButtonIcon label={'Edit'} color={'white'} icon={'edit'} back={'mediumGrey'} onClick={this.props.onEditTapped} /> : null}
        <ButtonEmail label={'email'} onClick={this.props.onEmailTapped} selected={emailSelected} back={iconBackColor} />
      </span>
    )
  }

  render() {
    const { project, emailSelected, profile, selected, forceActions } = this.props
    const name = _.get(project, 'name', '')
    const color = _.get(project, 'color', 'grey')
    const isProjectAdmin = ProjectsUtils.isProyectAdmin(profile, project)

    const info = this._renderProjectInfo(name, color)
    const actions = this._renderProjectActions(isProjectAdmin, this.props.onCallTapped, this.props.onEmailTapped, emailSelected)

    return (
      <ProjectCellStyled className={'mod mod-molecules mod-molecules-projectCard'}>
        <CardHolder onClick={this.props.onProjectTapped} addClass={'project'} info={info} actions={actions} selected={selected} forceActions={forceActions} />
      </ProjectCellStyled>
    )
  }
}
