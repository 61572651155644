//React

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavBarHolder } from '../../atoms'
import { NavBarMenu, NavBarSearch } from '../../molecules'

export default class NavBar extends PureComponent {
  static propTypes = {
    searchText: PropTypes.string,
    onChangeSearchText: PropTypes.func,
    showSearch: PropTypes.bool,
    history: PropTypes.object,
    signOut: PropTypes.func,
    showHome: PropTypes.bool,
    showMyProfile: PropTypes.bool,
    updateLocale: PropTypes.func,
    defaultCountry: PropTypes.string
  }

  static defaultProps = {
    searchText: '',
    onChangeSearchText: () => {},
    showSearch: false
  }

  _onClickMyProfile = () => this.props.history.push('/my-profile')
  _onClickHome = () => this.props.history.push('/')
  _onChangeText = text => this.setState({ text })

  render() {
    const { showHome, showMyProfile, searchText, onChangeSearchText, showSearch, updateLocale, defaultCountry } = this.props
    const menu = (
      <NavBarMenu
        showHome={showHome}
        showMyProfile={showMyProfile}
        onClickMyProfile={this._onClickMyProfile}
        onClickHome={this._onClickHome}
        onClickSignOut={this.props.signOut}
        updateLocale={updateLocale}
        defaultCountry={defaultCountry}
      />
    )
    const search = showSearch ? <NavBarSearch text={searchText} onChange={onChangeSearchText} /> : null
    return <NavBarHolder menu={menu} search={search} />
  }
}
