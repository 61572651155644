import styled from 'styled-components'

export const LandingHeaderStyled = styled.div`
  .info {
    background: #12319f;
    padding: 2em;
    color: white;

    .container {
      display: flex;

      .item {
        padding: 2em;
        flex: 1;
      }

      .line {
        background: white;
        width: 45px;
        height: 1px;
        border-radius: 2;
        margin-top: 10px;
        margin-bottom: 50px;
      }

      .image {
        height: 30px;
        object-fit: contain;
        margin-bottom: 20px;
      }

      .title {
        font-size: 30px;
        font-weight: 100;
      }

      .text {
        font-weight: bold;
        font-size: 18px;
        margin: 25px 0;
      }
    }
  }

  .bottom {
    background: #0d5bdc;
    padding: 10px 60px;
    text-align: center;
    color: white;
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 10px;

    a {
      color: white;
    }
  }

  @media (max-width: 1024px) {
    .info {
      .container {
        display: flex;
        flex-direction: column;

        .item {
          padding: 10px;
        }

        .line {
          background: white;
          width: 45px;
          height: 1px;
          border-radius: 2;
          margin-top: 10px;
          margin-bottom: 50px;
        }

        .image {
          height: 30px;
          object-fit: contain;
          margin-bottom: 20px;
        }

        .title {
          font-size: 30px;
          font-weight: 100;
        }

        .text {
          font-weight: bold;
          font-size: 18px;
          margin: 25px 0;
        }
      }
    }
  }
`
