import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CallsOperations } from '../../../redux/calls'
import { ProjectsOperations } from '../../../redux/projects'
import { ContactsOperations, ContactsActions } from '../../../redux/contacts'

const mapStateToProps = state => {
  return {
    searchText: state.projects.searchText,
    isFetching: state.projects.isFetching,
    projectsAccepted: state.projects.projectsAccepted,
    contacts: state.contacts.contactsProfile,
    profile: state.auth,
    contactsEmailSend: state.contacts.contactsEmailSend
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refresh: () => dispatch(ProjectsOperations.updateProjects()),
    sendEmailCompanys: (companys, project) => dispatch(CallsOperations.sendEmailCompanys(companys, project)),
    sendEmailProjects: projects => dispatch(CallsOperations.sendEmailProjects(projects)),
    sendContactsEmailsSend: () => dispatch(ContactsOperations.sendContactsEmailsSend()),
    addContactsEmailsSend: contacts => dispatch(ContactsOperations.addContactsEmailsSend(contacts)),
    removeContactsEmailsSend: contacts => dispatch(ContactsOperations.removeContactsEmailsSend(contacts)),
    resetContactsEmailsSend: () => dispatch(ContactsActions.setContactsEmailsSend([]))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
)
