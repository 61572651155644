//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../assets/i18n'
import { Button, ModalHolder, QrImageContact } from '../../../atoms'
import MyQRStyled from './styled'
import html2canvas from 'html2canvas'

export default class MyQR extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    history: PropTypes.object,
    onSubmit: PropTypes.func
  }

  _onClickClose = () => this.props.history.goBack()

  _onClickDownload = () => {
    html2canvas(document.getElementById('qr-image')).then(function(canvas) {
      const a = document.createElement('a')
      a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
      a.download = 'wgl_qr.jpg'
      a.click()
    })
  }

  render() {
    const { profile } = this.props
    return (
      <ModalHolder className={'has-navbar has-side'} addClose={true} CloseOnClick={this._onClickClose}>
        <MyQRStyled>
          <div id={'qr-image'}>
            <QrImageContact profile={profile} />
          </div>

          <Button label={i18n.download} onClick={this._onClickDownload} className={'clear-blue compact inline'} />
        </MyQRStyled>
      </ModalHolder>
    )
  }
}
