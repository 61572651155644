//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../assets/i18n'
import { Button, QrImageProject } from '../../../atoms'
import ProjectQRStyled from './styled'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

export default class ProjectQR extends PureComponent {
  static propTypes = {
    project: PropTypes.object
  }

  _onClickDownload = async () => {
    const canvas = await html2canvas(document.getElementById('qr-image'))
    canvas.toBlob(blob => saveAs(blob, `wgl_qr.jpg`))
  }

  render() {
    const { project } = this.props
    return (
      <ProjectQRStyled>
        <div>
          <div id={'qr-image'} style={{ padding: 20 }}>
            <QrImageProject project={project} />
          </div>

          <Button label={i18n.download} onClick={this._onClickDownload} className={'clear-blue compact inline'} />
        </div>
      </ProjectQRStyled>
    )
  }
}
