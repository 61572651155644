import styled from 'styled-components'

export const LandingInfoStyled = styled.div`
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;

  img {
    height: 65vh;
    width: 100%;
    object-fit: contain;
    display: flex;
  }

  p {
    font-size: 40px;
    font-weight: 200;
    text-align: center;
    padding-top: 1em;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
