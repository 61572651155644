import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CallsOperations } from '../../../redux/calls'

const mapStateToProps = state => {
  return {
    isFetching: state.contacts.isFetching,
    contacts: state.contacts.contactsProfile,
    projects: state.projects.projectsAccepted,
    userProjects: state.projects.projects,
    profile: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onPressCall: contact => dispatch(CallsOperations.call(contact)),
    sendEmailContacts: contacts => dispatch(CallsOperations.sendEmailContacts(contacts))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
)
