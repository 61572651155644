import styled from 'styled-components'

export const NavBarMenuStyled = styled.ul`
  background: ${props => props.theme.colors.tint};
  display: block;
  padding: 5px 0 !important;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0 !important;

  .my-profile {
    display: inline-flex;
    align-items: center;

    .my-profile-badge {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: red;
      margin-left: 5px;
    }
  }

  .flag-select {
    z-index: 999999;

    .selected--flag--option {
      color: white;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    .arrow-down {
      color: white !important ;
    }
  }

  li {
    display: block;
    padding: 0 10px;
    flex: 0 0 auto;
    margin-bottom: 0 !important;
    button {
      display: block;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 20px;
      color: ${props => props.theme.colors.white};
    }

    a {
      display: block;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 20px;
      color: ${props => props.theme.colors.white};
    }
    a:hover {
      color: ${props => props.theme.colors.white};
    }
    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }
  }
`
