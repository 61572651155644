//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'
import { TextInput, Button, ModalHolder } from '../../../atoms'
import Utils from '../../../../utils'

export default class UpdateEmail extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    sendPasswordReset: PropTypes.func,
    isFetching: PropTypes.bool,
    history: PropTypes.object
  }

  _onClickClose = () => this.props.history.goBack()
  _onClickForgot = () => this.props.sendPasswordReset()

  render() {
    const { onSubmit, isFetching, history } = this.props
    return (
      <ModalHolder className={'has-navbar has-side'} addClose={true} CloseOnClick={this._onClickClose}>
        <Form onSubmit={onSubmit} isFetching={isFetching} history={history} onClickForgot={this._onClickForgot} />
      </ModalHolder>
    )
  }
}

class Form extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    onClickForgot: PropTypes.func,
    isFetching: PropTypes.bool,
    history: PropTypes.object
  }

  state = {
    oldPassword: '',
    newEmail: ''
  }

  formInputs = {}

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      this.props.onSubmit(this.state, this.props.history)
    }
  }

  render() {
    const { isFetching, onClickForgot } = this.props
    const { oldPassword, newEmail } = this.state
    const submitEnabled = _.size(oldPassword) > 7 && _.size(newEmail) > 2

    return (
      <form onSubmit={this._onSubmit} className={'row regular-form slim-form-holder-nofloat'}>
        <div className={'col col-xs-12'}>
          <h2>{i18n.auth.changeEmail}</h2>
        </div>
        <div className={'col col-xs-12'}>
          <TextInput
            innerRef={i => (this.formInputs.oldPassword = i)}
            id={'oldPassword'}
            value={oldPassword}
            label={i18n.auth.currentPass}
            placeholder={' '}
            onChange={oldPassword => this.setState({ oldPassword })}
            type={'password'}
            validation={v => Utils.mandatoryValidate(v)}
          />
          <TextInput
            innerRef={i => (this.formInputs.newEmail = i)}
            id={'newEmail'}
            value={newEmail}
            label={i18n.auth.newEmail}
            placeholder={' '}
            onChange={newEmail => this.setState({ newEmail })}
            type={'email'}
            validation={v => Utils.mandatoryValidate(v)}
          />
        </div>
        <div className={'col col-xs-12 center'}>
          <Button label={i18n.save} disabled={!submitEnabled} isFetching={isFetching} className={'clear-blue compact inline'} parentClass={'no-bottom-padd'} />
          <Button label={i18n.auth.forgotPassword} onClick={onClickForgot} className={'link-alike compact inline'} />
        </div>
      </form>
    )
  }
}
