/* eslint-disable no-console */

import _ from 'lodash'
import { ProjectsActions, ProjectsUtils } from './'
import { ContactsUtils } from '../contacts'

let firebaseProjectsListener = null

export const addNewProject = data => (dispatch, getState, { api }) => {
  return api
    .postProject(data)
    .then(() => dispatch(updateProjects()))
    .then(res => {
      console.log('addNewProject res: ', res)
      return true
    })
    .catch(err => {
      console.log('addNewProject error: ', err)
      return false
    })
}

export const editProject = (data, project) => (dispatch, getState, { api }) => {
  const { id } = getState().auth
  const meData = { [id]: true }

  let members = { ...meData }
  _.forEach(data.members, ({ id }) => (members[id] = true))

  const newMembers = ProjectsUtils.getProjectNewMembersKeys({ members, project })
  const deletedMembers = ProjectsUtils.getProjectDeledtedMembersKeys({ members, project })

  let admins = { ...meData }
  _.forEach(data.admins, (value, key) => {
    if (!deletedMembers[key] && value) admins[key] = value
  })

  return api
    .patchProject({ ...data, id: project.id, admins, newMembers, deletedMembers })
    .then(() => {
      return true
    })
    .catch(error => {
      console.log(error)
      return false
    })
    .finally(() => dispatch(updateProjects()))
}

export const setProjectsListener = () => (dispatch, getState, { firebase, firebaseConfig }) => {
  removeProjectsListener()

  const { id } = getState().auth
  const meProjectsRef = `${firebaseConfig.refs.profile}/${id}/projects`
  firebaseProjectsListener = firebase.database().ref(meProjectsRef)
  firebaseProjectsListener.on('value', snapshot => {
    const projects = snapshot ? snapshot.val() : null
    dispatch(ProjectsActions.setProjects(projects ? projects : []))
    dispatch(updateProjects())
  })
}

export const removeProjectsListener = () => {
  firebaseProjectsListener && firebaseProjectsListener.off('value')
}

export const updateProjects = () => (dispatch, getState, { firebase, firebaseConfig }) => {
  const { projects } = getState().projects
  const promises = _.map(projects, (_, key) =>
    firebase
      .database()
      .ref(`${firebaseConfig.refs.project}/${key}`)
      .once('value')
  )

  return Promise.all(promises).then(response => {
    const projectsInfo = _.map(response, snapshot => snapshot.val())
    const projectsInfoFiltered = _.filter(projectsInfo, project => !_.isNull(project) && !_.isUndefined(project))
    dispatch(ProjectsActions.setProjectsInfo(projectsInfoFiltered))
    const projectsAccepted = _.filter(projectsInfoFiltered, ({ id }) => projects[id])
    dispatch(ProjectsActions.setProjectsAccepted(projectsAccepted))
  })
}

export const editProjectCompany = ({ project, companyName, image }) => (dispatch, getState, { api }) => {
  return api
    .putProjectCompany({ project: project.id, company: companyName, image })
    .then(response => {
      console.log('editProjectCompany response: ', response)
      dispatch(updateProjects())
      return true
    })
    .catch(error => {
      console.log('editProjectCompany error: ', error)
      return false
    })
    .finally(() => dispatch(updateProjects()))
}

export const toggleProjectIvitation = project => (dispatch, getState, { i18n, firebase, firebaseConfig, notify }) => {
  //si no ha aceptado --> aceptar
  //si no es admin --> salir del grupo
  //si es admin y hay más admin --> dejar de ser admin y salir del grupo
  //si es admin y no hay más admin --> confirmar borrado de projecto. Si acepta, borrar proyecto (ojo a todos los miembros)
  const { id } = getState().auth
  const { projects } = getState().projects
  const accepted = projects[project.id]

  if (!accepted) {
    return dispatch(updateProfileProjectAccepted({ proyectId: project.id, accepted: true }))
  } else {
    return firebase
      .database()
      .ref(`${firebaseConfig.refs.project}/${project.id}`)
      .once('value')
      .then(snapshot => snapshot.val())
      .then(project => {
        if (!project || !project.admins) return
        const numberOfAuthoriredAdmins = _.size(_.filter(project.admins, isAdmin => isAdmin))
        const isAdmin = project.admins[id]
        if (!isAdmin) {
          return dispatch(updateProfileProjectAccepted({ proyectId: project.id, accepted: false }))
        } else if (numberOfAuthoriredAdmins > 1) {
          dispatch(updateProfileProjectAccepted({ proyectId: project.id, accepted: false }))
          dispatch(updateProfileProjectAdmin({ proyectId: project.id, isAdmin: false }))
        } else {
          const onConfirm = () => dispatch(deleteProject(project))

          dispatch(
            notify({
              title: i18n.invitations.deleteProyectConfirm.title,
              message: i18n.invitations.deleteProyectConfirm.message,
              status: 'warning',
              buttons: [
                {
                  name: i18n.delete,
                  primary: false,
                  onClick: onConfirm
                },
                {
                  name: i18n.cancel,
                  primary: true
                }
              ]
            })
          )
        }
      })
  }
}

const updateProfileProjectAccepted = ({ proyectId, accepted }) => (dispatch, getState, { api }) => {
  return api
    .putProjectInvitation({ id: proyectId, accepted })
    .then(() => dispatch(updateProjects()))
    .catch(error => console.log(error))
}

const updateProfileProjectAdmin = ({ proyectId, isAdmin }) => (dispatch, getState, { api }) => {
  api
    .putProjectAdmin({ id: proyectId, isAdmin })
    .then(() => dispatch(updateProjects()))
    .catch(error => console.log(error))
}

const deleteProject = project => (dispatch, getState, { api }) => {
  let members = []
  _.forEach(project.members, (_, key) => {
    const contact = ContactsUtils.findContactByContact({ id: key }, getState().contacts.contactsProfile)
    contact && members.push(contact)
  })

  api
    .deleteProject({ id: project.id, name: project.name, members })
    .then(response => console.log(response))
    .catch(error => console.log(error))
    .finally(() => dispatch(updateProjects()))
}
