//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../../assets/i18n'
import { TextInput, Button, AuthHeader } from '../../../atoms'
import Utils from '../../../../utils'
import { LandingHeader, LandingFooter, LandingInfo, DownloadApp } from '../../../molecules'
import Paper from '@material-ui/core/Paper'

class RestoreForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    onClickLG: PropTypes.func,
    isFetching: PropTypes.bool,
    history: PropTypes.object
  }

  state = {
    _username: ''
  }

  formInputs = {}

  _onSubmit = e => {
    e.preventDefault()
    if (Utils.validateForm(this.formInputs)) {
      const data = {
        email: this.state._username
      }
      this.props.onSubmit(data).then(() => this.props.history.goBack())
    }
  }

  render() {
    const { _username } = this.state
    const { onClickLG, isFetching } = this.props
    const submitEnabled = _.size(_username) > 2
    return (
      <form onSubmit={this._onSubmit}>
        <TextInput
          innerRef={i => (this.formInputs._username = i)}
          id={'_username'}
          value={_username}
          label={i18n.email}
          placeholder={' '}
          onChange={_username => this.setState({ _username })}
          validation={v => Utils.mandatoryValidate(v)}
        />
        <div className={'center'}>
          <Button className={'clear-blue block'} label={'RESTORE'} disabled={!submitEnabled} isFetching={isFetching} labeledSpinner />

          <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ color: 'grey', marginRight: 10 }}>Have an account? </span>
            <Button className={'link-alike compact inline'} label={'SIGN IN'} onClick={onClickLG} parentClass={'no-padd'} />
          </div>
        </div>
      </form>
    )
  }
}

export default class Restore extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    history: PropTypes.object
  }

  _onClickLogin = () => this.props.history.push('/login')

  _onClickRegister = () => this.props.history.push('/register')

  _onClickForgot = () => this.props.history.push('/restore-password')

  render() {
    const { onSubmit, isFetching, history } = this.props
    return (
      <div>
        <LandingHeader />
        <section style={{ display: 'flex', flex: 1, padding: '2em', justifyContent: 'center', alignItems: 'center' }}>
          <LandingInfo />
          <div>
            <Paper style={{ display: 'flex', flexDirection: 'column', width: 320, background: '#f8f8f8', padding: 20, marginRight: 20 }} elevation={2}>
              <AuthHeader title={'FORGOT PASSWORD'} subtitle="to restore account" />
              <RestoreForm onSubmit={onSubmit} isFetching={isFetching} onClickLG={this._onClickLogin} history={history} />
              <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <span style={{ color: 'grey', marginRight: 10 }}>Don’t have an account? </span>
                <Button
                  className={'link-alike compact inline'}
                  label={'SIGN UP'}
                  onClick={this._onClickRegister}
                  parentClass={'no-padd'}
                  isFetching={isFetching}
                  labeledSpinner
                />
              </div>
            </Paper>
            <DownloadApp />
          </div>
        </section>
        <LandingFooter />
      </div>
    )
  }
}
