import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ProjectsOperations } from '../../../../redux/projects'
import { notify } from 'reapop'

const mapStateToProps = state => {
  return {
    projectsAccepted: state.projects.projectsAccepted,
    profile: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: data => dispatch(ProjectsOperations.editProjectCompany(data)),
    notify: notif => dispatch(notify(notif))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
)
