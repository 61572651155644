//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavBarSearchStyled } from './styled'
import i18n from '../../../assets/i18n'
import whiteClose from '../../../assets/images/icons/white-close.svg'

export default class NavBarSearch extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    text: '',
    onChange: () => {}
  }

  _handleOnChange = e => this.props.onChange(e.target.value)

  _handleClear = () => this.props.onChange('')

  _onSubmit = e => e.preventDefault()

  render() {
    const { text } = this.props
    return (
      <NavBarSearchStyled className={'mod mod-molecules mod-molecules-navBarSearch clearfix'}>
        <form onSubmit={this._onSubmit}>
          <div className={'input-holder'}>
            <input type="text" placeholder={i18n.search} name={'search'} value={text} onChange={this._handleOnChange} />
            <input type="submit" value={'s'} />
          </div>
          {text && (
            <button onClick={this._handleClear}>
              <img src={whiteClose} alt="Close" />
            </button>
          )}
        </form>
      </NavBarSearchStyled>
    )
  }
}
