import styled from 'styled-components'

export const SingleContactHeaderStyled = styled.div`
  .image-holder {
    text-align: center;

    figure {
      width: 100px;
      height: 100px;
      position: relative;
      overflow: hidden;
      margin: 10px auto;
      img {
        display: block;
        width: 94px;
        height: 94px;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    .initials {
      margin: 10px auto;
      width: 100px;
      height: 100px;
      position: relative;
      overflow: hidden;
      flex: 0 0 50px;
      text-transform: uppercase;
      .initials-inner {
        display: block;
        width: 94px;
        height: 94px;
        text-align: center;
        line-height: 94px;
        color: #fff;
        border-radius: 50%;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: ${props => props.theme.gradients.grey2.lineBG1};
        background: ${props => props.theme.gradients.grey2.lineBG2};
        background: ${props => props.theme.gradients.grey2.lineBG3};
        background: ${props => props.theme.gradients.grey2.lineBG4};
        filter: ${props => props.theme.gradients.grey2.lineFLT};
        span {
          line-height: 94px !important;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .name-holder {
    margin: 10px auto;
    text-align: center;
    h3 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
`
