import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AuthOperations } from '../../../../redux/auth'
import _ from 'lodash'

const mapStateToProps = state => {
  return {
    sesionLoaded: state.auth.sesionLoaded,
    uid: state.auth.uid,
    email: state.auth.email
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const from = _.get(props, 'location.state.from.pathname', false)
  return {
    checkProfile: email => dispatch(AuthOperations.checkProfile(email, props.history, from))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
)
