//React
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../atoms'
import { SidebarProfileStyled } from './styled'
import i18n from '../../../assets/i18n'

export default class SidebarProfile extends PureComponent {
  static propTypes = {
    CLMyData: PropTypes.func,
    CLChangePass: PropTypes.func,
    CLChangeEmail: PropTypes.func,
    CLInvitations: PropTypes.func,
    CLTerms: PropTypes.func,
    CLPrivacy: PropTypes.func,
    showInvitationsBadge: PropTypes.bool,
    CLQr: PropTypes.func
  }

  static defaultProps = {
    CLMyData: null,
    CLChangePass: null,
    CLChangeEmail: null,
    CLInvitations: null,
    CLTerms: null,
    CLPrivacy: null,
    showInvitationsBadge: false,
    CLQr: null
  }

  _renderLinkButton = (label, onClick, showBadge) => {
    return (
      <li>
        <Button label={label} onClick={onClick} className={'black-link-alike has-apicon apicon-rgarrow'} parentClass={'no-padd'} showBadge={showBadge} />
      </li>
    )
  }

  render() {
    const { CLMyData, CLChangePass, CLChangeEmail, CLInvitations, CLTerms, CLPrivacy, showInvitationsBadge, CLQr } = this.props

    return (
      <SidebarProfileStyled className={'mod mod-molecules mod-molecules-sideberProfile'}>
        <div className={'container'}>
          <div className={'inner ts ts-quick'}>
            <div className={'row'}>
              <div className={'col col-xs-12'}>
                <ul className={'nav-holder'}>
                  {this._renderLinkButton(i18n.myProfile.qrCOde, CLQr)}
                  {this._renderLinkButton(i18n.myProfile.title, CLMyData)}
                  {this._renderLinkButton(i18n.auth.changePassword, CLChangePass)}
                  {this._renderLinkButton(i18n.auth.changeEmail, CLChangeEmail)}
                  {this._renderLinkButton(i18n.invitations.title, CLInvitations, showInvitationsBadge)}
                  {this._renderLinkButton(i18n.terms.terms, CLTerms)}
                  {this._renderLinkButton(i18n.terms.privacy, CLPrivacy)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </SidebarProfileStyled>
    )
  }
}
