import View from './view'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    profile: state.auth
  }
}

export default 
  connect(
    mapStateToProps,
    null
  )(View)
